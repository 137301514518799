import { type FC } from 'react';
import { Flex, Image, Spacer } from '@chakra-ui/react';
import {
  CustomerSupportType,
  Feature,
  isFeatureAvailable,
} from '@shinetools/pricing-plan-library';

import { PaymentCardType, type ProviderPlanId } from '__generated__/GQL';
import { formatAmount } from 'common/formatAmount';
import useFeatureFlagsSwitcher from 'common/hooks/useFeatureFlagsSwitcher';
import Button from 'components/_core/Button';
import SunshineCard from 'components/_core/SunshineCard';
import Text from 'components/_core/Text';
import Price from 'components/Price';
import { type SupportedFeaturePreview } from 'features/Upgrade/types';
import { toMajorUnits } from 'helpers/amount';

import { type PlanChoicePageQuery } from '../../pages/PlanChoice/hooks/usePlanChoice/graphql/planChoicePage.gql';
import PlanDetailsBulletPoint from '../PlanDetailsBulletPoint';
import { getCardImage } from './helpers';
import locales from './locales';
import * as S from './styles';

const SEVEN_DAYS_BASIC_PAYMENT_CARD_LIMIT = 10000;
const SEVEN_DAYS_PREMIUM_PAYMENT_CARD_LIMIT = 15000;
const THIRTY_DAYS_BASIC_PAYMENT_CARD_LIMIT = 40000;
const THIRTY_DAYS_PREMIUM_PAYMENT_CARD_LIMIT = 60000;

interface PlanDetailsProps {
  className?: string;
  featurePreview?: SupportedFeaturePreview;
  isCurrentPlan?: boolean;
  plan: PlanChoicePageQuery['viewer']['company']['currentPlan']['upgradablePlans'][number];
  from?: string;
}

const PlanDetails: FC<PlanDetailsProps> = ({
  className,
  featurePreview,
  from,
  isCurrentPlan,
  plan,
}) => {
  const {
    featureFlags: { enableNewInsurance },
  } = useFeatureFlagsSwitcher();

  const catchPhrase =
    plan.providerPlanId in locales.catchPhrase
      ? (locales.catchPhrase as Record<ProviderPlanId, string>)[
          plan.providerPlanId
        ]
      : '';

  const hasAccessToBusinessCard =
    plan.paymentCard.type === PaymentCardType.BusinessWorldDebitCard;
  const nbOfPhysicalCards = plan.limits.physicalPaymentCard.value;
  const canHaveMultiplePhysicalCards = nbOfPhysicalCards > 1;

  const physicalCardImage = getCardImage({
    hasAccessToBusinessCard,
    nbOfPhysicalCards,
  });

  const hasUnlimitedVirtualCards =
    plan.limits.virtualPaymentCardMonth.isUnlimited;
  const nbOfVirtualCards = plan.limits.virtualPaymentCardMonth.value;
  const hasAccessToVirtualCards = nbOfVirtualCards > 0;

  const nbOfSepaOperations = plan.limits.sepaTransferMonth.value;
  const pricePerAdditionalSepaOperation = formatAmount({
    forcePlusSign: false,
    value: toMajorUnits(plan.fees.additionalSepaTransfer.fixed),
  });

  const sevenDaysCardLimit = formatAmount({
    forcePlusSign: false,
    value: hasAccessToBusinessCard
      ? SEVEN_DAYS_PREMIUM_PAYMENT_CARD_LIMIT
      : SEVEN_DAYS_BASIC_PAYMENT_CARD_LIMIT,
    withNoMinorUnitDigits: true,
  });
  const thirtyDaysCardLimit = formatAmount({
    forcePlusSign: false,
    value: hasAccessToBusinessCard
      ? THIRTY_DAYS_PREMIUM_PAYMENT_CARD_LIMIT
      : THIRTY_DAYS_BASIC_PAYMENT_CARD_LIMIT,
    withNoMinorUnitDigits: true,
  });

  const hasAccessToCashDeposit = isFeatureAvailable(plan, Feature.CashDeposit);
  const nbOfCashDepositsPerMonth = plan.limits.cashDepositMonth.value;
  const maxAmountPerCashDeposit = formatAmount({
    forcePlusSign: false,
    value: toMajorUnits(plan.limits.cashDepositMaxAmountPerDeposit.value),
    withNoMinorUnitDigits: true,
  });
  const hasVariableCashDepositFees = plan.fees.cashDeposit.variable !== null;

  const hasAccessToCheckDeposit = isFeatureAvailable(
    plan,
    Feature.CheckDeposit,
  );
  const nbOfCheckDepositsPerMonth = plan.limits.checkDepositMonth.value;
  const additionalCheckDepositsFees = formatAmount({
    forcePlusSign: false,
    value: toMajorUnits(plan.fees.additionalCheckDeposit.fixed),
    withNoMinorUnitDigits: true,
  });

  const hasAccessToTeamExpenses =
    isFeatureAvailable(plan, Feature.TeamManagement) &&
    (plan.limits.companyUserAccess.isUnlimited ||
      plan.limits.companyUserAccess.value > 1);

  const hasAccessToInsurancesAssistance = isFeatureAvailable(
    plan,
    Feature.InsuranceAssistance,
  );

  const hasAccessToCashflowManagementDashboard = isFeatureAvailable(
    plan,
    Feature.CashflowManagementDashboard,
  );

  const hasAccessToAdvancedBillingOptions =
    isFeatureAvailable(plan, Feature.PersonalizedLogo) &&
    isFeatureAvailable(plan, Feature.InvoicingItemsCatalog);

  const hasAccessToPhoneSupport =
    plan.customerSupport.type === CustomerSupportType.PriorityAll;

  const previousPlanName =
    plan.downgradablePlans[plan.downgradablePlans.length - 1]?.brandName ?? '';

  const secondaryBankAccountsCount = plan.limits.activeBankAccounts.value - 1;

  return (
    <SunshineCard
      borderRadius="radius-16"
      className={className}
      padding="space-0"
      width={292}
    >
      <Flex direction="column">
        <S.CardImageContainer
          $rebrandedColor="grey.100"
          brandColor={plan.brandColor}
        >
          <Image
            backgroundRepeat="no-repeat"
            height="100%"
            objectFit="cover"
            src={physicalCardImage}
            width="100%"
          />
        </S.CardImageContainer>

        <Flex
          direction="column"
          paddingBottom="space-32"
          paddingTop="space-16"
          paddingX="space-16"
        >
          <Text fontSize="font-18" fontWeight="weight-500" variant="primary">
            {locales.formatString(locales.planName, {
              planName: plan.brandName,
            })}
          </Text>

          <Flex align="baseline">
            <Price
              size={4}
              value={toMajorUnits(plan.priceWithoutVAT)}
              withoutCents={plan.price % 1 === 0}
            />
            <Text marginLeft="space-6">{locales.priceDetails}</Text>
          </Flex>

          {isCurrentPlan ? (
            <Button isDisabled={true} marginTop="space-20" marginX="space-8">
              {locales.currentPlan}
            </Button>
          ) : (
            <S.UpgradeLink
              to={{
                pathname: '/upgrade/confirm',
                state: {
                  featurePreview,
                  from,
                  pricingPlan: plan,
                },
              }}
            >
              <Button>
                {locales.formatString(locales.choosePlan, {
                  planName: plan.brandName,
                })}
              </Button>
            </S.UpgradeLink>
          )}

          {catchPhrase ? (
            <Text height="space-64" marginTop="space-24" variant="primary">
              {catchPhrase}
            </Text>
          ) : null}

          <Spacer flex="none" height="space-8" />

          {previousPlanName ? (
            <PlanDetailsBulletPoint
              title={locales.formatString(locales.featuresFromPreviousPlan, {
                planName: previousPlanName,
              })}
            />
          ) : null}

          <PlanDetailsBulletPoint
            description={locales.multipleAccounts.description}
            title={locales.formatString(
              secondaryBankAccountsCount === 1
                ? locales.multipleAccounts.titleSingular
                : locales.multipleAccounts.titlePlural,
              { secondaryBankAccountsCount },
            )}
          />

          <PlanDetailsBulletPoint
            description={
              canHaveMultiplePhysicalCards
                ? locales.physicalCards.description
                : undefined
            }
            title={locales.formatString(
              hasAccessToBusinessCard
                ? locales.physicalCards.business
                : locales.physicalCards.basic,
              { nbOfCards: nbOfPhysicalCards },
            )}
          />

          {hasAccessToVirtualCards ? (
            <PlanDetailsBulletPoint
              description={locales.virtualCards.description}
              title={
                hasUnlimitedVirtualCards
                  ? locales.virtualCards.unlimited
                  : locales.formatString(locales.virtualCards.limited, {
                      nbOfCards: nbOfVirtualCards,
                    })
              }
            />
          ) : null}

          <PlanDetailsBulletPoint
            description={locales.formatString(
              locales.sepaOperations.description,
              { amount: pricePerAdditionalSepaOperation },
            )}
            title={locales.formatString(locales.sepaOperations.title, {
              nbOfSepaOperations,
            })}
          />

          <PlanDetailsBulletPoint
            description={locales.formatString(locales.cardLimit.description, {
              amount: sevenDaysCardLimit,
            })}
            title={locales.formatString(locales.cardLimit.title, {
              amount: thirtyDaysCardLimit,
            })}
          />

          {hasAccessToCashDeposit ? (
            <PlanDetailsBulletPoint
              description={
                hasVariableCashDepositFees
                  ? locales.formatString(
                      locales.cashDeposits.descriptionVariable,
                      {
                        percentage:
                          (plan.fees.cashDeposit?.variable ?? 0) * 100,
                      },
                    )
                  : locales.formatString(
                      locales.cashDeposits.descriptionFixed,
                      {
                        amount: formatAmount({
                          forcePlusSign: false,
                          value: toMajorUnits(plan.fees.cashDeposit.fixed),
                          withNoMinorUnitDigits: true,
                        }),
                      },
                    )
              }
              title={locales.formatString(
                nbOfCashDepositsPerMonth === 1
                  ? locales.cashDeposits.titleSingular
                  : locales.cashDeposits.titlePlural,
                { maxAmountPerCashDeposit, nbOfCashDepositsPerMonth },
              )}
            />
          ) : null}

          {hasAccessToCheckDeposit ? (
            <PlanDetailsBulletPoint
              description={locales.formatString(
                locales.checkDeposit.description,
                { amount: additionalCheckDepositsFees },
              )}
              title={
                nbOfCheckDepositsPerMonth === 1
                  ? locales.checkDeposit.titleSingular
                  : locales.formatString(locales.checkDeposit.titlePlural, {
                      nbOfCheckDeposits: nbOfCheckDepositsPerMonth,
                    })
              }
            />
          ) : null}

          {hasAccessToTeamExpenses ? (
            <PlanDetailsBulletPoint
              description={locales.teamExpenses.description}
              title={locales.teamExpenses.title}
            />
          ) : null}

          <PlanDetailsBulletPoint
            description={locales.preAccounting.description}
            title={locales.preAccounting.title}
          />

          {hasAccessToCashflowManagementDashboard ? (
            <PlanDetailsBulletPoint
              description={locales.cashflowManagementDashboard.description}
              title={locales.cashflowManagementDashboard.title}
            />
          ) : null}

          <PlanDetailsBulletPoint
            description={locales.invoicingTool.description}
            title={locales.invoicingTool.title}
          />

          {hasAccessToAdvancedBillingOptions ? (
            <PlanDetailsBulletPoint
              description={locales.advancedBillingOptions.description}
              title={locales.advancedBillingOptions.title}
            />
          ) : null}

          {hasAccessToInsurancesAssistance ? (
            <PlanDetailsBulletPoint
              description={
                enableNewInsurance
                  ? locales.insurancesAssistance.description.insurances2024[
                      plan.providerPlanId as
                        | ProviderPlanId.Pro
                        | ProviderPlanId.Plus
                        | ProviderPlanId.Business
                    ]
                  : locales.insurancesAssistance.description.oldInsurance
              }
              title={locales.insurancesAssistance.title}
            />
          ) : null}

          <PlanDetailsBulletPoint
            description={locales.customerSupport.description}
            title={locales.customerSupport.title}
          />

          {hasAccessToPhoneSupport ? (
            <PlanDetailsBulletPoint
              description={locales.phoneSupport.description}
              title={locales.phoneSupport.title}
            />
          ) : null}
        </Flex>
      </Flex>
    </SunshineCard>
  );
};

export default PlanDetails;
