import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    allGood: 'All good! Continue',
    changePasscode:
      'As a precaution, we recommend you change your access code.',
    description:
      'We have blocked the login attempt from this device, it will not be able to sign in to your account.',
    title: 'Login attempt blocked',
  },
  fr: {
    allGood: 'C’est tout bon\xA0!',
    changePasscode:
      'Par mesure de précaution vous devriez changer votre code d’accès.',
    description:
      'Nous avons bien bloqué la connexion depuis cet appareil, l’appareil ne pourra plus se connecter à votre compte.',
    title: 'Connexion bloquée',
  },
} as const);

export default locales;
