import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    manualEntry: {
      cta: 'Enter your details manually',
      prompt: 'You didn’t find your address?',
    },
  },
  fr: {
    manualEntry: {
      cta: 'Saisissez les informations manuellement',
      prompt: 'Vous ne trouvez pas votre adresse\u00A0?',
    },
  },
} as const);

export default locales;
