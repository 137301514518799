import * as Sentry from '@sentry/react';

import { buildFullTraceId } from './utils';

interface NetworkCallInfo {
  message: string;
  method: 'GET' | 'POST';
  status: number;
  url: string;
  partialTraceId: string | null;
}

/**
 * Reports a network error (from a GraphQL call) to Sentry.
 */
const reportCustomNetworkCallErrorToSentry = ({
  message,
  method,
  partialTraceId,
  status,
  url,
}: NetworkCallInfo) => {
  Sentry.withScope((scope) => {
    scope.setLevel('info');

    // Set tags, which can be used for filtering in Sentry UI
    scope.setTags({
      networkError: true,
      networkErrorMethod: method,
      networkErrorStatusCode: status,
    });

    if (partialTraceId) {
      scope.setTag('traceId', buildFullTraceId(partialTraceId));
    }

    // Put networkErrorUrl & networkErrorMessage in extras to avoid polluting tags
    // These info **cannot** be used for filtering in Sentry UI
    scope.setExtras({
      networkErrorMessage: message,
      networkErrorUrl: url,
    });

    // If there is no internet connection, there is no status, so we avoid
    // displaying it in the message.
    Sentry.captureMessage(
      `${method} ${url} failed ${
        status ? `with status ${status} ` : ''
      }: ${message}`,
    );
  });
};

export default reportCustomNetworkCallErrorToSentry;
