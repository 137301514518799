import { createContext, useContext } from 'react';

interface UseCardReturn {
  isDisabled?: boolean;
}

const CardContext = createContext<UseCardReturn | null>(null);

export const CardContextProvider = CardContext.Provider;

export function useCard() {
  const context = useContext(CardContext);

  if (!context) {
    throw new Error('useCard can only be used inside of a CardContextProvider');
  }

  return context;
}
