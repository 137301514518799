import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    capdepDescription:
      'You have already started the process to create your company',
    capdepTitle: 'Make a capital deposit',
    independentDescription: 'Under the micro-entreprise legal form',
    independentTitle: 'Create a micro-entreprise',
    societyDescription: 'SASU, SAS, SARL, SA, SCI, EURL...',
    societyTitle: 'Create a company',
    submit: 'Continue',
    title: 'You want to',
    unknownDescription: 'No problem, we’re here to help!',
    unknownTitle: 'I am not sure',
  },

  fr: {
    capdepDescription:
      'Vous avez déjà commencé les démarches pour créer votre société',
    capdepTitle: 'Faire un dépôt de capital',
    independentDescription: 'Sous le régime de la micro-entreprise',
    independentTitle: 'Créer une micro-entreprise',
    societyDescription: 'SASU, SAS, SARL, SCI...',
    societyTitle: 'Créer une société',
    submit: 'Valider',
    title: 'Vous souhaitez',
    unknownDescription: 'Aucun problème, nous sommes là pour vous aider\u00a0!',
    unknownTitle: 'Je ne sais pas encore',
  },
} as const);

export default locales;
