import webView from 'common/webView';
import { currentAppVersionIsGte } from 'common/webView/helpers/versionIsGte';

export const INTERCOM_LAUNCHER_CLASS_NAME = 'intercom-custom-launcher';

/**
 * We setup native Intercom opening in 1.201.0 in the RN app.
 */
export const INTERCOM_LAUNCHER_PROPS =
  webView.isInWebView && currentAppVersionIsGte('1.201.0')
    ? {
        onClick: () => {
          webView.postMessage({ action: 'OPEN_INTERCOM_REQUESTED' });
        },
      }
    : { className: INTERCOM_LAUNCHER_CLASS_NAME };
