/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

/**
 * This hook calls its argument callback on the first mount of its React component.
 *
 * Be wary that:
 * - The callback will be called once, and only once,
 * - It will be called with a closure on the lexical scope of the FIRST React render of its component.
 */
const useOnMounted = (callback: () => void) => {
  useEffect(callback, []);
};

export default useOnMounted;
