import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    placeholder: {
      day: 'Day',
      month: 'Month',
      year: 'Year',
    },
  },

  fr: {
    placeholder: {
      day: 'Jour',
      month: 'Mois',
      year: 'Année',
    },
  },
} as const);

export default locales;
