import config from 'config';
import { post } from 'helpers/fetch';

export const approveDevice = async (token: string): Promise<void> => {
  const approvalURL = `${config.shineApiHost}/authentication/device_request/approve`;

  await post(
    approvalURL,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
