import styled from 'styled-components';

import Text from 'components/_core/Text';

export const Title = styled(Text).attrs({
  fontWeight: 'weight-500',
  size: 'lg',
})`
  margin-bottom: ${({ theme }) => theme.spacings.abs8}px;
`;

export const Subtitle = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacings.abs48}px;
`;
