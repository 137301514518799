import { type SystemStyleObject, type ThemeComponents } from '@chakra-ui/react';

export const gridStyles = {
  alignContent: 'stretch',
  columnGap: 'space-16',
  display: 'grid',
  gridAutoColumns: 'minmax(0, 1fr)',
  gridAutoFlow: 'column',
  justifyContent: 'stretch',
  justifyItems: 'start',
} as const satisfies SystemStyleObject;

export const tableTheme = {
  baseStyle: {
    body: {},
    cell: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'start',
      width: 'full',
    },
    column: {
      alignItems: 'center',
      display: 'flex',
      gap: 'space-8',
    },
    footer: {
      _hover: {
        backgroundColor: 'grey.200',
      },
      backgroundColor: 'grey.100',
      borderTop: '1px solid',
      borderTopColor: 'grey.300',
      color: 'grey.800',
      display: 'flex',
      fontWeight: 'weight-500',
      justifyContent: 'center',
      paddingX: 'space-24',
      paddingY: 'space-16',
      position: 'relative',
      textAlign: 'center',
      transitionDuration: 'duration-150',
      transitionProperty: 'background-color',
    },
    header: {
      ...gridStyles,
      backgroundColor: 'grey.100',
      borderBottom: '1px solid',
      borderBottomColor: 'grey.300',
      paddingX: 'space-24',
      paddingY: 'space-20',
    },
    loadingRow: {
      minHeight: 'space-24',
    },
    row: {
      ...gridStyles,

      _first: {
        borderTop: 'none',
        borderTopRadius: 'radius-8',
      },

      _last: {
        borderBottomRadius: 'radius-8',
      },

      borderTop: '1px solid',
      borderTopColor: 'grey.300',
      padding: 'space-24',
    },
    table: {
      border: '1px solid',
      borderColor: 'grey.300',
      borderRadius: 'radius-8',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
  },
} as const satisfies ThemeComponents['Table'];
