import * as components from './components';
import foundations from './foundations';
import semanticTokens from './semanticTokens';
import styles from './styles';

const theme = {
  components,
  styles,
  ...foundations,
  semanticTokens,
};

export type Theme = typeof theme;
export default theme;
