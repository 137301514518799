import { ERROR_CODES } from '@shinetools/errors';

import logger from 'helpers/logger';

import locales from './locales';

const getLocalizedErrorMessage = (errorCode: string | number | undefined) => {
  switch (errorCode) {
    case ERROR_CODES.ACTION_REQUEST_EXPIRED:
      return locales.actionRequestExpired;
    case ERROR_CODES.ACTION_REQUEST_ALREADY_REJECTED:
      return locales.actionRequestAlreadyRejected;
    case ERROR_CODES.ACTION_REQUEST_ALREADY_CONFIRMED:
      return locales.actionRequestAlreadyConfirmed;
    case ERROR_CODES.APE_CODE_DOES_NOT_EXIST:
      return locales.apeCodeDoesNotExist;
    case ERROR_CODES.BANK_TRANSFER_ALREADY_CANCELED:
      return locales.bankTransferAlreadyCanceled;
    case ERROR_CODES.BANK_TRANSFER_ALREADY_VALIDATED:
      return locales.bankTransferAlreadyValidated;
    case ERROR_CODES.CHECK_DEPOSIT_MONTHLY_AMOUNT_LIMIT:
      return locales.checkDepositMonthlyAmountLimit;
    case ERROR_CODES.COMPANY_REFUSED:
      return locales.companyRefused;
    case ERROR_CODES.CREDIT_CARD_NOT_OWNED_BY_USER:
      return locales.creditCardNotOwned;
    case ERROR_CODES.CREDIT_CARD_PAYMENT_INSUFFICIENT_FUNDS:
      return locales.paymentInsufficientFunds;
    case ERROR_CODES.CREDIT_CARD_PAYMENT_DECLINED:
      return locales.paymentDeclined;
    case ERROR_CODES.CREDIT_CARD_PAYMENT_EXPIRED:
      return locales.paymentExpired;
    case ERROR_CODES.CREDIT_CARD_PAYMENT_INVALID_CVC:
      return locales.paymentInvalidCVC;
    case ERROR_CODES.CREDIT_CARD_PAYMENT_INVALID_NUMBER:
      return locales.paymentInvalidNumber;
    case ERROR_CODES.CREDIT_CARD_PAYMENT_INVALID_ZIP_CODE:
      return locales.paymentInvalidZipCode;
    case ERROR_CODES.CREDIT_CARD_PAYMENT_NOT_SUPPORTED:
      return locales.paymentNotSupported;
    case ERROR_CODES.CREDIT_CARD_VALIDATION_TOKEN_DOES_NOT_MATCH:
      return locales.tokensDoNotMatch;
    case ERROR_CODES.EMAIL_ALREADY_EXISTS:
      return locales.emailAlreadyExists;
    case ERROR_CODES.KYC_ALREADY_VALIDATED:
    case 'ID_DOCUMENTS_VALIDATED':
    case 'POA_DOCUMENTS_VALIDATED':
      return locales.documentsValidated;
    case 'KYC_REVIEW_IN_PROGRESS':
      return locales.reviewInProgress;
    case ERROR_CODES.KYC_NOT_READY:
      return locales.kycNotReady;
    case ERROR_CODES.INVALID_CMC7:
      return locales.invalidCheckNumber;
    case ERROR_CODES.INVALID_COUPON:
      return locales.invalidCoupon;
    case ERROR_CODES.INVALID_EMAIL:
      return locales.invalidEmail;
    case ERROR_CODES.INVALID_RLMC_KEY:
      return locales.invalidRlmc;
    case ERROR_CODES.INVALID_ZIP_CODE:
      return locales.invalidZipCode;
    case ERROR_CODES.INVOICE_FISCAL_ID_ALREADY_EXISTS:
      return locales.invoiceFiscalIdNotUnique;
    case ERROR_CODES.QUOTE_FISCAL_ID_ALREADY_EXISTS:
      return locales.quoteFiscalIdNotUnique;
    case ERROR_CODES.LEGAL_REGISTRATION_NUMBER_ALREADY_EXISTS:
      return locales.legalRegistrationNumberAlreadyExists;
    case ERROR_CODES.SCHEDULED_BANK_TRANSFER_ENDS_AT_BEFORE_OR_SAME_AS_FIRST_OCCURRENCE:
      return locales.scheduledBankTransferEndsAtBeforeOrSameAsFirstOccurrence;
    case ERROR_CODES.SCHEDULED_BANK_TRANSFER_INVALID_ENDS_AT:
      return locales.scheduledBankTransferInvalidEndsAt;
    case ERROR_CODES.SCHEDULED_BANK_TRANSFER_INVALID_FIRST_OCCURRENCE:
      return locales.scheduledBankTransferInvalidFirstOccurrence;
    case ERROR_CODES.SCHEDULED_BANK_TRANSFER_UPDATE_FORBIDDEN:
      return locales.scheduledBankTransferUpdateForbidden;
    case ERROR_CODES.TREEZOR_PAYOUT_AMOUNT_TOO_HIGH:
      return locales.insuficientFunds;
    case ERROR_CODES.TREEZOR_BANKACCOUNT_BIC_NOT_VALID:
    case ERROR_CODES.TREEZOR_BANKACCOUNT_BIC_NOT_VALID_2:
    case ERROR_CODES.TREEZOR_BENEFICIARY_BIC_NOT_VALID:
      return locales.bicNotValid;
    case ERROR_CODES.TREEZOR_BANKACCOUNT_IBAN_NOT_VALID:
    case ERROR_CODES.TREEZOR_BENEFICIARY_IBAN_NOT_VALID:
      return locales.ibanNotValid;
    case ERROR_CODES.TREEZOR_BANKACCOUNT_IBAN_ALREADY_EXISTS:
      return locales.trzBankAccountIbanAlreadyExists;
    case ERROR_CODES.USER_IS_UNDER_18:
      return locales.userIsUnder18Error;
    case ERROR_CODES.TEMPORARY_WALLET_CREATION_LIMIT_REACHED:
      return locales.temporaryWalletCreationLimitReached;
    default:
      logger.error(
        `Could not find associated locales for error codes ${errorCode}`,
      );
      return null;
  }
};

export default getLocalizedErrorMessage;
