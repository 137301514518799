import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { KYC_DOCUMENTS_TYPES } from '@shinetools/kyc-validation';

import { OutstandingAgreementType, Role } from '__generated__/GQL';
import asBentoModule from 'common/bento/hoc/asBentoModule';
import withStateMachine from 'common/bento/hoc/withStateMachine/withStateMachine';
import assertNever from 'common/bento/lib/assertNever';
import stringifyState from 'common/bento/lib/stringifyState';
import Agreement from 'common/bento/modules/Agreement';
import { BentoModuleDoneStatus } from 'common/bento/types/BentoModule';
import SuspenseQuery from 'components/SuspenseQuery';
import LoaderPage from 'features/TeamOnboarding/Onboarding/components/LoaderPage';
import NavigationContext from 'features/TeamOnboarding/Onboarding/components/Navigation/NavigationContext';

import Verification from '../../modules/Verification';
import { TeamOnboardingAdminDataDocument } from './graphql/queries/teamOnboardingAdminData.gql';
import machineConfig, { model, State } from './machine';
import getNavigationSteps from './navigation/getNavigationSteps';
import UnderReview from './pages/UnderReview';
import WelcomeAdmin from './pages/WelcomeAdmin';

const AdminComponent = withStateMachine<typeof model>(
  ({ machine, moduleData }) => {
    const [state, send] = machine;

    const stringifiedState = stringifyState<State>(state);

    const onPrev = useCallback(() => send(model.events.PREV()), [send]);

    let pageContent = null;

    switch (stringifiedState) {
      case State.INIT:
        pageContent = <LoaderPage onPrev={() => null} />;
        break;

      case State.WELCOME: {
        const { company, companyUser } = moduleData.data.viewer;

        pageContent = (
          <WelcomeAdmin
            adminName={`${companyUser.invitation?.inviteeFirstName} ${companyUser.invitation?.inviteeLastName}`}
            companyName={company.profile.legalName}
            onContinue={() => send(model.events.NEXT())}
            onPrev={onPrev}
          />
        );
        break;
      }

      case State.VERIFICATION:
        pageContent = (
          <Verification
            companyProfileId={moduleData.data.viewer.company.companyProfileId}
            onDone={({ status }) =>
              status === BentoModuleDoneStatus.DONE
                ? send(model.events.NEXT())
                : send(model.events.PREV())
            }
          />
        );
        break;

      case State.AGREEMENT:
        pageContent = (
          <Agreement
            onDone={({ status }) =>
              status === BentoModuleDoneStatus.DONE
                ? send(model.events.NEXT())
                : send(model.events.PREV())
            }
            type={OutstandingAgreementType.Cgu}
          />
        );
        break;

      case State.SEND_APPLICATION:
        pageContent = <LoaderPage onPrev={() => null} />;
        break;

      case State.UNDER_REVIEW: {
        const { companyUser, profile } = moduleData.data.viewer;

        pageContent = (
          <UnderReview
            adminEmail={profile.email ?? ''}
            inviterName={companyUser.invitation?.inviterName ?? ''}
            onPrev={onPrev}
          />
        );
        break;
      }

      default:
        pageContent = assertNever(stringifiedState);
        break;
    }

    return (
      <NavigationContext.Provider
        value={{
          steps: getNavigationSteps(stringifiedState),
        }}
      >
        {pageContent}
      </NavigationContext.Provider>
    );
  },

  {
    devTools: true,
    machineConfig,
  },
);

const Admin = asBentoModule<{
  companyProfileId: string;
}>(({ companyProfileId, onDone }) => {
  const query = useQuery(TeamOnboardingAdminDataDocument, {
    variables: {
      companyProfileId,
      kycDocumentTypes: KYC_DOCUMENTS_TYPES,
    },
  });

  return (
    <SuspenseQuery loader={<LoaderPage onPrev={() => null} />} query={query}>
      {(data) => {
        if (data.viewer.companyUser.roles.includes(Role.AccountHolder)) {
          throw new Error(
            'Beneficiary module cannot be accessed by account holders',
          );
        }

        return <AdminComponent moduleData={{ data }} onDone={onDone} />;
      }}
    </SuspenseQuery>
  );
});

export default Admin;
