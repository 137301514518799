import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    addAddressComplement: 'Add address complement',
    cityLabel: 'City',
    clear: 'Clear',
    inputs: {
      city: {
        label: 'City',
      },
      street: {
        errors: {
          limitedLength: 'The street cannot have more than {0} characters',
        },
        label: 'Professional address',
        searchPlaceholder: 'Search your company’s address',
      },
      zip: {
        errors: {
          invalid: 'The zip code should contain 5 digits',
        },
        label: 'Zip',
      },
    },
    manualEntrySubTitle:
      'This information must match the one indicated on your Kbis.',
    manualEntryTitle: 'Enter your professional address',
    save: 'Save',
    streetAdditionLabel: 'Address second line',
    streetAdditionPlaceholder: 'Building, stair, floor...',
    zipLabel: 'Zip',
  },
  fr: {
    addAddressComplement: 'Ajouter un complément d’adresse',
    cityLabel: 'Ville',
    clear: 'Effacer',
    inputs: {
      city: {
        label: 'Ville',
      },
      street: {
        errors: {
          limitedLength: 'La rue ne peut pas dépasser {0} caractères',
        },
        label: 'Adresse professionnelle',
        searchPlaceholder: 'Rechercher l’adresse de votre entreprise',
      },
      zip: {
        errors: {
          invalid: 'Le code postal doit comporter 5 chiffres',
        },
        label: 'Code postal',
      },
    },
    manualEntrySubTitle:
      'Ces informations doivent correspondre à celles qui sont indiquées sur votre Kbis.',
    manualEntryTitle: 'Saisissez votre adresse professionnelle',
    save: 'Enregistrer',
    streetAdditionLabel: 'Complément d’adresse',
    streetAdditionPlaceholder: 'Batiment, escalier, étage...',
    zipLabel: 'Code postal',
  },
} as const);

export default locales;
