import TrackView from 'features/Analytics/TrackView';

// this is the top-level ErrorView
// we can't use any styles here because the theme provider is not initialized yet

const ErrorViewBlank = () => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
    }}
  >
    <TrackView pageName="ErrorViewBlank" />
    <div
      style={{
        color: '#17294D',
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
        maxWidth: 350,
        padding: 8,
        textAlign: 'center',
      }}
    >
      <h1
        style={{
          fontSize: 26,
          fontWeight: 500,
          lineHeight: '36px',
        }}
      >
        An error occurred
      </h1>

      <p
        style={{
          color: '#455980',
          fontSize: 15,
          fontWeight: 400,
          lineHeight: '22px',
          marginTop: 16,
        }}
      >
        Oops, an error occurred!
        <br />
        Please try again in a few minutes and don’t hesitate to contact us if
        the error remains.
      </p>
    </div>
  </div>
);

export default ErrorViewBlank;
