import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

import PlanDetails from '../../components/PlanDetails';

export const BottomMenu = styled(Flex).attrs({
  align: 'center',
  direction: 'column',
  marginTop: 'space-32',
  padding: 'space-24',
})``;

export const NavMenu = styled(Flex).attrs({
  paddingX: 'space-24',
  paddingY: 'space-16',
})``;

export const SpacedPlanDetails = styled(PlanDetails)`
  &:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacings.abs32}px;
  }
`;
