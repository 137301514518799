import * as Types from '../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ActionRequestQueryVariables = Types.Exact<{
  authenticationActionRequestId: Types.Scalars['ID']['input'];
}>;


export type ActionRequestQuery = { __typename: 'Query', actionRequest: { __typename: 'ActionRequest', authenticationActionRequestId: string, action: string, payload: string, status: Types.ActionRequestStatus, updatedAt: string, expiresAt: string, client: { __typename: 'ThirdPartyClient', authenticationClientId: string, displayName: string, logoURL: string }, recipient: { __typename: 'BankTransferRecipient', bankTransferRecipientId: string, name: string } | null } };


export const ActionRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"actionRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"authenticationActionRequestId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"actionRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"authenticationActionRequestId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"authenticationActionRequestId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authenticationActionRequestId"}},{"kind":"Field","name":{"kind":"Name","value":"action"}},{"kind":"Field","name":{"kind":"Name","value":"client"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authenticationClientId"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"logoURL"}}]}},{"kind":"Field","name":{"kind":"Name","value":"recipient"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bankTransferRecipientId"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"payload"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"expiresAt"}}]}}]}}]} as unknown as DocumentNode<ActionRequestQuery, ActionRequestQueryVariables>;