import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    independentDescription: 'Under the micro-entreprise legal form',
    independentTitle: 'Auto-entreprise',
    societyDescription: 'SASU, SAS, SARL, SA, SCI, EURL...',
    societyTitle: 'Company',
    submit: 'Continue',
    title: 'What type of company would you like to create?',
    unknownDescription: 'No problem, we’re here to help!',
    unknownTitle: 'I am not sure',
  },

  fr: {
    independentDescription: 'Sous le régime de la micro-entreprise',
    independentTitle: 'Auto-entreprise',
    societyDescription: 'SASU, SAS, SARL, SCI...',
    societyTitle: 'Société',
    submit: 'Valider',
    title: 'Quel type d’entreprise souhaitez-vous créer\u00a0?',
    unknownDescription: 'Aucun problème, nous sommes là pour vous aider\u00a0!',
    unknownTitle: 'Je ne sais pas encore',
  },
} as const);

export default locales;
