import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    content:
      'We will now verify your identity to finalize the creation of your access',
    continue: 'Continue',
    title: 'Welcome, {firstName} {lastName}!',
  },
  fr: {
    content:
      'Nous allons maintenant effectuer une vérification d’identité pour finaliser la création de votre accès',
    continue: 'Continuer',
    title: 'Bienvenue, {firstName} {lastName}\xa0!',
  },
} as const);

export default locales;
