import { type ThemeComponents } from '@chakra-ui/react';

export const quotaTheme = {
  baseStyle: {
    body: {
      '&[data-expandable=true]': {
        _hover: {
          cursor: 'pointer',
        },
      },

      _hover: {
        backgroundColor: 'grey.100',
      },

      alignItems: 'center',
      display: 'flex',
      gap: 'space-16',
      padding: 'space-24',
    },
    body_content: {
      alignItems: 'center',
      display: 'flex',
      gap: 'space-16',
      width: 'full',
    },
    body_trigger: {
      color: 'grey.600',
      marginLeft: 'auto',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 'space-4',
    },
    exceeding: {
      marginLeft: 'auto',
    },
    footer: {
      backgroundColor: 'grey.100',
      borderTop: '1px solid',
      borderTopColor: 'grey.300',
      padding: 'space-24',
    },
    progress: {},
    progress_icon: {
      color: 'grey.800',
    },
    quota: {
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: 'grey.300',
      borderRadius: 'radius-8',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
  },
} as const satisfies ThemeComponents['Quota'];
