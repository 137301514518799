import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    help: 'Help',
    logout: 'Log Out',
  },
  fr: {
    help: 'Aide',
    logout: 'Se déconnecter',
  },
} as const);
