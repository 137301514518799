import LocalizedStrings from 'localized-strings';

import { IdDocumentCase } from '__generated__/GQL';

const locales = new LocalizedStrings({
  en: {
    cta: {
      next: `Next`,
      skip: `Skip`,
    },
    step1: {
      body: `This step helps us confirm you are who you say you are. You’ll be redirected to our partner’s platform. `,
      moreInfoLink: 'More information',
      title: `Verify your identity`,
    },
    step2: {
      [IdDocumentCase.AcceptedPassportOrIdCard]: {
        body: `We’ll ask you for a photo of an ID document to verify your information. You can also use a passeport.`,
        title: `You’ll need an ID`,
      },
      [IdDocumentCase.ValidResidencyCard]: {
        body: 'We’ll ask you for a photo of a valid residency card',
        title: 'Get your residence permit',
      },
      [IdDocumentCase.ExpiredResidencyCard]: {
        body: 'We’ll ask you for a photo of your passport',
        title: 'Get your passport',
      },
      [IdDocumentCase.Visa]: {
        body: 'We’ll ask you for a photo of your passport',
        title: 'Get your passport',
      },
      [IdDocumentCase.NoDocument]: {
        body: 'We’ll ask you for a photo of your passport',
        title: 'Get your passport',
      },
    },
    step3: {
      body: `This step helps us confirm you are who you say you are. The photo remains entirely confidential.`,
      title: `Take a selfie`,
    },
  },
  fr: {
    cta: {
      next: 'Suivant',
      skip: 'Passer',
    },
    step1: {
      body: 'Cette étape nous permet de confirmer que vous êtes la personne que vous indiquez être. Vous serez redirigé·e vers la plateforme de notre partenaire. ',
      moreInfoLink: "Plus d'informations",
      title: 'Vérifiez votre identité',
    },
    step2: {
      [IdDocumentCase.AcceptedPassportOrIdCard]: {
        body: 'Nous allons vous demander une photo de votre carte d’identité ou de votre passeport',
        title: 'Munissez-vous d’une pièce d’identité',
      },
      [IdDocumentCase.ValidResidencyCard]: {
        body: 'Nous allons vous demander une photo de votre titre de séjour valide',
        title: 'Munissez-vous de votre titre de séjour',
      },
      [IdDocumentCase.ExpiredResidencyCard]: {
        body: 'Nous allons vous demander une photo de votre passeport',
        title: 'Munissez-vous de votre passeport',
      },
      [IdDocumentCase.Visa]: {
        body: 'Nous allons vous demander une photo de votre passeport',
        title: 'Munissez-vous de votre passeport',
      },
      [IdDocumentCase.NoDocument]: {
        body: 'Nous allons vous demander une photo de votre passeport',
        title: 'Munissez-vous de votre passeport',
      },
    },
    step3: {
      body: 'Cette étape nous permet de confirmer que vous êtes la personne que vous indiquez être. La photo reste entièrement confidentielle.',
      title: 'Prenez-vous en photo',
    },
  },
} as const);

export default locales;
