import { useMutation } from '@apollo/client';
import { TeamOnboardingTutorialsCardDocument } from 'Main/SecuredRoutes/hooks/useHasTeamOnboardingTutorialsCard/teamOnboardingTutorialsCardDocument.gql';

import { EntityType } from '__generated__/GQL';
import useCompanyContext from 'common/hooks/useCompanyContext';

import { ToggleCardDoneDocument } from './toggleCardDone.gql';

const useToggleCardDone = (cardId: string) => {
  const { companyUserId } = useCompanyContext();
  return useMutation(ToggleCardDoneDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: TeamOnboardingTutorialsCardDocument,
        variables: {
          filters: {
            entityId: companyUserId,
            entityType: EntityType.CompanyUser,
          },
        },
      },
    ],
    variables: {
      input: {
        cardId,
        done: true,
      },
    },
  });
};

export default useToggleCardDone;
