import { useHistory } from 'react-router';
import { useSubscription } from '@apollo/client';
import { IsUserAccountClosedDocument } from 'Main/SecuredRoutes/hooks/useLogoutClosedUserAccount/graphql/subscriptions/isUserAccountClosed.gql';

import { UserMetadataStatus } from '__generated__/GQL';

const useLogoutClosedUserAccount = () => {
  const history = useHistory();
  const { data } = useSubscription(IsUserAccountClosedDocument);

  if (data?.updatedUserMetadata.status === UserMetadataStatus.Closed) {
    history.push('/logout');
  }
};

export default useLogoutClosedUserAccount;
