import { Flex, Image, VStack } from '@chakra-ui/react';

import iddpImg from 'assets/brand/iddp@2x.png';
import asBentoPage from 'common/bento/hoc/asBentoPage';
import Button from 'components/_core/Button';
import Callout from 'components/_core/Callout';
import Heading from 'components/_core/Heading';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import locales from './locales';

interface IdentityReviewErrorProps {
  errors: string[];
  onContinue: () => void;
}

const IdentityReviewError = asBentoPage<IdentityReviewErrorProps>(
  ({ errors, Layout, onContinue, onPrev }) => (
    <Layout nav={<Navigation onPrev={onPrev} />}>
      <Flex direction="column" height={{ base: 'full', md: 'auto' }}>
        <Image
          height="180px"
          marginBottom="space-16"
          src={iddpImg}
          width="180px"
        />

        <Heading marginBottom="space-24" size="lg">
          {locales.title}
        </Heading>

        <VStack gap="space-16">
          {errors.map((error) => (
            <Callout key={error} variant="error">
              <Callout.Content>{error}</Callout.Content>
            </Callout>
          ))}
        </VStack>

        <Button
          alignSelf="start"
          icon="arrow-right"
          marginTop="space-32"
          onClick={onContinue}
        >
          {locales.next}
        </Button>
      </Flex>
    </Layout>
  ),
);

export default IdentityReviewError;
