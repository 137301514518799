import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    blockedByServer: 'Can’t connect right now. Try again later',
    cta: 'Verify my number',
    help: 'You will receive a verification code by SMS',
    invalidPhone: 'Invalid phone number format',
    title: 'Enter your phone number to create your account',
  },

  fr: {
    blockedByServer:
      'Connexion impossible pour le moment, veuillez-réessayer ultérieurement',
    cta: 'Vérifier mon numéro',
    help: 'Vous allez recevoir un code de vérification par SMS',
    invalidPhone: 'Le format du numéro de téléphone est invalide',
    title: 'Entrez votre numéro de téléphone pour créer votre compte',
  },
} as const);

export default locales;
