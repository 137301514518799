import { useCallback } from 'react';
import {
  Box,
  type BoxProps,
  type ComponentWithAs,
  forwardRef,
  useControllableState,
  type UseDisclosureProps,
} from '@chakra-ui/react';

import { QuotaContext, type QuotaState } from './context';
import * as Part from './parts';
import { quotaTheme } from './theme';

type QuotaParts = {
  Body: typeof Part.Body;
  Content: typeof Part.Content;
  Description: typeof Part.Description;
  Exceeding: typeof Part.Exceeding;
  Footer: typeof Part.Footer;
  Label: typeof Part.Label;
  Progress: typeof Part.Progress;
};

export type QuotaProps = BoxProps &
  UseDisclosureProps & {
    isExpandable?: boolean;
  };

export const Quota = forwardRef<QuotaProps, 'div'>((props, ref) => {
  const {
    __css: css,
    children,
    defaultIsOpen,
    isExpandable,
    isOpen: isOpenProp,
    onClose,
    onOpen,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useControllableState({
    defaultValue: defaultIsOpen,
    onChange: (nextIsOpen) => (nextIsOpen ? onOpen?.() : onClose?.()),
    value: isOpenProp,
  });

  const onToggle = useCallback(
    () => setIsOpen((prevIsOpen) => !prevIsOpen),
    [setIsOpen],
  );

  const quotaContext = {
    defaultIsOpen,
    isExpandable,
    isOpen,
    onClose,
    onOpen,
    onToggle,
    theme: quotaTheme.baseStyle,
  } satisfies QuotaState;

  return (
    <Box
      __css={{
        ...quotaTheme.baseStyle.quota,
        ...css,
      }}
      className="quota"
      {...rest}
      ref={ref}
    >
      <QuotaContext.Provider value={quotaContext}>
        {children}
      </QuotaContext.Provider>
    </Box>
  );
}) as ComponentWithAs<'div', QuotaProps> & QuotaParts;

Quota.Body = Part.Body;
Quota.Content = Part.Content;
Quota.Description = Part.Description;
Quota.Exceeding = Part.Exceeding;
Quota.Footer = Part.Footer;
Quota.Label = Part.Label;
Quota.Progress = Part.Progress;
