import * as Types from '../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AcceptCardPaymentStrongAuthenticationRequestMutationVariables = Types.Exact<{
  input: Types.AcceptCardPaymentStrongAuthenticationRequestInput;
}>;


export type AcceptCardPaymentStrongAuthenticationRequestMutation = { __typename: 'Mutation', acceptCardPaymentStrongAuthenticationRequest: { __typename: 'CardPaymentStrongAuthenticationRequest', cardPaymentStrongAuthenticationRequestId: string, status: Types.CardPaymentStrongAuthenticationRequestStatus, authenticationDevice: { __typename: 'AuthenticationDevice', authenticationDeviceId: string, model: string | null, name: string | null, platform: Types.AuthenticationDevicePlatform | null, status: Types.AuthenticationDeviceStatus, token: string } | null } };


export const AcceptCardPaymentStrongAuthenticationRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"acceptCardPaymentStrongAuthenticationRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptCardPaymentStrongAuthenticationRequestInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptCardPaymentStrongAuthenticationRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cardPaymentStrongAuthenticationRequestId"}},{"kind":"Field","name":{"kind":"Name","value":"authenticationDevice"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authenticationDeviceId"}},{"kind":"Field","name":{"kind":"Name","value":"model"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"platform"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"token"}}]}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<AcceptCardPaymentStrongAuthenticationRequestMutation, AcceptCardPaymentStrongAuthenticationRequestMutationVariables>;