import { type FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useQuery } from '@apollo/client';
import { Stack } from '@chakra-ui/react';
import { Typography } from '@shinetools/sunshine-universal';

import Button from 'components/_core/Button';
import Modal, { type ModalProps } from 'components/_core/Modal';

import { Table } from '../Table';
import * as GQL from './fees.gql';

export type ModalFeesProps = Omit<ModalProps, 'children'> & {
  companyProfileId: string;
};

export const ModalFees: FC<ModalFeesProps> = (props) => {
  const { companyProfileId, ...rest } = props;

  const { data, loading } = useQuery(GQL.SubscriptionManagementFeesDocument, {
    variables: {
      companyProfileId: companyProfileId,
    },
  });

  const fees = data?.viewer.company.currentPlan.fees;
  const quota = data?.viewer.company.subscriptionPlan?.quotas;

  return (
    <Modal {...rest}>
      <Modal.Header borderBottom="1px solid" borderColor="grey.3" />
      <Modal.Body paddingY="space-32">
        <Stack spacing="space-32">
          <Stack spacing="space-8">
            <Typography.Header>
              <FormattedMessage id="subscription.fees_modal.heading" />
            </Typography.Header>

            <Typography.Text variant="secondary">
              <FormattedMessage id="subscription.fees_modal.subheading" />
            </Typography.Text>
          </Stack>

          <Table>
            <Table.Header>
              <Table.Column>
                <FormattedMessage id="subscription.fees_modal.column_label" />
              </Table.Column>

              <Table.Column>
                <FormattedMessage id="subscription.fees_modal.column_value" />
              </Table.Column>
            </Table.Header>
            <Table.Body>
              <Table.LoadingRow
                in={!data && loading}
                noOfColumns={2}
                noOfLinesTemplate={[1, 1]}
                noOfRows={1}
              />

              {!loading ? (
                <Table.Row>
                  <Table.Cell>
                    <Typography.Text>
                      <FormattedMessage id="subscription.fees_modal.sepa_transfer.label" />
                    </Typography.Text>
                  </Table.Cell>

                  <Table.Cell>
                    <Typography.Text>
                      <FormattedMessage
                        id="subscription.fees_modal.sepa_transfer.value"
                        values={{
                          fee: (
                            <FormattedNumber
                              currency="EUR"
                              style="currency"
                              value={
                                (fees?.additionalSepaTransfer.fixed ?? 0) / 100
                              }
                            />
                          ),
                          included: quota?.sepaTransferMonthQuota.limit,
                        }}
                      />
                    </Typography.Text>
                  </Table.Cell>
                </Table.Row>
              ) : null}
            </Table.Body>
          </Table>
        </Stack>
      </Modal.Body>
      <Modal.Footer justifyContent="flex-end">
        <Button isLoading={loading} onClick={props.onClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
