import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description:
      'Get all bank statements from the creation of the account, with no time limit and download them with a single click.',
    title: 'Get all bank statements of {companyName}',
  },
  fr: {
    description:
      'Retrouvez tous les relevés bancaires depuis la création du compte, sans limite dans le temps, et téléchargez-les en un clic.',
    title: 'Accédez à tous les relevés bancaires de {companyName}',
  },
} as const);

export default locales;
