import styled from 'styled-components';

import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';

export const Title = styled(Heading).attrs({
  'aria-level': 1,
  role: 'heading',
})`
  margin-bottom: ${({ theme }) => theme.spacings.abs16}px;
`;

export const SubTitle = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacings.abs48}px;
`;
