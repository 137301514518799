import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description: `It appears that this phone number is already associated with an account.
    You can add a new account to your phone number by activating the multi-account option.
    To do this, you can find the step-by-step guide in our `,
    login: 'Login',
    multiAccountLink: `help center devoted to multi-accounting.`,
    prompt: 'Do you already have an account, or have started to register?',
    title: 'This phone number is already associated with a Shine account',
  },

  fr: {
    description: `Il semblerait que ce numéro de téléphone soit déjà associé à un compte.
    Vous pouvez ajouter un nouveau compte à votre numéro de téléphone en activant l’option multi-comptes. 
    Pour ce faire, retrouvez le pas-à-pas dans notre `,
    login: 'Se connecter',
    multiAccountLink: `centre d'aide dédié au multi-compte.`,
    prompt: 'Vous avez déjà un compte ? Vous avez commencé à vous inscrire ?',
    title: 'Ce numéro de téléphone est déjà associé à un compte',
  },
} as const);

export default locales;
