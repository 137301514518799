import { getLocalizedErrorMessage } from 'common/errors';

import localizedErrors from './localizedErrors';
import {
  getApolloErrorCode,
  hasOBErrorCode,
  isNetworkError,
  isServerError,
} from './utils';

/**
 * Given any error, retrieves the most relevant error message possible.
 *
 * Will pick up these errors :
 * - An OBError (meaning the error.message is listed in localizedErrors)
 * - An Apollo network error
 * - Any GraphQL error known in the error library
 *
 * Returns a generic error in other cases.
 *
 * @example <caption>
 *   // Throw this, then use `getErrorMessage` on it.
 *   throw Error(OBError.WRONG_PASSCODE)
 * </caption>
 */
const getErrorMessage = (error: Error) => {
  if (
    'localizedMessage' in error &&
    typeof error.localizedMessage === 'string'
  ) {
    return error.localizedMessage;
  }

  if (hasOBErrorCode(error)) {
    return localizedErrors[error.message];
  }

  if (isNetworkError(error)) {
    return localizedErrors.NETWORK;
  }

  if (isServerError(error)) {
    const message = getLocalizedErrorMessage(getApolloErrorCode(error));

    if (message) {
      return message;
    }
  }

  return localizedErrors.UNRECOGNIZED;
};

export default getErrorMessage;
