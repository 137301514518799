import { Box, type BoxProps, forwardRef } from '@chakra-ui/react';

import { useTable } from '../context';

export type BodyProps = BoxProps;

export const Body = forwardRef<BodyProps, 'div'>((props, ref) => {
  const { __css: css, ...rest } = props;
  const { theme } = useTable();

  return (
    <Box
      __css={{ ...theme.body, ...css }}
      className="table-body"
      {...rest}
      ref={ref}
    />
  );
});
