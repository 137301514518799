import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    options: {
      fewerThanTen: 'Company with fewer than 10 employees',
      moreThanTen: 'Company with 10 employees or more',
      solo: 'You work alone',
      soloDescription: 'Liberal profession, freelance, auto-entrepreneur',
    },
    title: 'Select your company profile',
  },

  fr: {
    options: {
      fewerThanTen: 'Entreprise de moins de 10 personnes',
      moreThanTen: 'Entreprise de 10 personnes ou plus',
      solo: 'Vous travaillez seul·e',
      soloDescription: 'Profession libérale, freelance, auto-entrepreneur',
    },
    title: 'Sélectionnez le profil de votre entreprise',
  },
} as const);

export default locales;
