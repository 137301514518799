/**
 * Wether this user context allow the step row to be shown
 */
const canShow = () => {
  return true;
};

/**
 * Returns the current status of this module for external usage
 * aka NOT the local status but the one infered from the saved application
 */
const getStatus = () => {
  throw new Error('to be implemented');
};

/**
 * Returns true if this module is considered done
 * ie the user has successfully completed all necessary steps
 * to be able to continue
 */
const isDone = () => {
  return true;
};

const status = {
  canShow,
  getStatus,
  isDone,
};

export default status;
