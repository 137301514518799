/* eslint-disable sort-keys-fix/sort-keys-fix */
/**
 * @see https://www.figma.com/file/TqOmecs9Hx6nwezatY2Yojdd/01.-Shine-Colors?node-id=3586%3A85
 */
const SHINE_COLORS = {
  brown: {
    '-2': '#B83A18',
    '-1': '#D95027',
    '0': '#F77036',
    '1': '#FBB59D',
    '2': '#FFDACC',
    '3': '#FFEBE0',
    '4': '#FFF3F0',
    '5': '#FFFCFB',
  },

  /**
   * Alias to the CTABlue shine color.
   */
  blue: {
    '-2': '#0D5ABF',
    '-1': '#0C6CF3',
    '0': '#5CA3FF',
    '1': '#A8D4FF',
    '2': '#D1E8FF',
    '3': '#E2F0FD',
    '4': '#F1F7FD',
    '5': '#FAFDFF',
  },

  ctaBlue: {
    '-2': '#0D5ABF',
    '-1': '#0C6CF3',
    '0': '#5CA3FF',
    '1': '#A8D4FF',
    '2': '#D1E8FF',
    '3': '#E2F0FD',
    '4': '#F1F7FD',
    '5': '#FAFDFF',
  },

  lightBlue: {
    '-2': '#1476BD',
    '-1': '#0E91E1',
    '0': '#52BCFF',
    '1': '#A8DEFF',
    '2': '#D2EEFF',
    '3': '#E5F5FF',
    '4': '#F0F9FF',
    '5': '#FAFDFF',
  },

  green: {
    '-2': '#3A8D42',
    '-1': '#48B14E',
    '0': '#76ED7C',
    '1': '#BAFCBD',
    '2': '#DDFDDD',
    '3': '#E5FFE7',
    '4': '#F0FFF0',
    '5': '#FBFFFB',
  },

  magenta: {
    '-2': '#9E1878',
    '-1': '#C936A6',
    '0': '#E85FCC',
    '1': '#F3A9E4',
    '2': '#FBD7F4',
    '3': '#FFE5FF',
    '4': '#FFF0FD',
    '5': '#FFFCFF',
  },

  orange: {
    '-2': '#C26604',
    '-1': '#E38016',
    '0': '#FFA238',
    '1': '#FFD39F',
    '2': '#FFEBD1',
    '3': '#FFF2E0',
    '4': '#FFF7F0',
    '5': '#FFFDFB',
  },

  pink: {
    '-2': '#B21E50',
    '-1': '#DA2B65',
    '0': '#F7578C',
    '1': '#FBABC6',
    '2': '#FDD7E3',
    '3': '#FFE5EE',
    '4': '#FDF1F5',
    '5': '#FFFCFD',
  },

  purple: {
    '-2': '#7229B3',
    '-1': '#9343D9',
    '0': '#B45EFF',
    '1': '#DAB0FF',
    '2': '#EDD9FF',
    '3': '#F3E5FF',
    '4': '#F7F0FF',
    '5': '#FEFCFF',
  },

  red: {
    '-2': '#B52430',
    '-1': '#DB394C',
    '0': '#F05B60',
    '1': '#F7ADAF',
    '2': '#FBD6D7',
    '3': '#FCE4E6',
    '4': '#FDF1F2',
    '5': '#FFFCFC',
  },

  turquoise: {
    '-2': '#068E70',
    '-1': '#07B58E',
    '0': '#13F0C0',
    '1': '#99FBE6',
    '2': '#D3FDF3',
    '3': '#E3FDF8',
    '4': '#ECFDF9',
    '5': '#FAFFFD',
  },

  yellow: {
    '-2': '#B57C08',
    '-1': '#E5B301',
    '0': '#FFD947',
    '1': '#FFEB9F',
    '2': '#FFF5CC',
    '3': '#FFF7DB',
    '4': '#FFFBEB',
    '5': '#FFFEF9',
  },

  grey: {
    '-2': '#17294D',
    '-1': '#455980',
    '0': '#8090B2',
    '1': '#B1BACC',
    '2': '#EDEEF1',
    '3': '#F3F5F6',
    '4': '#F9FAFB',
    '5': '#FCFDFE',
  },

  premiumPink: {
    '-2': '#E74E36',
    '-1': '#EA6F5D',
    '0': '#F39182',
    '1': '#FEA99A',
    '2': '#FFD2C7',
    '3': '#FFE2D9',
    '4': '#FFF0EB',
    '5': '#FFF8F5',
  },

  white: {
    '-2': '#FFF',
    '-1': '#FFF',
    '0': '#FFF',
    '1': '#FFF',
    '2': '#FFF',
    '3': '#FFF',
    '4': '#FFF',
    '5': '#FFF',
  },
};

export type ShineColors = keyof typeof SHINE_COLORS;

export default SHINE_COLORS;
