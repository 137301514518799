import * as Types from '../../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AcceptAgreementMutationVariables = Types.Exact<{
  input: Types.OutstandingAgreementInput;
}>;


export type AcceptAgreementMutation = { __typename: 'Mutation', agreeToOutstandingAgreement: { __typename: 'OutstandingAgreementPayload', outstandingAgreement: { __typename: 'OutstandingAgreement', agreementId: string, pdfDocumentUrl: string | null, status: Types.OutstandingAgreementStatus, type: Types.OutstandingAgreementType, updatedAt: string } | null } };


export const AcceptAgreementDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"acceptAgreement"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"OutstandingAgreementInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"agreeToOutstandingAgreement"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"outstandingAgreement"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"agreementId"}},{"kind":"Field","name":{"kind":"Name","value":"pdfDocumentUrl"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}}]} as unknown as DocumentNode<AcceptAgreementMutation, AcceptAgreementMutationVariables>;