import LocalizedStrings from 'localized-strings';

import { OutstandingAgreementType } from '__generated__/GQL';

const locales = new LocalizedStrings({
  en: {
    checkbox: {
      [OutstandingAgreementType.Cgu]:
        'I have read and agree to the document’s terms',
      [OutstandingAgreementType.NotaryFourez]:
        'I have read and agree to the document’s terms',
    },
    cta: 'Accept and sign',
    description: {
      [OutstandingAgreementType.Cgu]:
        'Please read and agree to our terms and conditions before sending your documents for validation.',
      [OutstandingAgreementType.NotaryFourez]:
        'Please read and agree to our capital deposit agreement before sending your documents for validation.',
    },
    title: {
      [OutstandingAgreementType.Cgu]: 'One last thing',
      [OutstandingAgreementType.NotaryFourez]:
        'Agree to the capital deposit agreement',
    },
  },
  fr: {
    checkbox: {
      [OutstandingAgreementType.Cgu]:
        'J’ai lu et j’accepte les conditions d’utilisation de Shine',
      [OutstandingAgreementType.NotaryFourez]:
        'J’ai lu et j’accepte les conditions du dépôt de capital',
    },
    cta: 'Accepter et signer',
    description: {
      [OutstandingAgreementType.Cgu]:
        'Lisez et acceptez les conditions d’utilisation de Shine avant de nous transmettre votre dossier pour validation.',
      [OutstandingAgreementType.NotaryFourez]:
        'Lisez et acceptez les conditions du dépôt de capital avant de nous transmettre votre dossier pour validation.',
    },
    title: {
      [OutstandingAgreementType.Cgu]: 'Dernière étape',
      [OutstandingAgreementType.NotaryFourez]:
        'Accepter les conditions du dépôt de capital',
    },
  },
} as const);

export default locales;
