import { type SubscriptionManagementPlansDetailFragment } from 'features/SubscriptionManagement/subscriptionManagementPlansDetail.gql';

import * as routes from '../../../routes';
import { type SubscriptionManagementPlansQuery } from '../plans.gql';
import { PLANS_METADATA } from '../plansMetadata';
import { type DowngradeBlockerReason } from './DowngradeBlockerDialog';

export const getDowngradeBlockerReason = ({
  plans,
  targetPlan,
}: {
  plans: SubscriptionManagementPlansQuery;
  targetPlan: SubscriptionManagementPlansDetailFragment;
}): DowngradeBlockerReason | null => {
  const walletsCount =
    plans.viewer.company.subscriptionPlan!.quotas.activeBankAccountsQuota.used;

  const newWalletLimit = targetPlan.limits.activeBankAccounts.isUnlimited
    ? Infinity
    : targetPlan.limits.activeBankAccounts.value;

  if (walletsCount > newWalletLimit) {
    return {
      currentWalletCount: walletsCount,
      newLimit: newWalletLimit,
      targetPlanName: targetPlan.brandName,
      type: 'bank-account-limit',
    };
  }

  const isDowngrade = plans.viewer.company.currentPlan.downgradablePlans.some(
    ({ providerPlanId }) => providerPlanId === targetPlan.providerPlanId,
  );

  if (isDowngrade) {
    const lostFeatures =
      PLANS_METADATA[plans.viewer.company.currentPlan.providerPlanId]
        .lostFeaturesOnDowngrade[targetPlan.providerPlanId];

    if (lostFeatures) {
      return {
        continueRoute: `${routes.plans}/${targetPlan.providerPlanId}`,
        lostFeatures: lostFeatures,
        targetPlanName: targetPlan.brandName,
        type: 'discourager',
      };
    }
  }

  return null;
};
