import { type FC } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';

import { Quota, type QuotaProps } from '../components/Quota';
import { useQuota } from '../context';

export type SepaTransferQuotaProps = QuotaProps;

export const SepaTransferQuota: FC<SepaTransferQuotaProps> = (props) => {
  const { fees, planName, quotas } = useQuota();
  const intl = useIntl();

  const quota = quotas.sepaTransferMonthQuota;
  const fee = fees.additionalSepaTransfer;
  const exceeding = 0;

  const locales = {
    additional_fee: intl.formatMessage(
      {
        id: 'subscription.overview.quotas.sepa_transfer.additional_fee',
      },
      {
        fixed: (
          <FormattedNumber
            currency="EUR"
            style="currency"
            value={fee.fixed / 100}
          />
        ),
      },
    ),
    description: intl.formatMessage(
      {
        id: 'subscription.overview.quotas.sepa_transfer.description',
      },
      {
        limit: quota.limit,
        plan: planName,
        used: quota.used,
      },
    ),
    exceeding: intl.formatMessage(
      {
        id: 'subscription.overview.quotas.exceeding',
      },
      {
        exceeding: (
          <FormattedNumber currency="EUR" style="currency" value={exceeding} />
        ),
      },
    ),
    label: intl.formatMessage({
      id: 'subscription.overview.quotas.sepa_transfer.label',
    }),
  };

  return (
    <Quota isExpandable {...props}>
      <Quota.Body>
        <Quota.Progress
          icon="bulk-transfer"
          max={quota.limit}
          value={quota.used}
        />

        <Quota.Content>
          <Quota.Label>{locales.label}</Quota.Label>
          <Quota.Description>{locales.description}</Quota.Description>
        </Quota.Content>

        {/* TODO: fetch exceeding quota once available from billing-service */}
        {/* <Quota.Exceeding>{locales.exceeding}</Quota.Exceeding> */}
      </Quota.Body>

      <Quota.Footer>{locales.additional_fee}</Quota.Footer>
    </Quota>
  );
};
