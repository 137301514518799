import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    content: 'Manage your business expenses independently at {companyName}.',
    title: 'Thank you, your access has been created!',
  },
  fr: {
    content:
      'Gérez vos dépenses professionnelles en toute autonomie chez {companyName}.',
    title: 'Merci, votre accès a été créé\xA0!',
  },
} as const);

export default locales;
