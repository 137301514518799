/**
 * This function should be used with an argument that **cannot have a value**.
 *
 * For example, you can create a type-safe switch construct by calling `assertNever(x)`
 * in the `default` case, if all possible values of `x` have been tested against.
 */
const assertNever = (shouldBeNever: never) => {
  throw Error(`Unhandled code branch: <${shouldBeNever}>`);
};

export default assertNever;
