import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    closeButton: 'Close',
    contactSupport: 'Contact support',
    description:
      'You did not initiate this payment? Block your card via the app and contact our customer support immediately.',
    descriptionAlreadyRefused:
      'You have already rejected this payment from the device below:',
    goToPaymentCardsButton: 'Access my cards',
    title: 'Payment attempt rejected',
    usedCreditCard: 'Card used',
  },
  fr: {
    closeButton: 'Fermer',
    contactSupport: 'Contacter le support',
    description:
      'Vous n’êtes pas à l’initiative de ce paiement\u00a0? Bloquez votre carte via l’application et contactez immédiatement notre service client.',
    descriptionAlreadyRefused:
      'Vous avez déjà refusé ce paiement depuis l’appareil ci-dessous\u00a0:',
    goToPaymentCardsButton: 'Accéder à mes cartes',
    title: 'Tentative de paiement refusée',
    usedCreditCard: 'Carte utilisée',
  },
} as const);

export default locales;
