import { type CollapseProps, forwardRef } from '@chakra-ui/react';

import SkeletonText from 'components/SkeletonText';

import { useTable } from '../context';
import { Cell } from './Cell';
import { Row, type RowProps } from './Row';

export type LoadingRowProps = RowProps &
  Pick<CollapseProps, 'in'> & {
    noOfColumns?: number;
    noOfRows?: number;
    noOfLinesTemplate?: number[];
  };

export const LoadingRow = forwardRef<LoadingRowProps, 'div'>((props, ref) => {
  const {
    __css: css,
    in: inProp,
    noOfColumns = 1,
    noOfLinesTemplate = [],
    noOfRows = 1,
    ...rest
  } = props;
  const { theme } = useTable();

  if (!inProp) {
    return null;
  }

  return (
    <>
      {Array.from({ length: noOfRows }).map((_, rowIndex) => (
        <Row
          __css={{ ...theme.loadingRow, ...css }}
          className="table-loading-row"
          key={rowIndex}
          {...rest}
          ref={ref}
        >
          {Array.from({ length: noOfColumns }).map((__, columnIndex) => (
            <Cell key={`${rowIndex}-${columnIndex}`}>
              <SkeletonText
                noOfLines={noOfLinesTemplate[columnIndex] ?? 1}
                width="full"
              />
            </Cell>
          ))}
        </Row>
      ))}
    </>
  );
});
