import config from './helpers/config';
import getWebViewAPI from './helpers/getWebViewAPI';
import listenNativeMessage from './helpers/listenNativeMessage';
import postMessage from './helpers/postMessage';

const webView = {
  config,
  isInWebView: getWebViewAPI() !== null,
  listenNativeMessage,
  postMessage,
};

export default webView;
