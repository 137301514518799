import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    back: 'Close',
  },
  fr: {
    back: 'Fermer',
  },
} as const);

export default locales;
