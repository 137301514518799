export type { Animation, AnimationData } from './types';

/**
 * Specifying animations as dynamic imports let us import them on-the-fly on first usage.
 *
 * Keeping them in a single library here prevents duplicate JSON files
 * from being imported in different places.
 */
const ANIMATIONS = {
  loader: () => import('./loader.json'),
  'red-button': () => import('./red-button.json'),
  success: () => import('./success.json'),
} as const;

export default ANIMATIONS;
