import { type FC } from 'react';
import { Flex, type FlexProps } from '@chakra-ui/react';

export type FooterProps = FlexProps;

export const Footer: FC<FooterProps> = (props) => (
  <Flex
    borderBottomRadius="radius-8"
    borderTop="1px solid"
    borderTopColor="grey.300"
    justifyContent="space-between"
    {...props}
  />
);
