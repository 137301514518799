import { useCallback, useState } from 'react';

type UseToggleState = (initialState?: boolean) => [boolean, () => void];

const useToggleState: UseToggleState = (initialState = false) => {
  const [value, setValue] = useState(initialState);
  const toggleValue = useCallback(() => setValue(!value), [value]);
  return [value, toggleValue];
};

export default useToggleState;
