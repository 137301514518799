import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { KYC_DOCUMENTS_TYPES } from '@shinetools/kyc-validation';

import asBentoModule from 'common/bento/hoc/asBentoModule';
import withStateMachine from 'common/bento/hoc/withStateMachine/withStateMachine';
import assertNever from 'common/bento/lib/assertNever';
import stringifyState from 'common/bento/lib/stringifyState';
import SuspenseQuery from 'components/SuspenseQuery';
import LoaderPage from 'features/TeamOnboarding/Onboarding/components/LoaderPage';

import { AddressModuleDataDocument } from './graphql/queries/addressModuleData.gql';
import machineConfig, { model, State } from './machine';
import AddressFormPage from './pages/AddressFormPage';

interface AddressProps {
  companyProfileId: string;
}

const AddressComponent = withStateMachine<typeof model>(
  ({ machine }) => {
    const [state, send] = machine;

    const stringifiedState = stringifyState<State>(state);

    const onPrev = useCallback(() => send(model.events.PREV()), [send]);

    switch (stringifiedState) {
      case State.ADDRESS_FORM:
      case State.ADDRESS_SEND:
        return (
          <AddressFormPage
            initialValues={state.context.address}
            isSubmitting={state.matches(State.ADDRESS_SEND)}
            onPrev={onPrev}
            onSubmit={(values) => send(model.events.SUBMIT_ADDRESS(values))}
          />
        );

      case State.DONE:
      case State.ABORT:
      case State.ADDRESS_LOAD:
        return <LoaderPage onPrev={() => null} />;

      default:
        return assertNever(stringifiedState);
    }
  },
  {
    devTools: true,
    machineConfig,
  },
);

/**
 * This module is responsible to retrieve the personal address of the user and the supporting document.
 *
 * @input A user whose address is registered or not and with or without an uploaded document.
 * @output A user whose address is registered and backed by a proof of address document.
 */
const Address = asBentoModule<AddressProps>(({ companyProfileId, onDone }) => {
  const query = useQuery(AddressModuleDataDocument, {
    variables: {
      companyProfileId,
      kycDocumentTypes: KYC_DOCUMENTS_TYPES,
    },
  });

  return (
    <SuspenseQuery loader={<LoaderPage onPrev={() => null} />} query={query}>
      {(data) => <AddressComponent moduleData={data} onDone={onDone} />}
    </SuspenseQuery>
  );
});

export default Address;
