/**
 * Affilae URLs contain this pattern and are not landings.
 * We don't parse their content.
 */
const AFFILAE_URL = /(\?|&)ae=\d+/;

/**
 * Given a landing URL, retrieve the name of the affiliate
 * and save it in the named group `source`.
 *
 * @example <caption>
 *   INPUT : "http://localhost:8000/lp/offre/agence-juridique/"
 *   OUTPUT : "agence-juridique"
 * </caption>
 */
const AFF_SOURCE_PATTERN = /\/lp\/\w+\/(?<source>[\w-]+)/;

/**
 * Given the URL of a landing page of shine.fr,
 * this returns a string with the name of the affiliate,
 * or just "AFFILAE" if it's an affilae affiliate.
 */
const getAffiliateSource = (affHref: string | null) => {
  if (!affHref) {
    return null;
  }

  const isAffilae = AFFILAE_URL.test(affHref);

  /**
   * This string should be used nowhere, it's just so that we send something.
   * Reproducing existing behavior explained by Manu.
   */
  if (isAffilae) {
    return 'AFFILAE';
  }

  const match = affHref.match(AFF_SOURCE_PATTERN);

  /**
   * Parse the name of the partner in the URL.
   */
  return match?.groups?.source ?? null;
};

export default getAffiliateSource;
