import { colors as sunshineColors } from '@shinetools/sunshine';
import { mergeDeepRight } from 'ramda';

import CHAKRA_COLORS from './chakraColors';
import SHINE_COLORS from './shineColors';

const colors = {
  /**
   * That's not elegant but Chakra uses keys like `blue.500` for certain defaults.
   * So we make a cohabitation with our keys like `blue.-2` for the moment.
   */
  ...mergeDeepRight(
    mergeDeepRight(CHAKRA_COLORS, SHINE_COLORS),
    sunshineColors,
  ),
};

export default colors;
