import { CompanyInvitationRole } from '__generated__/GQL';

import locales from './locales';

export const isAccountant = (role?: CompanyInvitationRole) =>
  role === CompanyInvitationRole.Accountant;

export const isEmployee = (role?: CompanyInvitationRole) =>
  role === CompanyInvitationRole.Employee;

export const isAdmin = (role?: CompanyInvitationRole) =>
  role === CompanyInvitationRole.Admin;

export const isLegalBeneficiary = (role?: CompanyInvitationRole) =>
  role === CompanyInvitationRole.LegalBeneficiary;

export const getHeading = (role?: CompanyInvitationRole) => {
  if (isAccountant(role) || isEmployee(role)) {
    // invitation?.role can't be undefined here
    return locales.headingByRole[role as CompanyInvitationRole];
  }

  return locales.screenshotHeading;
};
