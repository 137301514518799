import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    allGood: 'All good! Continue',
    checkDescription: 'If this is you, read and tick the items below:',
    description: 'We have detected an attempt to log into your Shine account.',
    device: 'New device',
    deviceName: 'Name',
    deviceWhitelisted: 'My account can also be accessed from another device.',
    error: 'An error occured.',
    fakePhoneScamCalloutContent:
      "Scammers will use Shine's number to call you. They will ask you to enter confidential information or validate transactions remotely. If you suspect fraud on your account, temporarily block your cards using the application.",
    fakePhoneScamCalloutTitle: 'Fake phone advisor scam campaign',
    location: 'Location',
    loginAttempt: 'Login attempt',
    neverGiveAccess:
      'Neither Shine nor its partners will ask me to give them access to my account.',
    no: 'No, block the device',
    parisTimezone: '(Paris time)',
    title: 'Did you attempt to log into your account?',
    unknownDevice: 'Unknown device',
    unknownDeviceType: 'Unknown device type',
    unknownLocation: 'Unknown location',
    yes: 'Yes, it’s me',
  },
  fr: {
    allGood: 'C’est tout bon\xA0!',
    checkDescription:
      'Si vous êtes bien à l’origine de la demande, validez les éléments ci-dessous\xa0:',
    description:
      'Nous avons détecté une tentative de connexion à votre compte Shine.',
    device: 'Nouvel appareil',
    deviceName: 'Nom',
    deviceWhitelisted:
      'Mon compte pourra être aussi utilisé sur un autre appareil.',
    error: 'Une erreur est survenue.',
    fakePhoneScamCalloutContent:
      "Des arnaqueurs affichent le numéro de Shine pour vous appeler. Ils vous demanderont de saisir des informations confidentielles ou de valider des opérations à distance. En cas de fraude suspectée sur votre compte, bloquez temporairement vos cartes depuis l'application.",
    fakePhoneScamCalloutTitle:
      'Campagne d’arnaque au faux conseiller téléphonique',
    location: 'Lieu',
    loginAttempt: 'Tentative de connexion',
    neverGiveAccess:
      'Ni Shine ni ses partenaires ne me demanderont de leur donner accès à mon compte.',
    no: 'Non, bloquer l’appareil',
    parisTimezone: '(heure de Paris)',
    title: 'Tentez-vous de vous connecter à votre compte\xa0?',
    unknownDevice: 'Appareil inconnu',
    unknownDeviceType: 'Type d’appareil inconnu',
    unknownLocation: 'Lieu inconnu',
    yes: 'Oui c’est bien moi',
  },
} as const);

export default locales;
