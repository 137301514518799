import { useEffect } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';

import useQueryParams from 'common/hooks/useQueryParams';

/**
 * This hook keeps the local part of the URL synced with the string you pass.
 * The "local part" means the *trailing part* of the URL, compared to its current Route.
 *
 * The only difference with `useModuleLocation` is that we use `history.replace` instead of `history.push`.
 *
 * @todo migrate Beneficiary to this hook instead of `useModuleLocation`
 */
const useReplaceModuleLocation = (page: string) => {
  const queryString = useQueryParams().toString();

  const history = useHistory();

  const { params, path } = useRouteMatch<{ page: string }>();

  const targetPath = `${generatePath(path, { page: page.replace('.', '_') })}${
    queryString ? `?${queryString}` : ''
  }`;

  useEffect(() => {
    if (params.page === page) {
      return;
    }

    history.replace(targetPath);
  }, [history, page, params.page, targetPath]);
};

export default useReplaceModuleLocation;
