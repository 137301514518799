import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { browserPerformanceTimeOrigin } from '@sentry/utils';
import { debounce } from 'debounce';

import webView from 'common/webView';
import logger from 'helpers/logger/logger';

/**
 * All possible entry points of the web-app we want to measure the opening time of.
 */
export type DestinationPage =
  | 'login'
  | 'onboarding'
  | 'payments-home'
  | 'payment-cards-list'
  | 'invoices-list'
  | 'transaction-list';

/**
 * A class used to measure the opening time of our main entry points.
 */
class WebAppOpeningTransaction {
  private transaction: Sentry.Transaction | null = null;
  private spans: Map<string, Sentry.Span> = new Map();

  /**
   * A debounced callback that will mark the web-app as being idle if it's not cleared or re-run soon.
   */
  private debouncedMarkAsIdle = debounce(() => {
    if (this.transaction && this.spans.size === 0) {
      this.abort();
      logger.info('ℹ️ Web-app opening transaction has been discarded');
    }
  }, 500);

  private refreshActivityStatus() {
    // There are currently running spans : the web-app is not idle.
    if (this.spans.size > 0) {
      return this.debouncedMarkAsIdle.clear();
    }

    // A span just ended, and there are no more running spans : the web-app seems idle.
    return this.debouncedMarkAsIdle();
  }

  start() {
    // We can't track reliably that transaction for browsers that don't implement the `performance` API at all.
    if (!browserPerformanceTimeOrigin) {
      return;
    }

    // This Sentry value represents the time when navigation started if it's deemed "reliable".
    // If not reliable, it represents the Date-based timestamp when the Sentry SDK loaded.
    const openingTimestamp = browserPerformanceTimeOrigin / 1000;

    this.transaction = Sentry.startTransaction({
      attributes: {
        'sentry.sample_rate': 0.2,
      },
      description:
        'Transaction started when the web-app starts loading and fired when the destination screen is fully loaded',
      name: 'Opening the web-app',
      startTimestamp: openingTimestamp,
    });

    const initialBundleLoad = this.transaction.startChild({
      name: 'Entry chunk download, parsing and execution',
      op: 'bundle',
      startTimestamp: openingTimestamp,
    });

    initialBundleLoad.finish();
  }

  postLoginTransactionName = 'Loading the web-app post-login';

  startPostLogin() {
    if (this.transaction) {
      return;
    }

    this.transaction = Sentry.startTransaction({
      description:
        'Transaction started when the login process ended, and fired when the destination screen is fully loaded',
      name: this.postLoginTransactionName,
    });
  }

  /**
   * Important note: if the transaction lasted more than 120 seconds, it will be discarded as an outlier in `beforeSendTransaction`
   * @see apps/client/src/common/sentry/initSentry.ts
   */
  finish({ destination }: { destination: DestinationPage }) {
    if (!this.transaction) {
      return;
    }

    webView.postMessage({ action: 'WEBVIEW_LOADED' });

    this.transaction.setTag('destination', destination);
    this.transaction.finish();

    const duration =
      (this.transaction.endTimestamp as number) -
      this.transaction.startTimestamp;

    logger.info(`ℹ️ [${destination}] opened in ${duration.toFixed(2)}s`);

    this.transaction = null;
  }

  /**
   * There is no way to abort a Sentry transaction - the only way is to never call `finish()`.
   * We therefore "forget" the transaction by setting it to null.
   */
  abort() {
    this.transaction = null;
  }

  /**
   * A wrapper around `.startChild()` that tracks lifecycle of the spans.
   * Useful to be able to determine when the web-app is idle.
   */
  addSpan(options: AddSpanOptions) {
    if (!this.transaction || this.transaction.endTimestamp) {
      return null;
    }

    const span = this.transaction.startChild(options);

    // `span.name` might not be the best identifier, but 2 of our queries start in duplicate (but end only once) and I have no idea why.
    // Additionnally, we're not supposed to have two identical spans,
    // and this code will simply "forget" the first one in the context of the `idle` computation.
    this.spans.set(span.name, span);

    // Allows understanding which spans have begun in this transaction,
    // but may never have been "finished".
    Sentry.addBreadcrumb({
      category: 'span',
      level: 'info',
      message: `Span ${span.name} started`,
      type: 'debug',
    });

    this.refreshActivityStatus();

    return {
      _span: span as Omit<Sentry.Span, 'end' | 'finish'>,
      finish: () => {
        span.end();
        this.spans.delete(span.name);

        this.refreshActivityStatus();
      },
    };
  }
}

type AddSpanOptions = Parameters<
  Exclude<WebAppOpeningTransaction['transaction'], null>['startChild']
>[0] & {
  name: string;
};

export const webAppOpeningTransaction = new WebAppOpeningTransaction();

export const useTrackLoadingState = ({
  destination,
  isLoaded,
}: {
  isLoaded: boolean;
  destination: DestinationPage;
}) => {
  useEffect(() => {
    if (isLoaded) {
      webAppOpeningTransaction.finish({ destination });
    }
  }, [isLoaded, destination]);
};
