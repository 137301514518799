import styled from 'styled-components';

import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.abs24}px;
`;

export const DesktopIllus = styled.img`
  margin-top: ${({ theme }) => theme.spacings.abs32}px;
  margin-bottom: ${({ theme }) => theme.spacings.abs40}px;
`;

export const Title = styled(Heading)`
  margin-bottom: ${({ theme }) => theme.spacings.abs16}px;
  text-align: center;
`;

export const Subtitle = styled(Text)`
  text-align: center;
`;
