export enum ApplicationType {
  Standard = 'standard',
  CapitalDeposit = 'capital-deposit',
  ShineStart = 'shine-start',
  SasuCreation = 'sasu-creation',
}

// This is an idea following the introduction of mandatees
// feel free to use it or not, or add keys
export enum ApplicantType {
  AccountHolder = 'ACCOUNT_HOLDER',
  Mandatee = 'MANDATEE',
  InvitedBeneficiary = 'INVITED_BENEFICIARY',
  InvitedAccountHolder = 'INVITED_ACCOUNT_HOLDER',
}

// Errors codes related to the companies onboarding domain
export enum ErrorCodes {
  COMPANY_NOT_SUPPORTED = 'COMPANY_NOT_SUPPORTED',
  COMPANY_ALREADY_EXISTS = 'COMPANY_ALREADY_EXISTS',
}
