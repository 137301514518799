import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    company: 'My company',
    createNewAccount: 'Add a new company',
    featuresFlags: 'Feature flags',
    logout: 'Log Out',
    profile: 'My profile',
    recentActivities: 'Recent activities',
    referral: 'Referral',
    security: 'Security',
    subscription: 'My subscription',
    switchAccount: 'Switch Account',
  },
  fr: {
    company: 'Mon entreprise',
    createNewAccount: 'Ajouter une nouvelle entreprise',
    featuresFlags: 'Feature flags',
    logout: 'Se déconnecter',
    profile: 'Mon profil',
    recentActivities: 'Activités récentes',
    referral: 'Parrainage',
    security: 'Sécurité',
    subscription: 'Mon abonnement',
    switchAccount: 'Changer de compte',
  },
} as const);
