import { z } from 'zod';

import { Gender } from 'common/types';
import locales from 'features/Bento/libs/locales';

const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: locales.errors.customRequiredFields.email })
    .email({ message: locales.errors.email }),

  firstName: z.string().trim().min(1, {
    message: locales.errors.customRequiredFields.firstName,
  }),

  gender: z.nativeEnum(Gender, {
    invalid_type_error: locales.errors.customRequiredFields.gender,
  }),

  hasAgreedToTermsOfService: z.literal<boolean>(true, {
    errorMap: () => ({
      message: locales.errors.shouldAgreeWithTermsOfService,
    }),
  }),

  lastName: z.string().trim().min(1, {
    message: locales.errors.customRequiredFields.lastName,
  }),
});

export const formInputSchema = formSchema.merge(
  z.object({
    gender: z.nativeEnum(Gender).nullable(),
  }),
);

export const defaultValues: z.input<typeof formInputSchema> = {
  email: '',
  firstName: '',
  gender: null,
  hasAgreedToTermsOfService: false,
  lastName: '',
};

export type FormSchema = z.infer<typeof formSchema>;
export type FormInputSchema = z.input<typeof formInputSchema>;

export default formSchema;
