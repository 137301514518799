import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    next: `Restart the verification`,
    title: `We have identified a blocking element following the analysis of your identity`,
  },
  fr: {
    next: 'Recommencer la vérification',
    title:
      'Nous avons identifié un élément bloquant suite à l’analyse de votre identité ',
  },
} as const);

export default locales;
