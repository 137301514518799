import { type FC } from 'react';
import { Route, type RouteProps, Switch } from 'react-router-dom';

import { Invoices } from './pages/Invoices';
import { Overview } from './pages/Overview';
import { PlanDetails } from './pages/PlanDetails';
import { Plans } from './pages/Plans';
import * as routes from './routes';

export const WithinAppLayoutSubscriptionManagement: FC = () => {
  return (
    <Switch>
      <Route component={Overview} exact path={routes.root} />
      <Route component={Invoices} path={routes.invoices} />
    </Switch>
  );
};

export const fullPageSubscriptionManagementRoutes = [
  {
    component: Plans,
    exact: true,
    path: routes.plans,
  },
  {
    component: PlanDetails,
    exact: true,
    path: routes.planDetails,
  },
] satisfies Array<RouteProps>;
