import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    downloadApp: 'Download the app',
    forceAccess: 'Access it anyway',
    or: 'OR',
    subtitle: 'Access it from shine.fr by clicking "Login"',
    title: 'Shine on the web is optimised for larger screens',
  },

  fr: {
    downloadApp: 'Télécharger Shine mobile',
    forceAccess: 'Accéder quand même',
    or: 'OU',
    subtitle:
      'Vous pouvez y accéder depuis shine.fr en cliquant sur "Se connecter"',
    title: 'La version web de Shine est optimisée pour les écrans plus grands',
  },
} as const);

export default locales;
