import { useEffect } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';

/**
 * This hook keeps the local part of the URL synced with the string you pass.
 *
 * The "local part" means the *trailing part* of the URL, compared to its current Route.
 *
 */
const useModuleLocation = (page: string) => {
  const history = useHistory();

  const { params, path } = useRouteMatch<{ page: string }>();

  useEffect(() => {
    if (params.page === page) {
      return;
    }

    history.replace({
      pathname: generatePath(path, { page }),
      search: location.search,
    });
  }, [history, page, params.page, path]);
};

export default useModuleLocation;
