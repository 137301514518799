import { ApolloLink } from '@apollo/client';
import { asyncMap } from '@apollo/client/utilities';
import * as Sentry from '@sentry/react';

import { getOperationType } from './utils';
import { webAppOpeningTransaction } from './webAppOpeningTransaction';

/**
 * ApolloLink that creates a breadcrumb for each GraphQL call.
 */
const apolloSentryBreadcrumbLink = new ApolloLink((operation, forward) => {
  const { operationName } = operation;
  const operationType = getOperationType(operation);

  const span =
    operationType === 'query' || operationType === 'mutation'
      ? webAppOpeningTransaction.addSpan({
          name: `${operationType} ${operationName}`,
          op: 'gql',
          tags: { operationType },
          /**
           * Helps understand why queries are trigger multiple times (their args change).
           * Query variables don't contain sensitive information (contrary to some mutations).
           */
          ...(operationType === 'query' && {
            variables: operation.variables,
          }),
        })
      : null;

  // asyncMap is working correctly while we had issues with forward(operation).map
  return asyncMap(forward(operation), (response) => {
    /**
     * This comes from Apollo Server tracing extension
     */
    const serverDurationMs = response.extensions?.tracing?.duration
      ? (response.extensions?.tracing?.duration as number) / 1_000_000
      : null;

    const rtt = navigator.connection?.rtt;

    const hasError = (response.errors && response.errors.length > 0) || false;

    Sentry.addBreadcrumb({
      category: 'graphql',
      data: {
        errors: response.errors,
        operationName,
      },
      level: hasError ? 'error' : 'info',
      message: `${operationType} ${operationName}`,
      type: 'query',
    });

    if (hasError) {
      span?._span?.setStatus('error');
    }

    if (serverDurationMs) {
      span?._span.setData('Server Duration (Millisecond)', serverDurationMs);
    }

    //
    if (rtt) {
      span?._span.setData('Round-trip Time (Millisecond)', rtt);
    }

    span?.finish();

    return response;
  });
});

export default apolloSentryBreadcrumbLink;
