import { Center } from '@chakra-ui/react';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import Loader from 'components/Loader';

const LoaderPage = asBentoPage(
  ({ Layout }) => (
    <Layout
      aside={null}
      data-testid="loader"
      footer={null}
      header={null}
      nav={null}
    >
      <Center paddingTop="space-128">
        <Loader />
      </Center>
    </Layout>
  ),
  { isLoadingState: true },
);

export default LoaderPage;
