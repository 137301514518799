import * as Types from '../../../../../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AcceptAuthenticationDeviceRequestMutationVariables = Types.Exact<{
  input: Types.AcceptAuthenticationDeviceRequestInput;
}>;


export type AcceptAuthenticationDeviceRequestMutation = { __typename: 'Mutation', acceptAuthenticationDeviceRequest: { __typename: 'AcceptAuthenticationDeviceRequestPayload', authenticationDeviceRequest: { __typename: 'AuthenticationDeviceRequest', phone: string, authenticationDeviceId: string, authenticationDeviceRequestId: string, status: Types.AuthenticationDeviceRequestStatus } } };


export const AcceptAuthenticationDeviceRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"acceptAuthenticationDeviceRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptAuthenticationDeviceRequestInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptAuthenticationDeviceRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authenticationDeviceRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"authenticationDeviceId"}},{"kind":"Field","name":{"kind":"Name","value":"authenticationDeviceRequestId"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode<AcceptAuthenticationDeviceRequestMutation, AcceptAuthenticationDeviceRequestMutationVariables>;