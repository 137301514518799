import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    cta: 'Start',
    heading: `Welcome {adminName}!`,
    list: [
      'Complete your personal information',
      'Add proof of identity (ID card, passport, residence permit)',
    ],
    standardDescription: `In order to access the pro account of company **{companyName}** we need you to submit the following information.`,
  },

  fr: {
    cta: 'Commencer',
    heading: `Bienvenue {adminName} !`,
    list: [
      'Compléter vos informations personnelles',
      'Ajouter un justificatif d’identité (CNI, passeport, titre de séjour)',
    ],
    standardDescription:
      'Afin d’accéder au compte pro de **{companyName}** en tant qu’admin, nous avons besoin que vous complétiez les informations suivantes.',
  },
} as const);

export default locales;
