import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    sogeInformation: 'Shine is an independent subsidiary of the SG Group',
    steps: {
      identity: 'Prepare an identity document',
      questions: 'Answer a few questions',
      verification: 'Verification in 48 hours',
    },
    title: 'steps to complete your information',
  },
  fr: {
    sogeInformation: 'Shine est une filliale indépendante du Groupe SG',
    steps: {
      identity: "Préparer une pièce d'identité",
      questions: 'Répondre à quelques questions',
      verification: 'Vérification en 48h',
    },
    title: 'étapes pour compléter vos informations',
  },
} as const);
