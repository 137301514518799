import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    next: 'Continue my registration',
    subtitle: 'We will now proceed to verify your identity',
    title: 'Your identity documents have been sent!',
  },
  fr: {
    next: 'Continuer mon inscription',
    subtitle: 'Nous allons procéder à la vérification de vos documents',
    title: 'Vos documents d’identités sont envoyés !',
  },
} as const);
