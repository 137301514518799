export const PLAYWRIGHT_LOCAL_STORAGE_KEY = 'playwright_runner' as const;

/**
 * Tests whether we are in the context of a playwright test.
 *
 * This should be used when creating experiments as we want our
 * e2e tests to be reliable and we are unlikely to create such tests
 * for experiments for the time being.
 *
 * @returns whether we are in the context of a playwright test
 */
const isPlaywrightTest = () =>
  Boolean(window.localStorage.getItem(PLAYWRIGHT_LOCAL_STORAGE_KEY));

export default isPlaywrightTest;
