export enum UserMenuItem {
  Profile = 'PROFILE',
  BankInfo = 'BANK_INFO',
  Company = 'COMPANY',
  CreateNewAccount = 'CREATE_NEW_ACCOUNT',
  RecentActivities = 'RECENT_ACTIVITIES',
  Security = 'SECURITY',
  Subscription = 'SUBSCRIPTION',
}

export const ALL_USER_MENU_ITEMS: Set<UserMenuItem> = new Set([
  UserMenuItem.Profile,
  UserMenuItem.BankInfo,
  UserMenuItem.Company,
  UserMenuItem.CreateNewAccount,
  UserMenuItem.RecentActivities,
  UserMenuItem.Security,
  UserMenuItem.Subscription,
]);
