import { type MouseEventHandler } from 'react';
import { Box, type BoxProps, forwardRef } from '@chakra-ui/react';
import { type IconName } from '@shinetools/sunshine-icons';
import { Icon } from '@shinetools/sunshine-universal';

import { useQuota } from '../context';

export type BodyProps = BoxProps;

export const Body = forwardRef<BodyProps, 'div'>((props, ref) => {
  const { __css: css, ...rest } = props;
  const { isExpandable, isOpen, onToggle, theme } = useQuota();

  const icon: IconName = isOpen ? 'chevron-up' : 'chevron-down';

  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isExpandable) {
      onToggle();
    }

    props.onClick?.(e);
  };

  return (
    <Box
      __css={{ ...theme.body, ...css }}
      className="quota__body"
      data-expandable={isExpandable || undefined}
      onClick={onClick}
      {...rest}
      ref={ref}
    >
      <Box __css={{ ...theme.body_content }} className="quota__body__content">
        {props.children}
      </Box>

      {isExpandable ? (
        <Box __css={{ ...theme.body_trigger }} className="quota__body__trigger">
          <Icon icon={icon} />
        </Box>
      ) : null}
    </Box>
  );
});
