import * as Types from '../../../../../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type FinishTeamIdentityVerificationMutationVariables = Types.Exact<{
  input: Types.FinishIdentityVerificationInput;
}>;


export type FinishTeamIdentityVerificationMutation = { __typename: 'Mutation', finishIdentityVerification: { __typename: 'FinishIdentityVerificationPayload', kycReviewDocuments: Array<{ __typename: 'KycReviewDocument', kycReviewDocumentId: string, type: Types.KycReviewDocumentType, url: string | null, status: Types.KycReviewDocumentStatus, errorLocalized: string | null }> } };


export const FinishTeamIdentityVerificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"finishTeamIdentityVerification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FinishIdentityVerificationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"finishIdentityVerification"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"kycReviewDocuments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"kycReviewDocumentId"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"errorLocalized"}}]}}]}}]}}]} as unknown as DocumentNode<FinishTeamIdentityVerificationMutation, FinishTeamIdentityVerificationMutationVariables>;