import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    closeButton: 'Close',
    description:
      'Please return to the merchant site to complete your purchase.',
    descriptionAlreadyAccepted:
      'You have performed this action from the device below:',
    finishButton: 'Finish',
    title: 'Payment authorized',
    titleAlreadyAccepted: 'Payment already authorized',
  },
  fr: {
    closeButton: 'Fermer',
    description:
      'Pour finaliser votre achat, veuillez retourner sur le site marchand.',
    descriptionAlreadyAccepted:
      'Vous avez effectué l‘action depuis l‘appareil ci-dessous\u00a0:',
    finishButton: 'Terminer',
    title: 'Paiement autorisé',
    titleAlreadyAccepted: 'Paiement déjà autorisée',
  },
} as const);

export default locales;
