import {
  addMonths,
  type Duration,
  intervalToDuration,
  isAfter,
} from 'date-fns';

import type * as GQL from '../overview.gql';

type TrialPeriodLoading = {
  isOngoing: false;
  duration: null;
  isLoading: true;
};

type TrialPeriodOngoing = {
  isOngoing: true;
  isLoading: false;
  duration: Duration;
};

type TrialPeriodEnded = {
  isOngoing: false;
  isLoading: false;
  duration: null;
};

export const getTrialPeriod = (
  data?: GQL.SubscriptionManagementOverviewQuery,
): TrialPeriodOngoing | TrialPeriodEnded | TrialPeriodLoading => {
  const subscription = data?.viewer.company.subscriptionPlan;
  const trialPeriodLength = subscription?.trialPeriodLength;

  if (!data) {
    return {
      duration: null,
      isLoading: true,
      isOngoing: false,
    };
  }

  if (!trialPeriodLength) {
    return {
      duration: null,
      isLoading: false,
      isOngoing: false,
    };
  }

  const startDate = new Date(subscription.startedAt);
  const endDate = addMonths(startDate, trialPeriodLength);

  if (isAfter(new Date(), endDate)) {
    return {
      duration: null,
      isLoading: false,
      isOngoing: false,
    };
  }

  const duration = intervalToDuration({
    end: endDate,
    start: new Date(),
  });

  return {
    duration,
    isLoading: false,
    isOngoing: true,
  };
};
