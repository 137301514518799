/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/**
 * Throws if any of the provided arguments are non-undefined.
 * For example: `throwIfErrorPresent(argOne, argTwo, argThree)` will
 * call `throw <arg>` if any of `argOne`, `argTwo` or `argThree` are not
 * `undefined` where `<arg>` is the content of the first argument that
 * has a value. This value does not have to be an Error but it always better
 * if it is.
 */
export const throwIfErrorPresent = (...args: unknown[]) => {
  args.forEach((arg) => {
    if (arg !== undefined) {
      throw arg;
    }
  });
};

// The 'any' type below is needed as T may be a primitive or non-primitive value.
// The existence of an extends at all is needed to be JSX friendly
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const acceptOnlyNonNil = <T>(value?: T | null): T => {
  if (value === undefined || value === null) {
    throw new Error('Expecting non-nil value');
  }
  return value;
};
