import { forwardRef } from '@chakra-ui/react';

import * as Form from 'components/_core/form';

interface CustomDateInputProps extends Form.InputProps {
  placeholder?: string;
  value?: string;
}

// Legacy date picker removes borders from all children inputs
// Defining it as a `button` is a workaround to patch the problem, but prevents using other input attributes such as placeholder
const CustomDateInput = forwardRef<CustomDateInputProps, 'input'>(
  ({ placeholder, value, ...props }, ref) => (
    <Form.Input
      alignItems="center"
      as="button"
      {...(!value && { color: 'grey.0', fontWeight: 'weight-400' })}
      display="flex"
      ref={ref}
      type="button"
      {...props}
    >
      {value || placeholder}
    </Form.Input>
  ),
);

export default CustomDateInput;
