import { type FC } from 'react';
import {
  Skeleton as ChakraSkeleton,
  type SkeletonProps,
} from '@chakra-ui/react';

const Skeleton: FC<SkeletonProps> = (props) => (
  <ChakraSkeleton
    borderRadius="radius-4"
    endColor="grey.200"
    startColor="grey.200"
    {...props}
  />
);

export default Skeleton;
