import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    addStreetAddition: 'Add address line',
    cityLabel: 'City',
    clear: 'Clear',
    continueCta: 'Continue',

    inputs: {
      city: {
        label: 'City',
      },
      street: {
        errors: {
          limitedLength: 'The street cannot have more than {0} characters',
        },
        label: 'Address',
        placeholder: 'Search address...',
      },
      streetAddition: {
        label: 'Address second line',
        placeholder: 'Building, stair, floor...',
      },
      zip: {
        errors: {
          invalid: 'The zip code should contain 5 digits',
        },
        label: 'Zip',
      },
    },
  },
  fr: {
    addStreetAddition: 'Ajouter un complément d’adresse',
    cityLabel: 'Ville',
    clear: 'Effacer',
    continueCta: 'Continuer',

    inputs: {
      city: {
        label: 'Ville',
      },
      street: {
        errors: {
          limitedLength: 'La rue ne peut pas dépasser {0} caractères',
        },
        label: 'Adresse',
        placeholder: 'Rechercher une adresse...',
      },
      streetAddition: {
        label: 'Complément d’adresse',
        placeholder: 'Batiment, escalier, étage...',
      },
      zip: {
        errors: {
          invalid: 'Le code postal doit comporter 5 chiffres',
        },
        label: 'Code postal',
      },
    },
  },
} as const);
