import { z } from 'zod';

import localStorageObjectSchema from 'common/zod/localStorageObjectSchema';
import logger from 'helpers/logger';

const companyContextSchema = z.object({
  companyProfileId: z.string().optional(),
  companyUserId: z.string().optional(),
});

export type CompanyContextState = z.infer<typeof companyContextSchema>;

class CompanyContextStorage {
  private readonly schema = localStorageObjectSchema.pipe(companyContextSchema);
  public readonly storageKey = 'companyContext';

  public retrieve() {
    const stringified = window.localStorage.getItem(this.storageKey);

    if (!stringified) {
      return null;
    }

    const parsed = this.schema.safeParse(stringified);

    if (parsed.success) {
      return parsed.data;
    }

    logger.error(
      `Invalid company context in localStorage: ${parsed.error.message}`,
    );
    this.clear();
    return null;
  }

  public persist(companyContext: CompanyContextState) {
    window.localStorage.setItem(
      this.storageKey,
      JSON.stringify(companyContext),
    );
  }

  public clear() {
    window.localStorage.removeItem(this.storageKey);
  }
}

export const companyContextStorage = new CompanyContextStorage();
