import LocalizedStrings from 'localized-strings';

export const locales = new LocalizedStrings({
  en: {
    bookingLinkText: 'Make an appointment',
  },
  fr: {
    bookingLinkText: 'Prendre rendez-vous',
  },
} as const);
