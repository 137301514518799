import styled from 'styled-components';

import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';

export const Title = styled(Heading).attrs({ size: 'lg' })`
  margin-bottom: ${({ theme }) => theme.spacings.abs16}px;
`;

export const Description = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacings.abs8}px;
  white-space: pre-wrap;
`;
