import {
  getAsYouType,
  getRegionCodeForCountryCode as awesomeGetRegionCodeForCountryCode,
} from 'awesome-phonenumber';

export const DEFAULT_COUNTRY_CODE = 'FR';
const LEADING_ZERO_REGEX = /^0+/;

export const getRegionCodeForCountryCode = (countryCode: string) =>
  awesomeGetRegionCodeForCountryCode(parseInt(countryCode, 10));

interface getFormattedPhoneNumberParams {
  countryCode: string;
  phoneNumber?: string;
}
/** Gets a phone number formatted accordingly to the national conventions of the country with the provided country code.
 *
 * @param {string} countryCode The country code of the phone number **without** leading `+` character
 * @param {string} phoneNumber The phone number to be formatted, can be a naked phone number or include the country code. In this case, it will be used over `countryCode`
 * @example
 * ```
 * getFormattedPhoneNumber({ countryCode: '33', phoneNumber: '612345678' });
 * // => {formattedCountryCode: '33', formattedPhoneNumber: '6 12 34 56 78'}
 *
 * getFormattedPhoneNumber({ countryCode: '33', phoneNumber: '+3224123456' });
 * // => {formattedCountryCode: '32', formattedPhoneNumber: '2 412 34 56'}
 * ```
 */
export const getFormattedPhoneNumber = ({
  countryCode,
  phoneNumber = '',
}: getFormattedPhoneNumberParams) => {
  const formatter = getAsYouType(getRegionCodeForCountryCode(countryCode));
  formatter.reset(phoneNumber);

  // if the phone number contains a country code, use it over the one from the dedicated input (particularly useful when the phone number is copy-pasted)
  const formattedCountryCode =
    formatter.getPhoneNumber().countryCode?.toString() || countryCode;
  const countryCodeMatchingRegex = new RegExp(`\\+${formattedCountryCode}\\s*`);

  const significantPhoneNumber = phoneNumber
    .replace(countryCodeMatchingRegex, '')
    .replace(/(?!\+)\D(?!$)/g, ''); // remove all non-trailing non-numeric characters except for the leading `+` character

  // if the significant phone number has a leading zero, it has to be remove for the formatter to work properly
  const phoneNumberWithoutLeadingZero = significantPhoneNumber.replace(
    LEADING_ZERO_REGEX,
    '',
  );

  const sanitizedPhoneNumber =
    `+${formattedCountryCode}${phoneNumberWithoutLeadingZero}`.replace(
      /\s/g,
      '',
    );

  const formattedSignificantPhoneNumber = formatter
    .reset(sanitizedPhoneNumber)
    .replace(countryCodeMatchingRegex, '')
    .trim();

  // Reinsert the leading zero if it existed
  const hasLeadingZero = LEADING_ZERO_REGEX.test(significantPhoneNumber);
  const formattedPhone = `${
    hasLeadingZero ? '0' : ''
  }${formattedSignificantPhoneNumber}`;

  return { formattedCountryCode, formattedPhone };
};
