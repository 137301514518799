/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { useRef } from 'react';

/**
 * This hook, given an object, will return a *stable* `MutableRefObject`
 * pointing to the object, and will update its reference on every render.
 */
const useUpdatedRef = <TData>(data: TData) => {
  const ref = useRef(data);

  /**
   * We don't use a useEffect because we need the effect to be triggered immediately on every render.
   * `useEffect` will queue the callback when `data` changes.
   * What we need is to update the ref *immediately* when `data` changes.
   */
  ref.current = data;

  return ref;
};

export default useUpdatedRef;
