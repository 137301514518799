import { useEffect, useRef } from 'react';

export interface CancellablePromise<T> {
  promise: Promise<T>;
  cancel: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeCancelable = <T>(
  promise: Promise<T>,
): CancellablePromise<T> => {
  let isCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject): void => {
    promise
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((val: any): void => {
        if (!isCanceled) {
          resolve(val);
        }
      })
      .catch((error: Error): void => {
        if (!isCanceled) {
          reject(error);
        }
      });
  });

  return {
    cancel: (): void => {
      isCanceled = true;
    },
    promise: wrappedPromise,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCancellablePromise = <T>(
  promise: Promise<T>,
): CancellablePromise<T> => {
  // wrap in useRef so it won't change on re-render
  const cancellablePromise = useRef(makeCancelable(promise));

  useEffect(
    () => (): void => {
      // auto cancel promise when unmounting
      cancellablePromise.current.cancel();
    },
    [],
  );

  return cancellablePromise.current;
};

export default useCancellablePromise;
