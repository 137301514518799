import { createContext, useContext } from 'react';
import { useModalContext as useChakraModalContext } from '@chakra-ui/react';

interface ModalContextType {
  isClosable: boolean;
}

const ModalContext = createContext<ModalContextType | null>(null);

export const ModalContextProvider = ModalContext.Provider;

export function useModalContext() {
  const context = useContext(ModalContext);
  const modalContext = useChakraModalContext();

  if (!context) {
    throw new Error(
      'useModalContext can only be used inside of a ModalContextProvider',
    );
  }

  return { ...context, ...modalContext };
}
