import { frenchDepartmentCodes } from '@shinetools/geo-library';
import { differenceInYears, isValid as isValidDate, parseISO } from 'date-fns';
import { z } from 'zod';

import { Gender } from 'common/types';
import { FRANCE_COUNTRY_CODE } from 'features/Bento/libs/constants';
import locales from 'features/Bento/libs/locales';
import identityLocales from 'features/TeamOnboarding/Onboarding/modules/AdminIdentity/pages/PersonalInformation/locales';

const identitySchema = z
  .object({
    birthCity: z
      .string()
      .trim()
      .min(1, { message: locales.errors.customRequiredFields.birthCity }),
    birthCountry: z.string().min(1, {
      message: locales.errors.customRequiredFields.birthCountry,
    }),
    birthdate: z
      .string()
      .min(1, { message: locales.errors.customRequiredFields.birthdate })
      .refine((value) => isValidDate(parseISO(value ?? '')), {
        message: locales.errors.dateIsInvalid,
      })
      .refine(
        (value) =>
          value ? differenceInYears(new Date(), new Date(value)) >= 18 : true,
        {
          message: locales.errors.shouldBeAdult,
        },
      ),
    birthDepartment: z.string(),
    firstName: z
      .string()
      .trim()
      .min(1, { message: locales.errors.customRequiredFields.firstName }),
    gender: z
      .nativeEnum(Gender, {
        required_error: locales.errors.customRequiredFields.gender,
      })
      .nullable(),
    lastName: z
      .string()
      .trim()
      .min(1, { message: locales.errors.customRequiredFields.lastName }),
    nationality: z
      .string()
      .min(1, { message: locales.errors.customRequiredFields.nationality }),
  })
  .superRefine((data, ctx) => {
    if (
      data.birthCountry === FRANCE_COUNTRY_CODE &&
      frenchDepartmentCodes.every((code) => code !== data.birthDepartment)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: identityLocales.form.errors.missingBirthDepartment,
        path: ['birthDepartment'],
      });
    }
  });

export type IdentitySchema = z.infer<typeof identitySchema>;

export default identitySchema;
