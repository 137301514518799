/* eslint-disable sort-keys-fix/sort-keys-fix */

const typography = {
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },

  lineHeights: {
    normal: 'normal',
    none: 1,
    shortest: 1.15,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
  },

  /**
   * Note that we force 500 for all bold fonts.
   */
  fontWeights: {
    'weight-100': 100,
    'weight-200': 200,
    'weight-300': 300,
    'weight-400': 400,
    'weight-500': 500,
    'weight-600': 600,
    'weight-700': 700,
    'weight-800': 800,
  },

  fonts: {
    body: `"Mabry Pro", serif`,
    heading: `"Value Serif Pro", serif`,
    mabry: `"Mabry Pro", serif`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
    serif: `"HeldaneText-Bold", "Times New Roman", Georgia, serif`,
    value: `"Value Serif Pro", serif`,
  },

  fontSizes: {
    'font-10': '10px',
    'font-12': '12px',
    'font-13': '13px',
    'font-15': '15px',
    'font-16': '16px',
    'font-18': '18px',
    'font-20': '20px',
    'font-21': '21px',
    'font-22': '22px',
    'font-25': '25px',
    'font-26': '26px',
    'font-28': '28px',
    'font-31': '31px',
    'font-37': '37px',
    'font-38': '38px',
    'font-42': '42px',
    'font-45': '45px',
  },
};

export default typography;
