import { SdkEnvironment } from '__generated__/GQL';

import remoteConfigDefaults from './sharedRemoteConfigDefaults';

const graphqlHost = 'api.staging.shine.fr';

const ONE_HOUR_MS = 60 * 60 * 1000;

const config = {
  assurupWidgetUrl: 'http://pprod.app.assurup.com/quote?partnerId=49',
  authenticatedSchemaSubscriptionsUri: `wss://${graphqlHost}/v2/subscriptions`,
  cannyAppId: '5c77bd1a6d8cd3449b277c90',
  didomiKey: '5b58c4e5-abc4-40d7-bf7d-fb1387527333',
  downloadHook: 'https://hooks.zapier.com/hooks/catch/2912704/oygtlfb/',
  firebase: {
    apiKey: 'AIzaSyAODFuB4Cs9vN9rnr0AEY7mg_3vFYcSsXQ',
    appId: '1:941350679490:web:ac7fa45a2b792513',
    authDomain: 'shine-api-staging.firebaseapp.com',
    databaseURL: 'https://shine-api-staging.firebaseio.com',
    messagingSenderId: '941350679490',
    projectId: 'shine-api-staging',
    storageBucket: 'shine-api-staging.appspot.com',
  },
  fourthlineCdnHost: 'https://sandbox.v.fourthline.com',
  fourthlineHostedFlowHost: 'https://shine.app.sandbox.fourthline.com/v1',
  gcloudApiKey: 'AIzaSyClXHXFzVnz-_tK7cUbYGIlnvE4EzEv1bk',
  graphqlPublicUri: `https://${graphqlHost}/v2/graphql-public`,
  graphqlUri: `https://${graphqlHost}/v2/graphql`,
  identityCheckSdkEnvironment: SdkEnvironment.Staging,
  intercomAppId: 'b9pxu4kt',
  merchant: {
    /**
     * This key is linked to the "Shine Payment Platform" Stripe
     * account that we use for "merchant" features.
     */
    stripePublishableKey:
      'pk_test_51PaHhmAGDa9k4cj1mC4exVjJO4S10B0clE8e0ZPMn4gDcuPk4HviBPvg5q05fUe7i6fhA9LsI4PoBM4FjkrO32uR004dZdYKjS',
  },
  publicSchemaSubscriptionsUri: `wss://${graphqlHost}/v2/subscriptions-public`,
  publicWebsiteUrl: 'https://shine-staging.netlify.app',
  remoteConfigDefaults,
  remoteConfigLogLevel: 'error',
  remoteConfigMinFetchIntervalMs: 12 * ONE_HOUR_MS,
  segmentKey: 'v3xZg12zJgcnxplx60yfqt7aPO2LOgyo',
  sentryDsn:
    'https://fd4649e3b86e4ec99a554468765bb721@o1089553.ingest.sentry.io/6105000',
  shineApiHost: 'https://api.staging.shine.fr/v2',

  shineAppHost: 'https://app.staging.shine.fr',

  shineStartTypeformId: 'eMeb60dp',
  // Signup zap URL: https://zapier.com/app/editor/73471437
  signupZapierHook: 'https://hooks.zapier.com/hooks/catch/2912704/o6el7hc/',
  /**
   * This key is linked to the "Shine" Stripe account
   * that we use to make users pay for their onboarding.
   */
  stripeKey: 'pk_test_X6bclSfXrruBfTpR6VV7vwfO',
};

export default config;
