import * as Types from '../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateTeamMemberReviewMutationVariables = Types.Exact<{
  input: Types.CreateTeamMemberReviewInput;
}>;


export type CreateTeamMemberReviewMutation = { __typename: 'Mutation', createTeamMemberReview: { __typename: 'createTeamMemberReviewPayload', companyUser: { __typename: 'CompanyUser', companyUserId: string, hasFinishedApplicationSetup: boolean, isAwaitingAccountValidation: boolean, reviewStatus: Types.CompanyUserReviewStatus, kycStatus: Types.CompanyUserKycStatus } } };


export const CreateTeamMemberReviewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createTeamMemberReview"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"createTeamMemberReviewInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createTeamMemberReview"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyUserId"}},{"kind":"Field","name":{"kind":"Name","value":"hasFinishedApplicationSetup"}},{"kind":"Field","name":{"kind":"Name","value":"isAwaitingAccountValidation"}},{"kind":"Field","name":{"kind":"Name","value":"reviewStatus"}},{"kind":"Field","name":{"kind":"Name","value":"kycStatus"}}]}}]}}]}}]} as unknown as DocumentNode<CreateTeamMemberReviewMutation, CreateTeamMemberReviewMutationVariables>;