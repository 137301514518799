import { createContext, useContext } from 'react';
import { type UseRadioGroupReturn } from '@chakra-ui/react';

import { type RadioPositions } from '..';

interface RadioGroupContext extends UseRadioGroupReturn {
  radioPosition?: RadioPositions;
  isInvalid?: boolean;
}

const Context = createContext<RadioGroupContext | null>(null);

export const RadioGroupProvider = Context.Provider;

export function useRadioGroup() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useRadioGroup can only be used inside of a RadioGroupProvider',
    );
  }

  return context;
}

/**
 * To be used when you are not about the presence of a RadioGroupProvider higher in the tree.
 * The implementation of SunshineCard forces us to get the radiogroup context value for every variant, not just when we are inside a RadioGroup.
 */
export function unsafe__useNullableRadioGroup() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useContext(Context);
}
