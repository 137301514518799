import doublePremiumRebrandedImage from './assets/rebranded/doublePremiumCard.png';
import singlePremiumRebrandedImage from './assets/rebranded/singlePremiumCard.png';
import triplePremiumRebrandedImage from './assets/rebranded/triplePremiumCard.png';
import singleBasicImage from './assets/singleBasicCard.png';

interface CardImageOptions {
  hasAccessToBusinessCard: boolean;
  nbOfPhysicalCards: number;
}

export const getCardImage = ({
  hasAccessToBusinessCard,
  nbOfPhysicalCards,
}: CardImageOptions) => {
  if (hasAccessToBusinessCard) {
    if (nbOfPhysicalCards <= 1) {
      return singlePremiumRebrandedImage;
    }
    if (nbOfPhysicalCards === 2) {
      return doublePremiumRebrandedImage;
    }

    return triplePremiumRebrandedImage;
  }

  return singleBasicImage;
};
