import * as Sentry from '@sentry/react';

import { OBError } from 'features/Bento/libs/errors/OBError';

enum LogLevel {
  log = 'log',
  info = 'info',
  warn = 'warn',
  error = 'error',
}

const getConsoleLogger = (level: LogLevel) => {
  if (import.meta.env.VITE_API_ENV !== 'production') {
    // eslint-disable-next-line no-console
    return console[level];
  }
  // When we're in the full production environment, do not log anything
  // to the console via the logger.
  return () => {};
};

export type errorTag = number | string | boolean | null | undefined;

/**
 * Logs an error to Sentry.
 */
const errorLogger = (
  error: unknown,
  metadata: {
    /**
     * Tags are indexed, searchable additional medatada in Sentry.
     */
    tags?: {
      [key: string]: errorTag;
    };
    /**
     * Context is additional metadata for this issue in Sentry.
     * Contrary to tags, it's not limited to primitives.
     */
    context?: { [key: string]: unknown };
  } = {},
) => {
  // Log this to the console only in non-production.
  if (import.meta.env.VITE_API_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.error(error, metadata);
  }

  Sentry.withScope((scope) => {
    if (metadata.tags) {
      scope.setTags(metadata.tags);
    }

    if (metadata.context) {
      scope.setContext('Custom context', metadata.context);
    }

    if (error instanceof OBError) {
      if (error.shouldReport) {
        Sentry.captureException(error);
      }
    } else if (error instanceof Error) {
      Sentry.captureException(error);
    } else if (typeof error === 'string') {
      Sentry.captureException(new Error(error));
    } else {
      // Fallback if the logger is not used correctly
      Sentry.captureMessage(JSON.stringify(error, undefined, 0));
    }
  });
};

const logger = {
  error: errorLogger,
  info: getConsoleLogger(LogLevel.info),
  log: getConsoleLogger(LogLevel.log),
  warn: getConsoleLogger(LogLevel.warn),
};

export default logger;
