import breakpoints from './breakpoints';
import colors from './colors';
import radii from './radius';
import shadows from './shadows';
import sizes from './sizes';
import space from './space';
import transition from './transition';
import typography from './typography';
import zIndices from './zIndex';

const foundations = {
  breakpoints,
  colors,
  radii,
  zIndices,
  ...typography,
  borders: {},
  direction: {},
  shadows,
  sizes,
  space,
  transition,
};

export default foundations;
