/**
 * Add your feature names here
 *
 * @example
 * const FEATURES = [
 *   'ACCOUNTING',
 *   'INVOICING',
 * ];
 */
const FEATURES = [
  /**
   * Should display or not a modal that warns the user when
   * editing the client of an invoice that has reminders
   */
  'INVOICE_EDIT_CLIENT',
  /**
   * Should display or not a callout in the invoicing form that
   * explains to the user how to update his presets
   */
  'INVOICE_PRESETS',
  'REFERRALS',
  'DASHBOARD',
  'BANK_ACCOUNTS',
  'URSSAF_ESTIMATION',
  'INTERNATIONAL_TRANSFER',
] as const;

export default FEATURES;
