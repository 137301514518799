import { useState } from 'react';
import { convert as titleCase } from 'titlecase-french';

import useCompanyContext from 'common/hooks/useCompanyContext';
import { useUserCompanyList } from 'common/hooks/useUserCompanyList';
import Icon from 'components/_core/Icon';
import Loader from 'components/Loader';

import locales from './locales';
import {
  Account,
  Container,
  Dropdown,
  DropdownHeader,
  DropdownTitle,
  Layout,
} from './styles';

const AccountSwitcher = () => {
  const { companies, loading } = useUserCompanyList();
  const { companyProfileId, setCompanyContext } = useCompanyContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  if (loading) {
    return <Loader />;
  }
  const validatedCompanies = companies.filter(
    (company) => company.metadata.accountStatus === 'VALIDATED',
  );
  const currentCompany = validatedCompanies.find(
    (c) => c.companyProfileId === companyProfileId,
  );
  if (!currentCompany) {
    return <Loader />;
  }
  return (
    <Layout withDropdown={validatedCompanies.length > 1}>
      <Container>
        <Account
          avatarUrl={currentCompany.logoSrc}
          logoSize={48}
          subtitle={currentCompany.legalForm}
          title={titleCase(currentCompany.legalName)}
        />
        {validatedCompanies.length > 1 ? (
          <Dropdown>
            <DropdownHeader
              justifyContent="space-between"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              withBorder={isDropdownOpen}
            >
              <DropdownTitle>{locales.title}</DropdownTitle>
              <Icon
                color="grey.-2"
                name={isDropdownOpen ? 'chevron-up' : 'chevron-down'}
              />
            </DropdownHeader>
            {isDropdownOpen
              ? validatedCompanies
                  .filter((c) => c.companyProfileId !== companyProfileId)
                  .map((company) => (
                    <Account
                      avatarUrl={company.logoSrc}
                      key={company.companyProfileId}
                      logoSize={48}
                      onClick={() => {
                        setCompanyContext({
                          companyProfileId: company.companyProfileId,
                          companyUserId: company.companyUserId,
                        });
                        setIsDropdownOpen(false);
                      }}
                      subtitle={company.legalForm}
                      title={titleCase(company.legalName)}
                    />
                  ))
              : null}
          </Dropdown>
        ) : null}
      </Container>
    </Layout>
  );
};

export default AccountSwitcher;
