import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(['root', 'item', 'container']);

const baseStyle = definePartsStyle(({ viewMode }) => {
  const itemsColumns = viewMode === 'quarter' ? 2 : 4;

  return {
    container: {
      display: 'grid',
      gap: 'space-8',
      gridAutoRows: 'max-content',
      gridTemplateColumns: `repeat(${itemsColumns},minmax(0,1fr))`,
    },
    item: {
      _disabled: {
        _hover: {
          cursor: 'not-allowed',
        },
        color: 'grey.500',
      },
      _focusVisible: {
        boxShadow: 'outline-rebranded',
        outline: 0,
      },
      _hover: {
        _disabled: {
          background: 'none',
        },
        background: 'grey.200',
      },
      _selected: {
        _hover: {
          background: 'grey.800',
        },
        background: 'grey.800',
        color: 'white',
      },
      borderRadius: 'radius-8',
      color: 'text-primary',
      fontFamily: 'body',
      fontSize: 'font-13',
      paddingX: 'space-2',
      paddingY: 'space-14',
      transitionDuration: 'duration-200',
      transitionProperty: 'common',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 'space-16',
    },
  };
});

export default defineMultiStyleConfig({
  baseStyle,
});
