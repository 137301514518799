import { z } from 'zod';

export const userSchema = z.object({
  email: z.string().optional(),
  firebaseToken: z.string().optional(),
  phone: z.string().optional(),
  token: z.string(),
  uid: z.string(),
  userHash: z.string().optional(),
  zip: z.string().optional(),
});

export type User = z.infer<typeof userSchema>;
