/**
 * The list of the front-end errors we are ready to handle with a message.
 */
export enum OBErrorCode {
  UNRECOGNIZED = 'UNRECOGNIZED',
  NETWORK = 'NETWORK',
  RATE_LIMIT = 'RATE_LIMIT',
  WRONG_PASSCODE = 'WRONG_PASSCODE',
  CODE_NOT_MATCH = 'CODE_NOT_MATCH',
  CODE_EXPIRED = 'CODE_EXPIRED',
  PAYMENT_3DS_FAILURE = 'PAYMENT_3DS_FAILURE',
}
