export const getInvoiceDate = (dateParts: Intl.DateTimeFormatPart[]) => {
  const month = dateParts.find((part) => part.type === 'month')?.value;

  const year = dateParts.find((part) => part.type === 'year')?.value;

  if (!month || !year) {
    return null;
  }

  return {
    month: month.charAt(0).toLocaleUpperCase() + month.slice(1),
    year,
  };
};
