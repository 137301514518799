import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    cta: 'Continue',
    labels: {
      hasEUCitizenshipOrVisa:
        'You are an European citizen or have a non-student visa',
      isAdult: 'You are over 18',
    },
    title: 'SASU creation is available for you if:',
  },
  fr: {
    cta: 'Continuer',
    labels: {
      hasEUCitizenshipOrVisa:
        'Vous êtes un·e ressortissant·e européen·ne ou détenteur·ice d’un visa non étudiant valide',
      isAdult: 'Vous êtes majeur·e',
    },
    title: 'La création d’une SASU est disponible pour vous si\u00A0:',
  },
} as const);

export default locales;
