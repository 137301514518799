import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    connectionFromANewDevice: 'Login attempt from a new device',
    firstConnection:
      'This is the first time you have connected to this device. In order to ensure the security of your Shine account, we ask you to confirm this login with an already known device.',
    noAccess: 'I do not have access to my devices',
  },
  fr: {
    connectionFromANewDevice: 'Connexion depuis un nouvel appareil',
    firstConnection:
      'C’est la première fois que vous vous connectez avec cet appareil. Afin de veiller à la sécurité de votre compte Shine, nous vous demandons de confirmer cette connexion à l’aide d’un appareil déjà connu.',
    noAccess: 'Je n’ai pas accès à mes appareils',
  },
} as const);

export default locales;
