import { Center } from '@chakra-ui/react';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import Loader from 'components/Loader';

import Navigation from '../Navigation/Navigation';

interface LoaderPageProps {
  /**
   * Use this for modules that don't show navigations, for example PricingPlan.
   * Avoids flashing a navigation while you load, then making it disappear.
   */
  hideNavigation?: boolean;
}

/**
 * A loader page for any onboarding page.
 */
const LoaderPage = asBentoPage<LoaderPageProps>(
  ({ hideNavigation = false, Layout }) => (
    <Layout data-testid="loader" nav={hideNavigation ? null : <Navigation />}>
      <Center height="full">
        <Loader />
      </Center>
    </Layout>
  ),
);

export default LoaderPage;
