import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    title: 'We are checking your documents, not long now!',
  },
  fr: {
    title:
      'Nous analysons vos documents, plus que quelques instants à patienter\xA0!',
  },
} as const);

export default locales;
