import { Gender } from 'common/types';

import locales from '../locales';

export const GENDER_OPTIONS = [
  {
    abbreviatedLabel: locales.legalTitles.abbreviatedMale,
    label: locales.legalTitles.male,
    value: Gender.MALE,
  },
  {
    abbreviatedLabel: locales.legalTitles.abbreviatedFemale,
    label: locales.legalTitles.female,
    value: Gender.FEMALE,
  },
];
