import { useEffect } from 'react';

/**
 * This hook should be used within a component
 * It will make the window scroll up to the top when the component mounts
 */
const useScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export default useScrollToTopOnMount;
