import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    acceptTransactionHint:
      'By authorizing, your account will be debited of {formattedAmount}',
    authorize: 'Authorize this payment',
    description:
      'If you did not initiate this payment, click REFUSE and contact our customer support.',
    descriptionFraudVariant:
      'Please note that this is **neither a refund nor a cancellation.**',
    refuse: 'Refuse',
    title: 'Payment pending confirmation',
  },
  fr: {
    acceptTransactionHint:
      'En autorisant, votre compte sera débité de {formattedAmount}',
    authorize: 'Autoriser ce paiement',
    description:
      'Si vous n‘êtes pas à l‘initiative de ce paiement, cliquez sur REFUSER et contactez notre service client.',
    descriptionFraudVariant:
      'Attention, il ne s’agit **ni d’un remboursement, ni d’une annulation.**',
    refuse: 'Refuser',
    title: 'Paiement en attente de validation',
  },
} as const);

export default locales;
