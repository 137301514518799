import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description:
      'This view couldn’t load correctly. It happens sometimes, and in this case you have to wait a few minutes before trying again. If the problem persists after 10 minutes, you can contact our support team.',
    retryButtonText: 'Try again',
    title: 'An error occurred',
  },
  fr: {
    description:
      'Cette vue n’a pas pu charger correctement. Cela arrive parfois, et il faut dans ce cas-là patienter quelques minutes avant de réessayer. Si le problème persiste après 10 minutes, vous pouvez prendre contact avec nos équipes.',
    retryButtonText: 'Réessayer',
    title: 'Une erreur est survenue',
  },
} as const);

export default locales;
