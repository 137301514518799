import styled from 'styled-components';

export const Inner = styled.div`
  width: 510px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: none;
  overflow-y: auto;

  padding: ${({ theme }) => theme.spacings.abs48}px;

  padding-top: ${({ theme }) => theme.spacings.abs48}px;
  padding-bottom: ${({ theme }) => theme.spacings.abs24}px;

  @media (max-width: 504px) {
    width: 100%;
  }
  @media (max-width: 450px) {
    padding-left: ${({ theme }) => theme.spacings.abs24}px;
    padding-right: ${({ theme }) => theme.spacings.abs24}px;
  }
  @media (max-width: 338px) {
    padding-left: ${({ theme }) => theme.spacings.abs24}px;
    padding-right: ${({ theme }) => theme.spacings.abs24}px;
  }
  @media (max-width: 322px) {
    padding-left: ${({ theme }) => theme.spacings.abs16}px;
    padding-right: ${({ theme }) => theme.spacings.abs16}px;
  }
  @media (max-width: 306px) {
    padding-left: ${({ theme }) => theme.spacings.abs8}px;
    padding-right: ${({ theme }) => theme.spacings.abs8}px;
  }
`;

export const Screenshot = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.abs64}px 0
    ${({ theme }) => theme.spacings.abs32}px;
  gap: ${({ theme }) => theme.spacings.abs8}px;
  overflow: hidden;

  background-color: #edf6fa;
`;

export const Heading = styled.h1`
  max-width: 554px;
  z-index: 1;

  font-family: HeldaneText-Bold;
  font-weight: bold;
  font-size: 38px; // No such value in theme spacings, so it is hardcoded
  line-height: 48px; // No such value in theme spacings, so it is hardcoded
  text-align: center;
  color: ${({ theme }) => theme.colors.grey(-2)};
`;

interface AppShotProps {
  bg: string;
  bg2x: string;
  bg3x: string;
}

export const AppShot = styled.div<AppShotProps>`
  width: 100%;
  height: 100%;
  flex: 1;
  z-index: 1;

  background-image: url(${({ bg }) => bg});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media (min-resolution: 192dpi),
    (-webkit-min-device-pixel-ratio: 2),
    (min--moz-device-pixel-ratio: 2),
    (-o-min-device-pixel-ratio: 2/1),
    (min-device-pixel-ratio: 2),
    (min-resolution: 2dppx) {
    background-image: url(${(props) => props.bg2x});
  }

  @media (min-resolution: 300dpi),
    (-webkit-min-device-pixel-ratio: 3),
    (min--moz-device-pixel-ratio: 3),
    (-o-min-device-pixel-ratio: 3/1),
    (min-device-pixel-ratio: 3),
    (min-resolution: 3dppx) {
    background-image: url(${(props) => props.bg3x});
  }
`;

const Ellipsis = styled.div`
  position: absolute;
  opacity: 0.4;
  filter: blur(65px);
  border-radius: 50%;

  // Way to get a square with both side having <width> %
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const TopLeftEllipsis = styled(Ellipsis)`
  left: 0;
  top: 0;

  /*
   * The following width and translate values ensure a consistent position
   * no matter what the screenshot container ratio is
   */
  width: 67%;
  transform: translate(-56%, -78%) rotate(-170.2deg);

  background: linear-gradient(94.65deg, #ffe0ea 4.71%, #aad2fa 96.67%);
`;

export const BottomRightEllipsis = styled(Ellipsis)`
  right: 0;
  bottom: 0;

  /*
   * The following width and translate values ensure a consistent position
   * no matter what the screenshot container ratio is
   */
  width: 140%;
  transform: translate(24%, 88%) rotate(147.24deg);

  background: linear-gradient(
    94.65deg,
    #f7b2c7 4.71%,
    #f8f4ab 38.78%,
    #97f2e2 69.17%,
    #aad2fa 96.67%
  );
`;
