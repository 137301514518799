import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacings.abs64}px;
`;

export const Image = styled.img`
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacings.abs32}px;
  width: 144px;
  align-self: center;
`;

export const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacings.abs24}px
    ${({ theme }) => theme.spacings.abs24}px;
  ${({ theme }) => theme.spacings.abs16}px;
  ${({ theme }) => theme.spacings.abs24}px;
`;
