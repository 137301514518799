import { components, tokens } from '@shinetools/sunshine';

const colors = {
  ...tokens,
  ...components.button,
  ...components.card,
  ...components.checkbox,
  ...components.chip,
  ...components.radio,
  ...components.input,
  ...components.progressBar,
};

export default colors;
