import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    countryLabel: 'Country',
    onlyAvailableInFrance: 'Only french addresses are accepted at the moment.',
  },
  fr: {
    countryLabel: 'Pays',
    onlyAvailableInFrance:
      'Seules les adresses françaises sont acceptées pour le moment.',
  },
} as const);
