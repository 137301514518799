import { Center } from '@chakra-ui/react';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import Loader from 'components/Loader';

import OBNavigation from '../OBNavigation/OBNavigation';

interface OBLoaderPageProps {
  /**
   * Use this for modules that don't show navigations, for example PricingPlan.
   * Avoids flashing a navigation while you load, then making it disappear.
   */
  hideNavigation?: boolean;
}

/**
 * A loader page for any onboarding page.
 */
const OBLoaderPage = asBentoPage<OBLoaderPageProps>(
  ({ hideNavigation = false, Layout }) => (
    <Layout data-testid="loader" nav={hideNavigation ? null : <OBNavigation />}>
      <Center height="full">
        <Loader />
      </Center>
    </Layout>
  ),
  { isLoadingState: true },
);

export default OBLoaderPage;
