import LocalizedStrings from 'localized-strings';

import { Gender } from 'common/types';

const locales = new LocalizedStrings({
  en: {
    continue: 'Continue',
    errors: {
      invalidDate: 'This date isn’t valid',
      isNotAdult: 'You must be 18 or older to open a Shine account',
    },
    labels: {
      birthdate: 'Date of birth',
      firstName: 'First name',
      gender: {
        [Gender.FEMALE]: 'Ms',
        [Gender.MALE]: 'Mr',
      },
      lastName: 'Last name',
      legalTitle: 'Legal title',
      nationality: 'Nationality',
    },
    placeholders: {
      birthdate: 'Pick a date',
    },
    title: 'Confirm your personal information',
  },
  fr: {
    continue: 'Continuer',

    errors: {
      invalidDate: 'Cette date n’est pas valide',
      isNotAdult: 'Vous devez avoir 18 ans minimum pour ouvrir un compte Shine',
    },

    labels: {
      birthdate: 'Date de naissance',
      firstName: 'Prénom',
      gender: {
        [Gender.FEMALE]: 'Mme',
        [Gender.MALE]: 'M.',
      },
      lastName: 'Nom de famille',
      legalTitle: 'Civilité',
      nationality: 'Nationalité',
    },
    placeholders: {
      birthdate: 'Sélectionnez une date',
    },
    title: 'Confirmez vos informations personnelles',
  },
} as const);

export default locales;
