import { useState } from 'react';

const FORCE_MOBILE_KEY = 'forceMobile';

const useForceMobileAccess = () => {
  const [isMobileAccessForced, setIsMobileAccessForced] = useState<boolean>(
    Boolean(window.localStorage.getItem(FORCE_MOBILE_KEY)),
  );

  const forceMobileAccess = () => {
    setIsMobileAccessForced(true);
    window.localStorage.setItem(FORCE_MOBILE_KEY, 'true');
  };

  return { forceMobileAccess, isMobileAccessForced };
};

export default useForceMobileAccess;
