import { Controller, useForm } from 'react-hook-form';
import { Flex, Image, Link, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import Button from 'components/_core/Button';
import * as Form from 'components/_core/form';
import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import Text from 'components/_core/Text';
import { OBCard } from 'features/Bento/components';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import illustration from './assets/euFlag.png';
import locales from './locales';

const biometricConsentSchema = z.object({
  hasAgreed: z
    .boolean()
    .refine((hasAgreed) => hasAgreed, { message: locales.consentError }),
});

interface BiometricConsentProps {
  onContinue: () => void;
}

const BiometricConsent = asBentoPage<BiometricConsentProps>(
  ({ Layout, onContinue, onPrev }) => {
    const form = useForm({
      defaultValues: { hasAgreed: false },
      resolver: zodResolver(biometricConsentSchema),
    });

    return (
      <Layout nav={<Navigation onPrev={onPrev} />}>
        <Flex
          as="form"
          direction="column"
          height={{ base: 'full', md: 'auto' }}
          onSubmit={form.handleSubmit(onContinue)}
        >
          <OBCard flexGrow={1}>
            <OBCard.Body>
              <VStack spacing="space-16">
                <Image height="180px" src={illustration} width="180px" />

                <Heading size="lg" textAlign="center">
                  {locales.title}
                </Heading>

                <Text textAlign="center">{locales.subtitle}</Text>

                <Controller
                  control={form.control}
                  name="hasAgreed"
                  render={({ field }) => (
                    <Form.Field
                      error={form.formState.errors.hasAgreed?.message}
                    >
                      <SunshineCard
                        data-testid="check-card"
                        isChecked={field.value}
                        onChange={field.onChange}
                        title={locales.consent}
                        variant="checkbox"
                      />
                    </Form.Field>
                  )}
                />
              </VStack>
            </OBCard.Body>

            <OBCard.Footer bottom="space-24" justifyContent="flex-end">
              <Button icon="arrow-right" type="submit">
                {locales.next}
              </Button>
            </OBCard.Footer>
          </OBCard>

          <Link
            display="block"
            href="https://www.shine.fr/privacy/fr/"
            marginTop="space-24"
            target="_blank"
            textAlign="center"
          >
            {locales.moreInfo}
          </Link>
        </Flex>
      </Layout>
    );
  },
);

export default BiometricConsent;
