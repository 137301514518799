/* eslint-disable sort-keys-fix/sort-keys-fix */

import space from './space';

const container = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

const sizes = {
  full: '100%',
  ...space,
  container,
};

export default sizes;
