import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';

/**
 * This function will parse an URL querystring and return the value of target param.
 * It consumes the target param, meaning the resulting querystring doesn't contain it anymore.
 *
 * The resulting queryString is **ready to be appended to a pathname** (it includes the question mark).
 */
export const consumeQueryParam = (queryString: string, param: string) => {
  const searchParams = new URLSearchParams(queryString);

  const paramValue = searchParams.get(param);

  searchParams.delete(param);

  const resultingQueryString = searchParams.toString();

  return {
    resultingQueryString: resultingQueryString
      ? `?${resultingQueryString}`
      : '',
    value: paramValue,
  };
};

/**
 * This hook consumes a search param from the current location and returns it.
 *
 * It is:
 * - fully synchronous (reason why a useEffect wasn't used).
 * - reactive (if you update the argument, it will synchronously return the appropriate value).
 */
export const useConsumeQueryParam = (
  searchParam: string,
  callback?: (param: string) => void,
) => {
  const history = useHistory();
  const location = useLocation();

  const [savedParam, setSavedParam] = useState<null | {
    key: string;
    value: string | null;
  }>(null);

  if (savedParam && savedParam.key === searchParam) {
    return savedParam.value;
  }

  const consumed = consumeQueryParam(location.search, searchParam);

  if (consumed.value) {
    callback?.(consumed.value);
  }

  setSavedParam({
    key: searchParam,
    value: consumed.value,
  });

  history.replace({
    pathname: location.pathname,
    search: consumed.resultingQueryString,
  });

  return consumed.value;
};
