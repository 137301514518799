/* eslint-disable @typescript-eslint/naming-convention */
import logger from 'helpers/logger';

import getCookies from './getCookies';

const getUTMParametersFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);

  return {
    utm_campaign: urlParams.get('utm_campaign') ?? undefined,
    utm_medium: urlParams.get('utm_medium') ?? undefined,
    utm_source: urlParams.get('utm_source') ?? undefined,
  };
};

export const UTM_PARAMS_STORAGE_KEY = 'onboarding.utmParams';

export const setUTMParametersInLocalStorage = () => {
  const params = getUTMParametersFromUrl();

  localStorage.setItem(UTM_PARAMS_STORAGE_KEY, JSON.stringify(params));
};

/**
 * Get the UTM parameters from the cookies storage
 * https://en.wikipedia.org/wiki/UTM_parameters
 * These are stored from shine.fr’s "HTML - Cookie - UTMz" GTM script
 */
const getUTMParametersFromCookies = () => {
  const { utm_zlc } = getCookies();

  // UTMs from the last non-organic click
  if (!utm_zlc) {
    return null;
  }

  try {
    // Stored as `utm_source_value|utm_medium_value|utm_campaign_value`
    const [utm_source, utm_medium, utm_campaign] = atob(utm_zlc).split('|');

    return {
      utm_campaign,
      utm_medium,
      utm_source,
    };
  } catch (error) {
    logger.error('Couldn’t decode utm_zlc cookie', { context: { error } });
    return null;
  }
};

const getUTMParametersFromLocalStorage = () => {
  const rawData = localStorage.getItem(UTM_PARAMS_STORAGE_KEY);

  if (!rawData) {
    return {};
  }

  try {
    return JSON.parse(rawData) as {
      utm_campaign?: string;
      utm_medium?: string;
      utm_source?: string;
    };
  } catch {
    return {};
  }
};

/**
 * Get the UTM parameters from the utm_zlc cookie in priority
 * If not found, get them from the local storage, which have been set from the URL
 */
export const getUTMParametersFromStorage = () =>
  getUTMParametersFromCookies() || getUTMParametersFromLocalStorage();
