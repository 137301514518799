import config from 'config';
import { post } from 'helpers/fetch';

export const sendEmailWithSecureLink = async ({
  authenticationDeviceId,
  authenticationDeviceRequestId,
  passcode,
  phone,
}: {
  authenticationDeviceId: string;
  authenticationDeviceRequestId: string;
  phone: string;
  passcode: string;
}): Promise<void> => {
  const sendEmailURL = `${config.shineApiHost}/authentication/phone/${phone}/devices/${authenticationDeviceId}/requests/${authenticationDeviceRequestId}/send_email_fallback`;

  await post(sendEmailURL, { passcode });
};
