import { useEffect, useState } from 'react';
import { getRemoteConfig } from 'firebase/remote-config';

import { logEvent } from 'features/Analytics/analytics';

import {
  fetchAndActivateRemoteConfig,
  getConfigGettersOnceLoaded,
} from '../../../../common/remoteConfig';

const enableFraudWarningFirebaseKey = 'enableFraudWarningModal';
const ONE_MINUTE = 60 * 1000;
const CACHE_INVALIDATION_FETCH_INTERVAL = 1;
const HAS_SEEN_FRAUD_WARNING_MODAL_LOCAL_STORAGE_KEY =
  'hasSeenFraudWarningModal';

const useFraudWarningModal = () => {
  const [hasSeenFraudWarningModal, setHasSeenFraudWarningModal] =
    useState<boolean>(false);

  const [isFraudWarningModalEnabled, setIsFraudWarningModalEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const { getBoolean } = await getConfigGettersOnceLoaded();

      const config = getRemoteConfig();

      config.settings = {
        fetchTimeoutMillis: ONE_MINUTE,
        minimumFetchIntervalMillis: CACHE_INVALIDATION_FETCH_INTERVAL,
      };

      // Invalidate remote config cache
      await fetchAndActivateRemoteConfig(config);

      const result = getBoolean(enableFraudWarningFirebaseKey);
      setIsFraudWarningModalEnabled(result);
    })();
  }, []);

  useEffect(() => {
    const checkModalSeenStatus = async () => {
      const value = window.localStorage.getItem(
        HAS_SEEN_FRAUD_WARNING_MODAL_LOCAL_STORAGE_KEY,
      );
      const hasSeenModal = value === 'true';
      setHasSeenFraudWarningModal(!!hasSeenModal);
    };
    checkModalSeenStatus();
  }, []);

  const dismissFraudWarning = () => {
    setHasSeenFraudWarningModal(true);
    window.localStorage.setItem(
      HAS_SEEN_FRAUD_WARNING_MODAL_LOCAL_STORAGE_KEY,
      'true',
    );
    logEvent({ name: 'Has_Seen_Fraud_Warning_Modal' });
  };

  // Should also only display if account is validated (this is ensured at the upper level to mutualize data loading)
  const shouldDisplayFraudWarning =
    !hasSeenFraudWarningModal && isFraudWarningModalEnabled;

  return {
    dismissFraudWarning,
    shouldDisplayFraudWarning,
  };
};

export default useFraudWarningModal;
