import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    new: 'New',
  },
  fr: {
    new: 'Nouveau',
  },
} as const);

export default locales;
