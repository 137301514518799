import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description:
      'Free your professional expenses: get your own card and manage your expenses on your own.',
    title: 'Say goodbye to expenses claims',
  },
  fr: {
    description:
      'Effectuez vos dépenses pros en toute liberté\xa0: obtenez votre propre carte et gérez vos dépenses en autonomie.',
    title: 'Dites adieu aux notes de frais',
  },
} as const);

export default locales;
