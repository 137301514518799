import * as Types from '../../../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StartIdentityVerificationMutationVariables = Types.Exact<{
  input: Types.StartIdentityVerificationInput;
}>;


export type StartIdentityVerificationMutation = { __typename: 'Mutation', startIdentityVerification: { __typename: 'StartIdentityVerificationPayload', provider: Types.KycProvider, sdkToken: string } };


export const StartIdentityVerificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"startIdentityVerification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"StartIdentityVerificationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startIdentityVerification"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"provider"}},{"kind":"Field","name":{"kind":"Name","value":"sdkToken"}}]}}]}}]} as unknown as DocumentNode<StartIdentityVerificationMutation, StartIdentityVerificationMutationVariables>;