import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    defaultPlaceholder: 'Select...',
    noOptionsMessage: 'No results',
  },
  fr: {
    defaultPlaceholder: 'Sélectionnez...',
    noOptionsMessage: 'Aucun résultat',
  },
} as const);

export default locales;
