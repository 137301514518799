import { Box, type BoxProps, forwardRef } from '@chakra-ui/react';

import { useTable } from '../context';

export type HeaderProps = BoxProps;

export const Header = forwardRef<HeaderProps, 'div'>((props, ref) => {
  const { __css: css, ...rest } = props;
  const { columnStyles, theme } = useTable();

  return (
    <Box
      __css={{
        ...theme.header,
        ...columnStyles,
        ...css,
      }}
      className="table-header"
      {...rest}
      ref={ref}
    />
  );
});
