import { Box, type BoxProps, forwardRef } from '@chakra-ui/react';
import { Typography } from '@shinetools/sunshine-universal';

import { useQuota } from '../context';

export type ExceedingProps = BoxProps;

export const Exceeding = forwardRef<ExceedingProps, 'div'>((props, ref) => {
  const { __css: css, ...rest } = props;

  const { theme } = useQuota();

  return (
    <Box
      __css={{ ...theme.exceeding, ...css }}
      className="quota__exceeding"
      {...rest}
      ref={ref}
    >
      <Typography.Text bold variant="discreet">
        {props.children}
      </Typography.Text>
    </Box>
  );
});
