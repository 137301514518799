import { forwardRef } from '@chakra-ui/system';

import SunshineIcon from 'components/_core/SunshineIcon';

import { useStyles } from '../../context';

const CheckboxIcon = forwardRef((props, ref) => {
  const styles = useStyles();

  return <SunshineIcon __css={styles.icon} name="tick" ref={ref} />;
});

export default CheckboxIcon;
