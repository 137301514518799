import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    accountNotFound: 'This account does not seem to exist',
    blockedByServer: 'Can’t connect right now. Try again later',
    connectionErrorMessage: 'Please check your connection and try again',
    createAccount: 'Create my account',
    haveAccount: {
      beneficiary: 'Already have an account? Already did this step?',
      regular: 'Already have an account? Already started registering?',
    },
    invalidPasscode: 'Invalid passcode',
    invalidPhone: 'Invalid phone number format',
    invalidVerificationCode: 'Invalid verification code',
    invitedBeneficiary: {
      helpAnswer: `As a payment institution, we are legally required to collect the information of all beneficial owners of a company before opening a professional account. You have been listed by the account holder as a beneficial owner, which is why we are asking you to complete your information.`,
      helpQuestion: 'Why do I need to provide my information?',
    },
    login: 'Sign in',
    noAccount: 'No Shine account yet?',
    openAccount: 'Open an account',
    phoneAuth:
      '**Why use my phone number as my login?**\n\nThis will improve the security of the connection using a verification SMS. It is also a quick and easy way to log in without forgetting your login details!',
    phoneNumberNotAllowed: 'Your phone number area code is not allowed',
    phoneNumberRegisteringSubtitle: {
      beneficiary:
        'Enter your phone number to create your access. It’s simple, and safe.',
      regular:
        'Enter your phone number to create your account. It’s simple, and safe.',
    },
    phoneNumberRegisteringTitle: 'Welcome to Shine!',
    phoneNumberTitle: 'Login',
    rateLimitError: 'Too many attempts. Try again in a hour',
    registerNotFinished: 'Please complete your registration',
    sendANewCode: 'Send a new code',
    sentSmsTo: 'We sent a message to ',
    twoFactor:
      'Shine is using a combination of two factor authentication and a simple code to make logging into your account both very easy and secure.',
    unknownError: 'Oops! There was an unknown error',
    wrongInvitBody:
      'If you were invited to complete your profile as part of the process of opening a pro account for your company, the link you were given may be incorrect.\n\nPlease check for typos or contact the person who invited you so that he or she can give you a new functional link.',
    wrongInvitTitle: 'Looks like you landed on a page that doesn’t exist',
  },
  fr: {
    accountNotFound: 'Ce compte ne semble pas exister',
    blockedByServer:
      'Connexion impossible pour le moment, veuillez-reessayer ultérieurement',
    connectionErrorMessage: 'Pouvez-vous vous reconnecter et réessayer\u00A0?',
    createAccount: 'Créer mon compte',
    haveAccount: {
      beneficiary:
        'Vous avez déjà un compte Shine,\nvous avez déjà réalisé cette étape ?',
      regular:
        'Vous avez déjà un compte ? Vous avez commencé à vous inscrire\u00A0?',
    },
    invalidPasscode: 'Code secret erroné',
    invalidPhone: 'Le format du numéro de téléphone est invalide',
    invalidVerificationCode: 'Code de vérification erroné',
    invitedBeneficiary: {
      helpAnswer: `En tant qu'établissement de paiement, nous devons légalement recueillir les informations de tous les bénéficiaires effectifs d'une société avant d'ouvrir un compte pro. Vous avez été listé·e par le ou la titulaire du compte comme bénéficiaire effectif, c'est pourquoi nous vous demandons de compléter vos informations.`,
      helpQuestion: 'Pourquoi dois-je compléter mes informations ?',
    },
    login: 'Se connecter',
    noAccount: 'Je n’ai pas encore de compte Shine',
    openAccount: 'Ouvrir un compte',
    phoneAuth:
      '**Pourquoi utiliser mon numéro de téléphone comme identifiant\u00A0?**\nIl nous permet de vous proposer une connexion plus sécurisée grâce aux SMS de validation de connexion. C’est aussi un moyen simple et rapide de vous connecter, sans risquer d’oublier vos identifiants\u00A0!',
    phoneNumberNotAllowed:
      'L’indicatif de votre numéro de téléphone n’est pas autorisé',
    phoneNumberRegisteringSubtitle: {
      beneficiary:
        'Entrez votre numéro de téléphone pour créer votre espace personnel. C’est simple et sécurisé\u00A0!',
      regular:
        'Entrez votre numéro de téléphone pour créer votre compte. C’est simple et sécurisé\u00A0!',
    },
    phoneNumberRegisteringTitle: 'Bienvenue sur Shine\u00A0!',
    phoneNumberTitle: 'Se connecter',
    rateLimitError:
      'Vous avez dépassé le nombre d’essais autorisés. Vous pourrez réessayer dans une heure',
    registerNotFinished: 'Veuillez terminer votre inscription',
    sendANewCode: 'Envoyer un nouveau code',
    sentSmsTo: 'Nous avons envoyé un SMS au ',
    twoFactor:
      'Shine utilise une authentification à deux facteurs et un code simple pour rendre la connexion à votre compte à la fois simple et sécurisée.',
    unknownError: 'Oups\u00A0! Une erreur inconnue est survenue',
    wrongInvitBody:
      'Vous avez été invité·e à créer un profil Shine dans le cadre de l’ouverture d’un compte pour votre entreprise ? Le lien qui vous a été donné est peut être incorrect ou a expiré.\n\nNous vous conseillons de vérifier les éventuelles coquilles, ou de contacter la personne qui vous a envoyé l’invitation afin qu’elle vous transmette un nouveau lien fonctionnel.',
    wrongInvitTitle: 'Il semble que cette page n’existe pas',
  },
} as const);

export default locales;
