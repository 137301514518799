import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    features: {
      notifications:
        'Create your access and confirm your identity in a few clicks',
      orderCard: 'Request a card suited to your expenses',
      transactions: 'Manage all your expenses independently',
    },
  },
  fr: {
    features: {
      notifications:
        'Créez votre accès et vérifiez votre identité en quelques clics',
      orderCard: 'Demandez une carte adaptée à vos dépenses',
      transactions: 'Gérez toutes vos dépenses en autonomie',
    },
  },
} as const);
