import { useLayoutEffect } from 'react';

import debounceToNextFrame from 'common/debounceToNextFrame';

/*
  TODO(alex): creating multiple ResizeObserver's instances especially observing
  the same element everytime could be a poor choice for performance.
  Check if rolling back to a simple resize event listener on window wouldn't be better.
*/

/**
 * Call handler on the frame following element's resize
 * @param handler
 */
const useResize = (handler: () => void): void => {
  useLayoutEffect((): (() => void) | undefined => {
    const element = document.body;
    if (!window.ResizeObserver || !element) {
      return undefined;
    }
    handler();
    const debouncedHandler = debounceToNextFrame(handler);
    let resizeObserver: ResizeObserver | null = new window.ResizeObserver(
      debouncedHandler,
    );
    resizeObserver.observe(element);
    return (): void => {
      if (resizeObserver) {
        debouncedHandler.cancel();
        resizeObserver.disconnect();
        resizeObserver = null;
      }
    };
  }, [handler]);
};

export default useResize;
