export const getTextSize = (size: number) => {
  /**
   * If size = 0, then Price text component will be a `Text`
   * to which default size is 0.
   */
  if (size === 0) {
    return undefined;
  }

  if (size === 3) {
    return 'lg';
  }

  if (size === 4) {
    return 'font-31';
  }

  return '23px';
};

export const getVariant = (size: number): 'number' | 'primary' => {
  if (size === 4) {
    return 'number';
  }

  return 'primary';
};
