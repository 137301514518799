import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
`;

export const ContentFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 504px) {
    align-items: center;
  }
  margin-top: 36px;
  margin-bottom: ${({ theme }) => theme.spacings.abs48}px;
`;
