import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * A hook that returns a URLSearchParams instance for the current URL.
 *
 * The returning instance is referentially stable through renders with an equal query string,
 * but will stay up-to-date when the query string changes.
 *
 * Inspired by :
 * @see https://reactrouter.com/web/example/query-parameters
 */
const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useQueryParams;
