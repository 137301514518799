import {
  forwardRef,
  RadioGroup,
  type RadioGroupProps,
  Stack,
  type StackProps,
  useRadioGroup,
} from '@chakra-ui/react';

import { type RadioPositions } from '../..';
import { RadioGroupProvider } from '../../hooks/useRadioGroup';

export type GroupProps = RadioGroupProps & {
  direction?: StackProps['direction'];
  radioPosition?: RadioPositions;
  isInvalid?: boolean;
  spacing?: StackProps['spacing'];
};

const Group = forwardRef<GroupProps, 'div'>(
  (
    {
      children,
      direction = 'column',
      isInvalid,
      radioPosition = 'left',
      spacing = 'space-12',
      ...props
    },
    ref,
  ) => {
    const group = useRadioGroup(props);

    return (
      <RadioGroupProvider value={{ ...group, isInvalid, radioPosition }}>
        <RadioGroup data-testid="radio-group" {...props}>
          <Stack direction={direction} ref={ref} spacing={spacing}>
            {children}
          </Stack>
        </RadioGroup>
      </RadioGroupProvider>
    );
  },
);

export default Group;
