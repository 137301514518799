import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    contactUs: 'Contact us',
    descriptionOngoingOnboarding:
      'It seems you already started an account opening with Shine.\n\nTo be able to verify your identity as beneficiary of **{invitationCompanyName}**, please finish the opening of your account for **{onboardingCompanyName}** first.\n\nIf you would like to cancel the previous account opening, please contact our support.',
    descriptionOngoingOnboardingAsBeneficiary:
      'It seems you already have an ongoing identity check.\n\nTo be able to verify your identity as beneficiary of **{invitationCompanyName}**, please finish your process for **{onboardingCompanyName}** first.',
    descriptionOngoingOnboardingNoName:
      'It seems you already started an account opening with Shine.\n\nTo be able to verify your identity as beneficiary of **{invitationCompanyName}**, please finish the opening of your account first.\n\nIf you would like to cancel the previous account opening, please contact our support.',
    finishOnboardingCta: 'Finish my account creation',
    titleOngoingOnboarding: 'You already have an ongoing account creation',
    titleOngoingOnboardingAsBeneficiary:
      'You already have an identity check ongoing',
  },
  fr: {
    contactUs: 'Contacter l’équipe',
    descriptionOngoingOnboarding:
      'Il semblerait que vous ayez déjà entamé une ouverture de compte chez Shine.\n\nAfin de vérifier votre identité en tant que bénéficiaire de **{invitationCompanyName}**, veuillez d’abord finaliser la création de votre compte pour **{onboardingCompanyName}**.\n\nSi vous souhaitez annuler votre ouverture de compte précédente, veuillez contacter notre équipe.',
    descriptionOngoingOnboardingAsBeneficiary:
      'Il semblerait que vous ayez déjà une vérification d’identité en cours.\n\nAfin de vérifier votre identité en tant que bénéficiaire de **{invitationCompanyName}**, veuillez d’abord finaliser vos démarches pour l’entreprise **{onboardingCompanyName}**.',
    descriptionOngoingOnboardingNoName:
      'Il semblerait que vous ayez déjà entamé une ouverture de compte chez Shine.\n\nAfin de vérifier votre identité en tant que bénéficiaire de **{invitationCompanyName}**, veuillez d’abord finaliser la création de votre compte.\n\nSi vous souhaitez annuler votre ouverture de compte précédente, veuillez contacter notre équipe.',
    finishOnboardingCta: 'Finaliser la création de mon compte',
    titleOngoingOnboarding: 'Vous avez déjà un compte en cours de création',
    titleOngoingOnboardingAsBeneficiary:
      'Vous avez déjà une demande de vérification d’identité en cours',
  },
} as const);
