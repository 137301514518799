import styled, { css } from 'styled-components';

import componentWithoutProps from 'common/componentWithoutProps';
import { easeOutExpo } from 'common/easings';
import Icon from 'components/_core/Icon';

import ButtonBadgeWrapper from './ButtonBadgeWrapper';

export const ButtonBadgeContainer = styled(
  componentWithoutProps(['hasText'], ButtonBadgeWrapper),
)`
  display: inline-flex;
  border: none;
  cursor: pointer;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.abs8}px
    ${({ hasText, theme }): number =>
      hasText ? theme.spacings.abs16 : theme.spacings.abs8}px;
  background-color: ${({ theme }): string => theme.colors.ctaBlue(4)};
  color: ${({ theme }): string => theme.colors.grey(-2)};
  border-radius: 100px;

  transition:
    background-color 0.4s ${easeOutExpo},
    color 0.4s ${easeOutExpo};

  :hover {
    background-color: ${({ theme }): string => theme.colors.ctaBlue(-1)};
    color: ${({ theme }): string => theme.colors.white(0)};
  }

  :active {
    background-color: ${({ theme }): string => theme.colors.ctaBlue(-1)};
  }

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: auto;
          background-color: ${({ theme }): string => theme.colors.grey(2)};
          color: ${({ theme }): string => theme.colors.white(0)};

          :hover,
          :active {
            background-color: ${({ theme }): string => theme.colors.grey(2)};
            color: ${({ theme }): string => theme.colors.white(0)};
          }
        `
      : null}
`;

export const ButtonBadgeIcon = styled(
  componentWithoutProps(['hasText'], Icon),
)<{ hasText: boolean }>`
  margin-right: ${({ hasText, theme }): number =>
    hasText ? theme.spacings.abs4 : 0}px;
`;
