/* eslint-disable sort-keys-fix/sort-keys-fix */
// https://www.figma.com/file/PQsLUUr7rmKzGzhBdgMkqdWu/Shadows?node-id=215%3A1394

const shadows = {
  'shadow-small-1':
    '0px 2px 6px rgba(0, 0, 0, 0.07), 0px 0px 2px rgba(0, 0, 0, 0.1)',
  'shadow-small-1-hover':
    '0px 4px 12px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.1)',
  'shadow-small-2':
    '0px 5px 20px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.08)',
  'shadow-medium-3':
    '0px 5px 26px rgba(0, 0, 0, 0.12), 0px 2px 6px rgba(0, 0, 0, 0.05)',
  'shadow-high-4':
    '0px 6px 32px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.1)',
  outline: '0px 0px 0px 2px #A8D4FF',
  'outline-rebranded': '0px 0px 0px 2px #2A93F4',
};

export default shadows;
