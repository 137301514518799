/* eslint-disable @typescript-eslint/no-use-before-define */

import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';

import { OutstandingAgreementType } from '__generated__/GQL';
import asBentoContainer from 'common/bento/hoc/asBentoContainer';
import withStateMachine from 'common/bento/hoc/withStateMachine/withStateMachine';
import assertNever from 'common/bento/lib/assertNever';
import stringifyState from 'common/bento/lib/stringifyState';
import Agreement from 'common/bento/modules/Agreement';
import { BentoModuleDoneStatus } from 'common/bento/types/BentoModule';
import useCompanyContext from 'common/hooks/useCompanyContext';
import SuspenseQuery from 'components/SuspenseQuery';
import OBLoaderPage from 'features/Onboarding/components/OBLoaderPage/OBLoaderPage';

import { GetAgreementCatchupContextDocument } from './graphql/queries/getAgreementCatchupContext.gql';
import machineConfig, { model, State } from './machine';
import Introduction from './pages/Introduction';
import Success from './pages/Success';

const AgreementCatchup = asBentoContainer(
  () => {
    const history = useHistory();

    const { companyProfileId } = useCompanyContext();

    const exitFlow = useCallback(() => history.push('/'), [history]);

    if (!companyProfileId) {
      exitFlow();

      return null;
    }

    const query = useQuery(GetAgreementCatchupContextDocument, {
      variables: {
        companyProfileId,
      },
    });

    return (
      <SuspenseQuery
        loader={<OBLoaderPage onPrev={() => null} />}
        query={query}
      >
        {(moduleData) => (
          <AgreementCatchupComponent
            moduleData={moduleData}
            onDone={exitFlow}
          />
        )}
      </SuspenseQuery>
    );
  },

  {
    layouts: {
      base: ({ children }) => <Box>{children}</Box>,
    },
  },
);

const AgreementCatchupComponent = withStateMachine<typeof model>(
  ({ machine, moduleData }) => {
    const [state, send] = machine;

    const stringifiedState = stringifyState<State>(state);

    switch (stringifiedState) {
      case State.INTRODUCTION:
        return (
          <Introduction
            moduleData={moduleData}
            onNext={() => send(model.events.NEXT())}
            onPrev={() => null}
          />
        );

      case State.AGREEMENT:
        return (
          <Agreement
            onDone={({ status }) =>
              status === BentoModuleDoneStatus.DONE
                ? send(model.events.NEXT())
                : send(model.events.PREV())
            }
            type={OutstandingAgreementType.Cgu}
          />
        );

      case State.SUCCESS:
      case State.DONE:
        return (
          <Success
            moduleData={moduleData}
            onNext={() => send(model.events.NEXT())}
            onPrev={() => send(model.events.PREV())}
          />
        );

      default:
        return assertNever(stringifiedState);
    }
  },

  {
    machineConfig,
    syncLocation: true,
  },
);

export default AgreementCatchup;
