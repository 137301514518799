import { enUS, fr } from 'date-fns/locale';
import LocalizedStrings from 'localized-strings';

// A lot of tests use String.prototype.toLocaleString which accepts
// a locale as first parameter. If it's passed undefined, it will
// try to automatically detect the user's language.
// Since tests run on an uncontrolled environment, we don't want
// to use automatic detection, and force the LOCALE to 'fr-FR'
// in the test environment

const SUPPORTED_LANGUAGES = { en: {}, fr: {} };

// export the list of keys rather than the whole object
export type SupportedLanguages = keyof typeof SUPPORTED_LANGUAGES;

const locales = new LocalizedStrings(SUPPORTED_LANGUAGES);

export const getLanguage = () =>
  (locales.getLanguage() || locales.getInterfaceLanguage()).substring(0, 2);

const LOCALE = (
  import.meta.env.MODE === 'test' ? 'fr' : getLanguage()
) as SupportedLanguages;

export const getDateFnsLocale = () => (getLanguage() === 'fr' ? fr : enUS);

export default LOCALE;
