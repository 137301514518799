import asBentoPage from 'common/bento/hoc/asBentoPage';
import Card from 'components/_core/Card';
import Heading from 'components/_core/Heading';
import SunshineIcon from 'components/_core/SunshineIcon';
import Text from 'components/_core/Text';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import locales from './locales';

interface UnderReviewProps {
  inviterName: string;
  adminEmail: string;
}

/**
 * A page allowing the admin to see that their application is sent.
 */
const UnderReview = asBentoPage<UnderReviewProps>(({ Layout }) => (
  <Layout nav={<Navigation />}>
    <Card gap="space-32" padding="space-32" textAlign="center">
      <Card.Title>
        <Heading size="lg">{locales.title}</Heading>
      </Card.Title>
      <Card.Content gap="space-32">
        <Text marginX="auto">
          <SunshineIcon name="timer" /> {locales.delay}
        </Text>
        <Text>{locales.nextText}</Text>
      </Card.Content>
    </Card>
  </Layout>
));

export default UnderReview;
