import { z } from 'zod';

import { nonEmptyStringSchema, zipCodeSchema } from 'common/zod/schemas';

import locales from './locales';

const MAX_STREET_CHARACTERS_LENGTH = 45;

const addressSchema = z.object({
  city: nonEmptyStringSchema,
  street: nonEmptyStringSchema.max(
    MAX_STREET_CHARACTERS_LENGTH,
    locales.formatString(
      locales.inputs.street.errors.limitedLength,
      MAX_STREET_CHARACTERS_LENGTH,
    ),
  ),
  streetAddition: z.string().optional(),
  zip: zipCodeSchema,
});

export type AddressSchema = z.input<typeof addressSchema>;

export const getDefaultAddress = (): AddressSchema => ({
  city: '',
  street: '',
  streetAddition: '',
  zip: '',
});

export default addressSchema;
