import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    errors: {
      idStepAlreadyDone: 'Your identity documents are being processed.',
    },
    identity: {
      genericError:
        'An error occurred during the analysis of your identity document.\nPlease restart the verification process.',
    },
    legalTitles: {
      abbreviatedFemale: 'Mrs.',
      abbreviatedMale: 'Mr.',
      female: 'Missus',
      male: 'Mister',
    },
  },
  fr: {
    errors: {
      idStepAlreadyDone: 'Vos documents d’identité sont en cours d’analyse.',
    },
    identity: {
      genericError:
        'Une erreur est survenue lors de l’analyse de votre document d’identité.\nVeuillez recommencer la vérification.',
    },
    legalTitles: {
      abbreviatedFemale: 'Mme',
      abbreviatedMale: 'M.',
      female: 'Madame',
      male: 'Monsieur',
    },
  },
} as const);

export default locales;
