import styled from 'styled-components';

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(252, 252, 253, 0.8);
`;

export const Container = styled.div`
  display: flex;
  width: 424px;
  height: 450px;

  background-color: white;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 5px 26px rgba(0, 0, 0, 0.12);
  border-radius: ${({ theme }) => theme.spacings.abs8}px;

  padding: ${({ theme }) => theme.spacings.abs32}px
    ${({ theme }) => theme.spacings.abs40}px;
`;

export const HighContainer = styled(Container)`
  height: 547px;
`;
