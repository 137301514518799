import { type FC } from 'react';
import { Flex, type FlexProps } from '@chakra-ui/react';

export type FooterItemProps = FlexProps;

export const FooterItem: FC<FooterItemProps> = (props) => (
  <Flex
    align="center"
    color="grey.800"
    flexBasis="50%"
    gap="space-16"
    padding="space-24"
    {...props}
  />
);
