import { Box, type BoxProps, forwardRef } from '@chakra-ui/react';

import { useQuota } from '../context';

export type ContentProps = BoxProps;

export const Content = forwardRef<ContentProps, 'div'>((props, ref) => {
  const { __css: css, ...rest } = props;

  const { theme } = useQuota();

  return (
    <Box
      __css={{
        ...theme.content,
        ...css,
      }}
      className="quota__content"
      {...rest}
      ref={ref}
    />
  );
});
