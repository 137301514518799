import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { NetworkStatus, useQuery } from '@apollo/client';
import { Stack } from '@chakra-ui/react';
import { Typography } from '@shinetools/sunshine-universal';

import mergeConnectionResultsBy from 'common/graphql/mergeConnectionResultsBy';
import useCompanyContext from 'common/hooks/useCompanyContext';
import Button from 'components/_core/Button';
import * as Layout from 'layouts/ContentLayout';

import { InvoicesTable } from '../../components/InvoicesTable';
import { Table } from '../../components/Table';
import * as routes from '../../routes';
import * as GQL from './invoices.gql';

const INVOICES_PER_PAGE = 20;

export const Invoices: FC = () => {
  const { companyProfileId } = useCompanyContext();

  const { data, fetchMore, loading, networkStatus } = useQuery(
    GQL.SubscriptionManagementInvoicesDocument,
    {
      notifyOnNetworkStatusChange: true,
      skip: !companyProfileId,
      variables: {
        after: null,
        companyProfileId: companyProfileId as string,
        first: INVOICES_PER_PAGE,
      },
    },
  );

  const invoicesData = data?.viewer.company.subscriptionPlan?.invoices;

  return (
    <Layout.Root>
      <Layout.Body size="md">
        <Stack align="start" spacing="space-48">
          <Button
            as={Link}
            icon="arrow-left"
            iconPosition="left"
            to={routes.root}
            variant="secondary"
          >
            <FormattedMessage id="subscription.invoices.back_button" />
          </Button>

          <Stack spacing="space-40" width="full">
            <Typography.Header>
              <FormattedMessage id="subscription.invoices.heading" />
            </Typography.Header>

            <InvoicesTable
              invoices={invoicesData?.edges}
              loading={loading && networkStatus !== NetworkStatus.fetchMore}
              loadingMore={loading && networkStatus === NetworkStatus.fetchMore}
            >
              {invoicesData?.pageInfo.hasNextPage ? (
                <Table.Footer
                  isLoading={
                    loading && networkStatus === NetworkStatus.fetchMore
                  }
                  onClick={() =>
                    fetchMore({
                      updateQuery: (prevResult, { fetchMoreResult }) =>
                        mergeConnectionResultsBy('subscriptionInvoiceId')(
                          prevResult,
                          fetchMoreResult,
                        ),
                      variables: {
                        after: invoicesData.pageInfo.endCursor,
                        // GraphQL returns the last item of the previous page everytime, so we need to ask for one more item to get the entire next page
                        first: INVOICES_PER_PAGE + 1,
                      },
                    })
                  }
                >
                  <FormattedMessage id="subscription.invoices.load_more_button" />
                </Table.Footer>
              ) : null}
            </InvoicesTable>
          </Stack>
        </Stack>
      </Layout.Body>
    </Layout.Root>
  );
};
