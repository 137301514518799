import logger from 'helpers/logger';

import { parseStringifiedWebViewConfig } from '../webViewMessages';
import getWebViewAPI from './getWebViewAPI';

const getWebViewConfig = () => {
  const parsed = parseStringifiedWebViewConfig(
    window.localStorage.getItem('webView.config'),
  );

  if (!parsed.success) {
    logger.error('[WebViewConfig] Config parsing failed', {
      context: { validationError: parsed.error.message },
    });
    throw new Error('[WebViewConfig] Config parsing failed');
  }

  return parsed.data;
};

export default getWebViewAPI() ? getWebViewConfig() : null;
