import {
  Box,
  type BoxProps,
  type ComponentWithAs,
  forwardRef,
} from '@chakra-ui/react';

import { TableContext, type TableState } from './context';
import * as Part from './parts';
import { tableTheme } from './theme';

type TableParts = {
  Body: typeof Part.Body;
  Cell: typeof Part.Cell;
  Column: typeof Part.Column;
  Header: typeof Part.Header;
  Footer: typeof Part.Footer;
  Row: typeof Part.Row;
  LoadingRow: typeof Part.LoadingRow;
};

export type TableProps = BoxProps;

export const Table = forwardRef<TableProps, 'div'>((props, ref) => {
  const { children, columnGap, gridTemplateColumns, ...rest } = props;

  const tableContext = {
    columnStyles: {
      ...(columnGap && { columnGap }),
      ...(gridTemplateColumns && { gridTemplateColumns }),
    },
    theme: tableTheme.baseStyle,
  } satisfies TableState;

  return (
    <Box className="table" {...tableContext.theme.table} {...rest} ref={ref}>
      <TableContext.Provider value={tableContext}>
        {children}
      </TableContext.Provider>
    </Box>
  );
}) as ComponentWithAs<'div', TableProps> & TableParts;

Table.Body = Part.Body;
Table.Cell = Part.Cell;
Table.Column = Part.Column;
Table.Header = Part.Header;
Table.Footer = Part.Footer;
Table.Row = Part.Row;
Table.LoadingRow = Part.LoadingRow;
