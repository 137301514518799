import { createContext, useContext } from 'react';

interface UseCardGroupReturn {
  isBoxed?: boolean;
  isDisabled?: boolean;
}

const CardGroupContext = createContext<UseCardGroupReturn | null>(null);

export const CardGroupContextProvider = CardGroupContext.Provider;

export function useCardGroup() {
  const context = useContext(CardGroupContext);

  if (!context) {
    // don't throw an error as a Card can be used outside of a CardGroup
    return {};
  }

  return context;
}
