import development from './development';
import prod from './prod';
import staging from './staging';

let config;
switch (import.meta.env.VITE_API_ENV) {
  case 'production':
    config = prod;
    break;
  case 'staging':
    config = staging;
    break;
  default:
    config = development;
}

// Reassigning config to a const variable to avoid exporting a let.
const exportedConfig = config;
export default exportedConfig;
