import styled from 'styled-components';

export const OnboardingSidePanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
`;

export const OnboardingSidePanelBottomWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const OnboardingSidePanelBottomItemsWrapper = styled.div`
  width: 192px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
