import { type FC, useState } from 'react';
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  IntlProvider,
  useIntl,
} from 'react-intl';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  chakra,
  Grid,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Icon, Typography } from '@shinetools/sunshine-universal';

import { type ProviderPlanId } from '__generated__/GQL';
import useCompanyContext from 'common/hooks/useCompanyContext';
import { INSURANCE_2024_NOTICE_URL } from 'common/insurance';
import Button from 'components/_core/Button';
import SunshineCard from 'components/_core/SunshineCard';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';
import Loader from 'components/Loader';
import { SubscriptionManagementLayout } from 'features/SubscriptionManagement/components/SubscriptionManagementLayout';
import { PLANS_CARD_IMAGES } from 'features/SubscriptionManagement/lib/cardImages';
import * as subscriptionManagementRoutes from 'features/SubscriptionManagement/routes';
import { plans } from 'features/SubscriptionManagement/routes';

import { PLANS_METADATA } from '../Plans/plansMetadata';
import { ChangePlanDocument } from './changePlan.gql';
import { SubscriptionManagementPlansDetailDocument } from './planDetails.gql';

export const PlanDetails: FC = () => {
  const companyContext = useCompanyContext();
  const params = useParams<{ providerPlanId: ProviderPlanId }>();
  const history = useHistory();
  const intl = useIntl();

  /**
   * Whether the user has agreed to the insurance terms.
   * Is set to `null` if the plan does not require insurance terms agreement.
   */
  const [hasAgreedToInsuranceTerms, setHasAgreedToInsuranceTerms] = useState<
    boolean | null
  >(
    PLANS_METADATA[params.providerPlanId]?.requiresInsuranceTermsAgreement
      ? false
      : null,
  );

  const query = useQuery(SubscriptionManagementPlansDetailDocument, {
    onError() {
      history.replace(plans);
    },
    variables: {
      companyProfileId: companyContext.companyProfileId!,
      providerPlanId: params.providerPlanId,
    },
  });

  const [changePlan, changePlanMutation] = useMutation(ChangePlanDocument, {});

  if (query.loading || !query.data) {
    return <Loader />;
  }

  const {
    targetPricingPlan,
    viewer: { company },
  } = query.data;

  if (!company.subscriptionPlan) {
    throw new Error('No subscription plan found');
  }

  const isDowngrade = company.currentPlan.downgradablePlans.some(
    ({ providerPlanId }) => providerPlanId === targetPricingPlan.providerPlanId,
  );

  const targetPlanMetadata = PLANS_METADATA[targetPricingPlan.providerPlanId];

  return (
    <IntlProvider
      {...intl}
      defaultRichTextElements={{
        ...intl.defaultRichTextElements,
        subtitle: (chunks) => (
          <Text as="span" fontWeight="weight-400" size="sm" variant="secondary">
            {chunks}
          </Text>
        ),
      }}
    >
      <SimpleGrid columns={2} height="full">
        <SubscriptionManagementLayout
          prevRoute={subscriptionManagementRoutes.plans}
        >
          <VStack align="stretch" spacing="space-40">
            <Box>
              <Typography.Header size="large">
                <FormattedMessage
                  id="subscription.plan_details.title"
                  values={{
                    planName: targetPricingPlan.brandName,
                  }}
                />
              </Typography.Header>
            </Box>

            <Box>
              <Typography.Text size="small" variant="secondary">
                {isDowngrade ? (
                  <FormattedMessage
                    id="subscription.plan_details.downgrade.description"
                    values={{
                      currentPlanEndDate: (
                        <FormattedDate
                          day="numeric"
                          month="long"
                          value={company.subscriptionPlan.nextBillingAt!}
                          year="numeric"
                        />
                      ),
                      currentPlanName: company.currentPlan.brandName,
                      newPlanPrice: (
                        <FormattedNumber
                          currency="EUR"
                          style="currency"
                          value={targetPricingPlan.priceWithoutVAT / 100}
                        />
                      ),
                      newPlanStartPayingDate: (
                        <FormattedDate
                          day="numeric"
                          month="long"
                          value={
                            new Date(company.subscriptionPlan.nextBillingAt!)
                          }
                          year="numeric"
                        />
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="subscription.plan_details.upgrade.description"
                    values={{
                      currentPlanName: company.currentPlan.brandName,
                      newPlanPrice: (
                        <FormattedNumber
                          currency="EUR"
                          minimumFractionDigits={0}
                          style="currency"
                          value={targetPricingPlan.priceWithoutVAT / 100}
                        />
                      ),
                      newPlanStartPayingDate: (
                        <FormattedDate
                          day="numeric"
                          month="long"
                          value={
                            new Date(company.subscriptionPlan.nextBillingAt!)
                          }
                          year="numeric"
                        />
                      ),
                    }}
                  />
                )}
              </Typography.Text>
            </Box>

            {targetPlanMetadata.requiresInsuranceTermsAgreement ? (
              <Box>
                <VStack align="stretch" spacing="space-8">
                  <Typography.Text bold>
                    <FormattedMessage id="subscription.plan_details.insurance_terms.title" />
                  </Typography.Text>

                  <Typography.Text
                    marginBottom="$space.16"
                    size="small"
                    variant="secondary"
                  >
                    <FormattedMessage
                      id="subscription.plan_details.insurance_terms.description"
                      values={{
                        more_info_insurances_link: (content) => (
                          <chakra.a
                            _hover={{
                              color: 'blue.700',
                            }}
                            alignItems="center"
                            color="blue.600"
                            display="inline-flex"
                            gap="space-8"
                            href="https://www.shine.fr/extras-assurances/"
                            target="_blank"
                            textDecoration="underline"
                          >
                            <span>{content}</span>
                            <Icon icon="link" size="small" />
                          </chakra.a>
                        ),
                        planName: targetPricingPlan.brandName,
                      }}
                    />
                  </Typography.Text>
                </VStack>

                <SunshineCard
                  isChecked={hasAgreedToInsuranceTerms ?? false}
                  onChange={(event) => {
                    setHasAgreedToInsuranceTerms(event.target.checked);
                  }}
                  variant="checkbox"
                >
                  <SunshineCard.Content>
                    <Box fontSize="font-13">
                      <FormattedMessage
                        id="subscription.plan_details.insurance_terms.agreement"
                        values={{
                          insurance_terms_pdf: (content) => (
                            <chakra.a
                              _hover={{
                                color: 'blue.700',
                              }}
                              color="blue.600"
                              display="inline"
                              href={INSURANCE_2024_NOTICE_URL}
                              target="_blank"
                              textDecoration="underline"
                            >
                              {content}
                            </chakra.a>
                          ),
                        }}
                      />
                    </Box>
                  </SunshineCard.Content>
                </SunshineCard>
              </Box>
            ) : null}

            <Button
              isDisabled={hasAgreedToInsuranceTerms === false}
              isLoading={changePlanMutation.loading}
              onClick={() =>
                changePlan({
                  variables: {
                    input: {
                      companyProfileId: companyContext.companyProfileId!,
                      providerPlanId: targetPricingPlan.providerPlanId,
                    },
                  },
                }).then(() => {
                  history.push(subscriptionManagementRoutes.root);
                })
              }
            >
              <FormattedMessage
                id="subscription.plan_details.change_plan_cta"
                values={{
                  planName: targetPricingPlan.brandName,
                }}
              />
            </Button>

            <Box>
              <Typography.Text size="tiny" variant="secondary">
                <FormattedMessage id="subscription.plan_details.footer" />
              </Typography.Text>
            </Box>
          </VStack>
        </SubscriptionManagementLayout>

        <Box
          backgroundColor="grey.200"
          paddingX="space-40"
          paddingY={{ base: 'space-40', xl: 'space-64' }}
        >
          <VStack
            alignItems="center"
            flexDirection="column"
            height="full"
            spacing="space-24"
          >
            <SunshineCard.Group maxWidth={530} overflow="hidden">
              <SunshineCard
                backgroundColor="blue.300"
                borderRadius="radius-0"
                paddingX="space-32"
                paddingY="space-24!"
              >
                <Text fontWeight="weight-500" variant="primary">
                  <FormattedMessage id="subscription.plan_details.selected_offer_summary" />
                </Text>
              </SunshineCard>

              <SunshineCard
                backgroundColor="grey.100"
                borderRadius="radius-0"
                overflow="hidden"
                padding="0!"
              >
                <Grid gridTemplateColumns="1fr auto">
                  <Box paddingLeft="space-32" paddingY="space-24">
                    <Heading size="xl">
                      <FormattedMessage
                        id="subscription.plan_details.selected_offer_title"
                        values={{
                          planName: targetPricingPlan.brandName,
                        }}
                      />
                    </Heading>

                    <Text fontWeight="weight-500" variant="primary">
                      <FormattedMessage
                        id="subscription.plan_details.selected_offer_pricing"
                        values={{
                          price: (
                            <FormattedNumber
                              currency="EUR"
                              minimumFractionDigits={
                                targetPricingPlan.priceWithoutVAT % 100 ? 2 : 0
                              }
                              style="currency"
                              value={targetPricingPlan.priceWithoutVAT / 100}
                            />
                          ),
                          trialPeriodLength: 1,
                        }}
                      />
                    </Text>
                  </Box>

                  <Image
                    alignSelf="end"
                    height={80}
                    marginRight="space-8"
                    objectFit="cover"
                    objectPosition="top"
                    src={PLANS_CARD_IMAGES[targetPricingPlan.providerPlanId]}
                    width={160}
                  />
                </Grid>
              </SunshineCard>

              <SunshineCard paddingX="space-32" paddingY="space-24!">
                <VStack align="stretch" spacing="space-32">
                  {targetPlanMetadata.summary.map(({ description, icon }) => {
                    const descriptionNode = description(targetPricingPlan);

                    return (
                      <HStack key={icon} spacing="space-16">
                        <SquircleIcon name={icon} />
                        <Typography.Text bold>
                          {descriptionNode}
                        </Typography.Text>
                      </HStack>
                    );
                  })}
                </VStack>
              </SunshineCard>
            </SunshineCard.Group>
          </VStack>
        </Box>
      </SimpleGrid>
    </IntlProvider>
  );
};
