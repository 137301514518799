import { useState } from 'react';

import { logEvent } from 'features/Analytics/analytics';

type FillingType = 'manual' | 'from list';

/**
 * Hook to isolate the logic tied to tracking the usage of the birth city input.
 * To know if the user fills it by selecting on the list or manually.
 * Since we call the function onBlur, one way to prevent unnecessary analytics
 * events firings it’s to do it only when the fillingType has changed since this
 * is mostly what we care about.
 */
const useTrackBirthCityInputUsage = () => {
  const [fillingType, setFillingType] = useState<FillingType>();

  const trackBirthCityInputUsage = (newFillingType: FillingType) => {
    if (newFillingType !== fillingType) {
      logEvent({
        name: 'Onboarding Birth City Filled',
        properties: {
          fillingType: newFillingType,
          isEnhancedBirthCityInputEnabled: true,
        },
        usePrefix: false,
      });

      setFillingType(newFillingType);
    }
  };

  return trackBirthCityInputUsage;
};

export default useTrackBirthCityInputUsage;
