import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    email: {
      firstItem:
        'You can add a new account to your e-mail address by activating the **multi-account** option. To do this, **find the step-by-step** in our',
      link: 'help center dedicated to multi-accounts.',
      title: 'This e-mail address is already in use',
    },
    phone: {
      firstItem:
        'You can add a new account to your phone number by activating the **multi-account** option. To do this, **find the step-by-step** in our',
      link: 'help center dedicated to multi-accounts.',
      title: 'This phone number is already in use',
    },
  },
  fr: {
    email: {
      firstItem:
        'Vous pouvez ajouter un nouveau compte à votre adresse e-mail en activant l’option **multi-comptes**. Pour ce faire, **retrouvez le pas-à-pas** dans notre',
      link: 'centre d’aide dédié au multi-compte.',
      title:
        'Il semblerait que cette adresse e-mail soit déjà associée à un compte.',
    },
    phone: {
      firstItem:
        'Vous pouvez ajouter un nouveau compte à votre numéro de téléphone en activant l’option **multi-comptes**. Pour ce faire, **retrouvez le pas-à-pas** dans notre',
      link: 'centre d’aide dédié au multi-compte.',
      title:
        'Il semblerait que ce numéro de téléphone soit déjà associé à un compte.',
    },
  },
} as const);

export default locales;
