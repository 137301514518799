import { useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { KYC_DOCUMENTS_TYPES } from '@shinetools/kyc-validation';

import asBentoModule from 'common/bento/hoc/asBentoModule';
import withStateMachine from 'common/bento/hoc/withStateMachine/withStateMachine';
import assertNever from 'common/bento/lib/assertNever';
import stringifyState from 'common/bento/lib/stringifyState';
import { BentoModuleDoneStatus } from 'common/bento/types/BentoModule';
import { apolloClient } from 'common/graphql/clients';
import useCompanyContext from 'common/hooks/useCompanyContext';
import webView from 'common/webView';
import SuspenseQuery from 'components/SuspenseQuery';

import LoaderPage from '../../components/LoaderPage';
import Address from '../Address';
import Identity from '../AdminIdentity';
import { TeamOnboardingVerificationModuleDataDocument } from './graphql/queries/teamOnboardingVerificationModuleData.gql';
import machineConfig, { model, State } from './machine';
import Home from './pages/Home';

interface VerificationProps {
  companyProfileId: string;
}

const VerificationComponent = withStateMachine<typeof model>(
  ({ machine, moduleData }) => {
    const [state, send] = machine;
    const { company, companyUser } = moduleData.verificationData.viewer;

    const stringifiedState = stringifyState<State>(state);

    const onPrev = useCallback(() => send(model.events.PREV()), [send]);

    switch (stringifiedState) {
      case State.HOME:
        return (
          <Home
            isLoading={false}
            moduleData={moduleData}
            onPrev={onPrev}
            send={send}
          />
        );

      case State.ADDRESS:
        return (
          <Address
            companyProfileId={company.companyProfileId}
            onDone={() => send(model.events.NEXT())}
          />
        );

      case State.IDENTITY:
        return (
          <Identity
            companyProfileId={company.companyProfileId}
            companyUserId={companyUser.companyUserId}
            onDone={({ status }) =>
              status === BentoModuleDoneStatus.ABORT
                ? send(model.events.PREV())
                : send(model.events.NEXT())
            }
          />
        );

      case State.INIT:
      case State.DONE:
        return <LoaderPage onPrev={() => null} />;

      default:
        return assertNever(stringifiedState);
    }
  },

  { devTools: true, machineConfig },
);

const Verification = asBentoModule<VerificationProps>(
  ({ companyProfileId, onDone }) => {
    const verificationQuery = useQuery(
      TeamOnboardingVerificationModuleDataDocument,
      {
        notifyOnNetworkStatusChange: true,
        variables: {
          companyProfileId,
          kycDocumentTypes: KYC_DOCUMENTS_TYPES,
        },
      },
    );

    useEffect(() => {
      return webView.listenNativeMessage((message) => {
        if (message.action === 'NATIVE_ID_CHECK_COMPLETED') {
          apolloClient.refetchQueries({
            include: [TeamOnboardingVerificationModuleDataDocument],
          });
        }
      });
    }, []);

    const companyContext = useCompanyContext();

    return (
      <SuspenseQuery
        loader={<LoaderPage onPrev={() => null} />}
        query={verificationQuery}
      >
        {(data) => (
          <VerificationComponent
            moduleData={{
              bootstrap: null,
              companyContext,
              verificationData: data,
            }}
            onDone={onDone}
          />
        )}
      </SuspenseQuery>
    );
  },
);

export default Verification;
