import config from 'config';

const visitorContextCookieName = 'shineVisitorContext';
const openAccountExitPageCookieName = 'shineOpenAccountExitPage';
const visitorContextCookieRegex = new RegExp(
  `(^| )${visitorContextCookieName}=([^;]+)`,
);
const openAccountExitPageCookieRegex = new RegExp(
  `(^| )${openAccountExitPageCookieName}=([^;]+)`,
);

const getVisitorContextAffiliateInfo = () => {
  const match = document.cookie.match(visitorContextCookieRegex);
  if (match === null || match.length <= 2) {
    return { affiliateLandingHref: undefined, affiliateSource: undefined };
  }
  // @ts-expect-error match[2] is possibly 'undefined'.
  const cookieContent = JSON.parse(decodeURIComponent(match[2]));
  const { affiliateLandingHref, affiliateSource } = cookieContent;
  return { affiliateLandingHref, affiliateSource };
};

const getOpenAccountExitPage = () => {
  const match = document.cookie.match(openAccountExitPageCookieRegex);
  if (match === null || match.length <= 2) {
    return undefined;
  }
  // @ts-expect-error match[2] is possibly 'undefined'.
  return JSON.parse(decodeURIComponent(match[2]));
};

type SendSignupZapierHook = (args: { phone: string }) => Promise<void>;

const sendSignupZapierHook: SendSignupZapierHook = async ({ phone }) => {
  // Load the affiliate source from the visitor context cookie (if it exists)
  const { affiliateLandingHref: href, affiliateSource } =
    getVisitorContextAffiliateInfo();
  const source = getOpenAccountExitPage();
  await fetch(config.signupZapierHook, {
    body: JSON.stringify({
      affiliateSource,
      href,
      phone,
      source,
    }),
    method: 'POST',
  });
};

export default sendSignupZapierHook;
