import LocalizedStrings from 'localized-strings';

import { CompanyInvitationRole } from '__generated__/GQL';

export default new LocalizedStrings({
  en: {
    headingByRole: {
      [CompanyInvitationRole.Accountant]:
        'Your client invites you to access their transactions and receipts through your own dedicated accountant access',
      [CompanyInvitationRole.Employee]: '3 steps to be efficient',
      [CompanyInvitationRole.LegalBeneficiary]: '',
      [CompanyInvitationRole.AccountHolder]: '',
      /**
       * @todo remove once we updated the screens with the new wordings when implementing the new flow for admin
       * @see https://linear.app/shine/issue/CPA-1294
       */
      [CompanyInvitationRole.Admin]: '',
    },
    screenshotHeading: 'Your business account made easy',
  },
  fr: {
    headingByRole: {
      [CompanyInvitationRole.Accountant]:
        'Votre client·e vous invite à accéder à ses transactions et justificatifs grâce à un accès comptable dédié',
      [CompanyInvitationRole.Employee]: '3 étapes simples pour être efficace',
      [CompanyInvitationRole.LegalBeneficiary]: '',
      [CompanyInvitationRole.AccountHolder]: '',
      /**
       * @todo remove once we updated the screens with the new wordings when implementing the new flow for admin
       * @see https://linear.app/shine/issue/CPA-1294
       */
      [CompanyInvitationRole.Admin]: '',
    },
    screenshotHeading:
      'Gérer votre compte pro ne\u00a0devrait pas être un effort',
  },
} as const);
