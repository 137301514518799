import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    didNotMatchError:
      'The passcode does not match the one  you indicated in the previous step.',
    label: 'Choose a 4-digit combination',
    title: 'Create your passcode to access your account',
  },

  fr: {
    didNotMatchError:
      'Le code secret ne correspond pas à celui que vous avez indiqué à l’étape précédente.',
    label: 'Choisissez une combinaison de 4 chiffres',
    title: 'Créez votre code personnel d’accès à votre compte',
  },
} as const);

export default locales;
