import { countries } from '@shinetools/geo-library';

export default countries as Record<
  string,
  {
    callingCode: string;
    emoji: string;
    flag: string;
    name: {
      fr: string;
      en: string;
    };
  }
>;
