import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    beneficiary: {
      description: `Complete the opening of your company's account`,
      label: 'Your business partner invited you',
    },
    capDep: {
      description:
        'You have already started the process of creating your company',
      label: 'You want to deposit your capital',
    },
    companyCreation: {
      description: 'Create your SASU, SAS, SARL, auto-entreprise...',
      label: 'You want to create your company',
    },
    inviteeQuestion: 'Are you a beneficial owner?',
    login: {
      label: 'Already have an account?',
      link: 'Sign in',
    },
    page: {
      title: 'What is your current situation?',
    },
    standard: {
      description: 'You are a company owner, freelance, self-employed...',
      label: 'You already have a SIRET number',
    },
  },
  fr: {
    beneficiary: {
      description: 'Compléter l’ouverture du compte pour votre entreprise',
      label: 'Votre associé·e vous a invité·e',
    },
    capDep: {
      description:
        'Vous avez déjà commencé les démarches pour créer votre société',
      label: 'Vous voulez déposer votre capital',
    },
    companyCreation: {
      description: 'Créer votre SASU, SAS, SARL, auto-entreprise...',
      label: 'Vous souhaitez lancer votre activité\u00a0?',
    },
    inviteeQuestion: 'Vous êtes bénéficiaire effectif ?',
    login: {
      label: 'Vous avez un compte\u00a0?',
      link: 'Connectez-vous',
    },
    page: {
      title: 'Quelle est votre situation actuelle\u00a0?',
    },
    standard: {
      description:
        'Vous êtes dirigeant∙e d’entreprise, indépendant·e, en libéral...',
      label: 'Vous avez déjà un numéro de SIRET',
    },
  },
} as const);

export default locales;
