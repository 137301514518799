import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description:
      'Schedule automatic exports easily to save time. A one-off requirement? Then just create a customized export for a selected period.',
    title: 'Free your mind by automating exports',
  },
  fr: {
    description:
      'Programmez simplement des exports automatiques complets pour gagner du temps. Un besoin ponctuel\xa0? Générez facilement un export personnalisé pour la période de votre choix.',
    title: 'Libérez-vous l’esprit en automatisant les exports',
  },
} as const);

export default locales;
