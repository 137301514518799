import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    delay: 'This takes 48h on average',
    nextText:
      'The account owner will be notified once your application has been accepted.',
    title: 'You’re all set!\nWe’re reviewing your account',
  },
  fr: {
    delay: 'Cela prend 48 heures en moyenne',
    nextText:
      'Nous notifierons le ou la propriétaire du compte lorsque votre demande sera acceptée.',
    title: 'C’est parti\u00A0!\nNous vérifions votre compte',
  },
} as const);
