import { type ColorName } from '@shinetools/sunshine';
import { type IconName } from '@shinetools/sunshine-icons';

import { type DetailedTransactionFragment as Transaction } from './Sidebar/graphql/fragment/detailedTransaction.gql';

const getPaymentMethodIconName = ({
  paymentMethod,
  type,
}: {
  paymentMethod: Transaction['paymentMethod'];
  type: Transaction['type'];
}) => {
  const mapping: Record<
    Transaction['paymentMethod'],
    Record<Transaction['type'], IconName>
  > = {
    CARD: {
      payin: 'card-recto',
      payout: 'card-recto',
    },
    CASH_DEPOSIT: {
      payin: 'cash',
      payout: 'cash',
    },
    CHECK: {
      payin: 'check',
      payout: 'check',
    },
    DIRECT_DEBIT: {
      payin: 'transfer',
      payout: 'transfer',
    },
    INSTANT_TRANSFER: {
      payin: 'transfer',
      payout: 'transfer',
    },
    INTERNATIONAL_TRANSFER: {
      payin: 'transaction-in',
      payout: 'transaction-out',
    },
    INTRA_COMPANY_FUNDS_TRANSFER: {
      payin: 'transaction-in',
      payout: 'transaction-out',
    },
    SWIFT_TRANSFER: {
      payin: 'transaction-in',
      payout: 'transaction-out',
    },
    TRANSFER: {
      payin: 'transaction-in',
      payout: 'transaction-out',
    },
    WALLET_TRANSFER: {
      payin: 'wallet',
      payout: 'wallet',
    },
  };

  return mapping[paymentMethod][type] as IconName;
};

type MapTransactionToRebrandedCategoryIconPropsParams = {
  transaction: Pick<
    Transaction,
    'category' | 'paymentMethod' | 'type' | 'status'
  >;
  isMergedTransaction?: boolean;
};

const mapTransactionToRebrandedCategoryIconProps = ({
  isMergedTransaction,
  transaction,
}: MapTransactionToRebrandedCategoryIconPropsParams): {
  name: IconName;
  color: ColorName;
} => {
  const { category, paymentMethod, status, type } = transaction;

  if (isMergedTransaction) {
    return { color: 'grey', name: 'payment' };
  }

  if (status === 'CANCELED') {
    return { color: 'red', name: 'cancel' };
  }

  switch (category) {
    case 'BANKING':
      return { color: 'blue', name: 'insurance' };
    case 'CASH':
      return { color: 'grey', name: 'cash' };
    case 'CONTRIBUTIONS':
      return { color: 'blue', name: 'contribution' };
    case 'EQUIPMENT':
      return { color: 'blue', name: 'equipment' };
    case 'FOOD':
      return { color: 'yellow', name: 'food' };
    case 'HOTEL':
      return { color: 'orange', name: 'hotel' };
    case 'LEGAL':
      return { color: 'orange', name: 'legal' };
    case 'LOAN':
      return { color: 'orange', name: 'loan' };
    case 'MAINTENANCE':
      return { color: 'green', name: 'maintenance' };
    case 'MISC':
      return { color: 'blue', name: 'misc' };
    case 'PERSONAL':
      return { color: 'yellow', name: 'personal' };
    case 'RENT':
      return { color: 'orange', name: 'rent' };
    case 'SERVICES':
      return { color: 'green', name: 'services' };
    case 'TAX':
      return { color: 'orange', name: 'tax' };
    case 'TELECOM':
      return { color: 'blue', name: 'phone' };
    case 'TRANSPORTATION':
      return { color: 'green', name: 'transportation' };
    case 'UTILITIES':
      return { color: 'blue', name: 'utilities' };
    case 'VEHICLE':
      return { color: 'orange', name: 'vehicle' };
    default:
      return {
        color: transaction.type === 'payin' ? 'green' : 'grey',
        name: getPaymentMethodIconName({
          paymentMethod: paymentMethod,
          type: type,
        }) as IconName,
      };
  }
};

export default mapTransactionToRebrandedCategoryIconProps;
