import { type FC, type PropsWithChildren } from 'react';
import { Typography } from '@shinetools/sunshine-universal';

export type DescriptionProps = PropsWithChildren;

export const Description: FC<DescriptionProps> = (props) => {
  return (
    <Typography.Text className="quota__usage" variant="primary">
      {props.children}
    </Typography.Text>
  );
};
