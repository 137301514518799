/* eslint-disable @typescript-eslint/no-explicit-any */
import { append, findIndex, mergeDeepRight, reduce, update } from 'ramda';

const mergeBy = (
  predicate: (a: any, b: any) => boolean,
  left: any[],
  right: any[],
): any =>
  reduce(
    (acc, obj): any => {
      const index = findIndex((item): boolean => predicate(item, obj), acc);
      if (index > -1) {
        return update(index, mergeDeepRight(acc[index], obj), acc);
      }
      return append(obj, acc);
    },
    left,
    right,
  );

export default mergeBy;
