import { type FC } from 'react';
import {
  SkeletonText as ChakraSkeletonText,
  type SkeletonTextProps,
} from '@chakra-ui/react';

const SkeletonText: FC<SkeletonTextProps> = (props) => (
  <ChakraSkeletonText
    endColor="grey.200"
    noOfLines={props.noOfLines ?? 1}
    skeletonHeight="space-16"
    startColor="grey.200"
    {...props}
  />
);

export default SkeletonText;
