import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    lastFeatureCta: 'Schedule an export',
    subtitle: 'Manage your accounting for {companyName} independently',
    title: 'Welcome {firstName} 🎉',
  },
  fr: {
    lastFeatureCta: 'Programmer un export',
    subtitle: 'Gérez la comptabilité de {companyName} en toute autonomie',
    title: 'Bienvenue {firstName} 🎉',
  },
} as const);

export default locales;
