import actions from './actions';
import guards from './guards';
import services from './services';

const shared = {
  actions,
  guards,
  services,
};

export default shared;
