/* eslint-disable sort-keys-fix/sort-keys-fix */

/**
 * Coming from the Fourthline docs
 * @see https://github.com/Fourthline-com/Fourthline-React-Native#identity-verification
 */
export const FOURTHLINE_ERRORS = {
  800: 'Decoding Error', // Workflow input decoding error. More details provided in `errorDescription`.
  802: 'Invalid or missing font', // Provided font could not be found. Details provided in `errorDescription`.
  803: 'User canceled', // User explicitly canceled Workflow.
  830: 'Json parse error', // Could not parse the provided json.
  850: 'Incorrect Configuration', // Orca Workflow was not configured correctly.
  870: 'Unexpected + message', // Unexpected error occurred. Please report this issue immediately.
  1000: 'ClientRejected', // The client is not eligible for the Workflow flow. A new Kyc cannot be triggered.
  1001: 'InvalidValidationCode', // Provided validation code is invalid.
  1002: 'Module error + message', // User encountered an error in one of the workflow modules.
  1003: 'ConfigurationNotSupported + message', // The Workflow created using provided validation code is not supported. Consider updating the plugin to the latest version.
  1004: 'InvalidWorkflowStatus', // The status of the workflow is not allowed. The modules could already be completed successfully or finished with an error.
} as const;

export type FourthlineErrorCode = keyof typeof FOURTHLINE_ERRORS;

export type FourthlineError = {
  errorCode: FourthlineErrorCode;
  errorDescription: string;
};
