import { createContext, useContext } from 'react';
import { type SystemStyleObject } from '@chakra-ui/react';

import { tableTheme } from './theme';

/**
 * Table state context
 *
 * In order to ease the styling of the Table columns (`width`, `spacing`, etc)
 * we are using a context that shares the column styles and the table state with
 * the children components.
 */
export type TableState = {
  columnStyles?: Partial<{
    gridTemplateColumns: SystemStyleObject['gridTemplateColumns'];
    columnGap: SystemStyleObject['columnGap'];
  }>;
  theme: typeof tableTheme.baseStyle;
};

export const TableContext = createContext<TableState>({
  theme: tableTheme.baseStyle,
});
export const useTable = () => useContext(TableContext);
