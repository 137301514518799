import * as Types from '../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type NewAuthenticationDeviceRequestSubscriptionVariables = Types.Exact<{
  phone: Types.Scalars['ID']['input'];
}>;


export type NewAuthenticationDeviceRequestSubscription = { __typename: 'Subscription', newAuthenticationDeviceRequest: { __typename: 'AuthenticationDeviceRequest', phone: string, authenticationDeviceId: string, authenticationDeviceRequestId: string, status: Types.AuthenticationDeviceRequestStatus, updatedAt: string } };


export const NewAuthenticationDeviceRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"newAuthenticationDeviceRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phone"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"newAuthenticationDeviceRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"phone"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phone"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"authenticationDeviceId"}},{"kind":"Field","name":{"kind":"Name","value":"authenticationDeviceRequestId"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]} as unknown as DocumentNode<NewAuthenticationDeviceRequestSubscription, NewAuthenticationDeviceRequestSubscriptionVariables>;