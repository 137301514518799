import * as Sentry from '@sentry/react';

export const setSentryUser = (uid: string) => {
  Sentry.setUser({
    id: uid,
  });

  Sentry.setTag('uid', uid);
};

export const clearSentryUser = () => {
  Sentry.setUser(null);
  Sentry.setTag('uid', null);
};
