import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    identity: {
      genericError:
        'An error occured during the analysis of your identity document.\nPlease restart the verification process.',
    },
    legalTitleFemale: 'Mrs.',
    legalTitleMale: 'Mr.',
  },
  fr: {
    identity: {
      genericError:
        'Une erreur est survenue lors de l’analyse de votre document d’identité.\nVeuillez recommencer la vérification.',
    },
    legalTitleFemale: 'Mme',
    legalTitleMale: 'M.',
  },
} as const);

export default locales;
