import * as Types from '../../../../../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CompanyUserSubscriptionVariables = Types.Exact<{
  companyUserId: Types.Scalars['ID']['input'];
}>;


export type CompanyUserSubscription = { __typename: 'Subscription', updatedCompanyUser: { __typename: 'CompanyUser', companyUserId: string, kycStatus: Types.CompanyUserKycStatus } };


export const CompanyUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"companyUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyUserId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatedCompanyUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyUserId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyUserId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyUserId"}},{"kind":"Field","name":{"kind":"Name","value":"kycStatus"}}]}}]}}]} as unknown as DocumentNode<CompanyUserSubscription, CompanyUserSubscriptionVariables>;