import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    content:
      'It will take us a little more time to check your documents. You can now leave this page. We will email you once the check is complete.',
    title: 'We will contact you in a few hours',
  },
  fr: {
    content:
      'Il nous faut un peu plus de temps pour vérifier vos documents. Vous pouvez maintenant quitter cette page, nous vous préviendrons par e-mail une fois la vérification terminée.',
    title: 'Nous vous contacterons sous quelques heures',
  },
} as const);

export default locales;
