import * as Types from '../../../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type VerificationIdUpdateIdentityMutationVariables = Types.Exact<{
  input: Types.UserProfileInput;
}>;


export type VerificationIdUpdateIdentityMutation = { __typename: 'Mutation', updateKycInformation: { __typename: 'KycInformationPayload', profile: { __typename: 'UserProfile', uid: string, firstName: string, lastName: string, gender: string, birthdate: string, birthCity: string | null, birthDepartment: string | null, birthCountry: string | null, nationality: string | null } | null } };


export const VerificationIdUpdateIdentityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"verificationIdUpdateIdentity"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserProfileInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateKycInformation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"gender"}},{"kind":"Field","name":{"kind":"Name","value":"birthdate"}},{"kind":"Field","name":{"kind":"Name","value":"birthCity"}},{"kind":"Field","name":{"kind":"Name","value":"birthDepartment"}},{"kind":"Field","name":{"kind":"Name","value":"birthCountry"}},{"kind":"Field","name":{"kind":"Name","value":"nationality"}}]}}]}}]}}]} as unknown as DocumentNode<VerificationIdUpdateIdentityMutation, VerificationIdUpdateIdentityMutationVariables>;