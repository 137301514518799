import { ApolloError } from '@apollo/client';
import { ERROR_CODES } from '@shinetools/errors';

import { OBErrorCode } from './OBErrorCode';

/**
 * Is an error registered in our local errors
 */
export const hasOBErrorCode = (
  error: Error,
): error is Error & { message: OBErrorCode } => error.message in OBErrorCode;

/**
 * Is an ApolloError
 */
const isApolloError = (error: Error): error is ApolloError =>
  error instanceof ApolloError;

/**
 * Is an Apollo network error
 */
export const isNetworkError = (error: Error) =>
  isApolloError(error) && Boolean(error.networkError);

export const getApolloErrorCode = (error: ApolloError): string | undefined => {
  const extensions = error.graphQLErrors[0]?.extensions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (extensions?.exception as any).code || extensions?.code;
};

/**
 * Is an Apollo error that we have a message for
 */
export const isServerError = (error: Error): error is ApolloError => {
  if (!isApolloError(error)) {
    return false;
  }

  const code = getApolloErrorCode(error);

  if (!code) {
    return false;
  }

  return code in ERROR_CODES;
};
