import { z } from 'zod';

/**
 * This schema parses any `localStorage` entry that's supposed to be an object.
 * When encountering an invalid value (eg. an invalid stringified object), it will default to `null`.
 * Use it by piping it into a specific `zod` validation schema.
 */
const localStorageObjectSchema = z
  .string()
  .nullable()
  .transform<unknown>((data) => {
    if (!data) {
      return null;
    }

    try {
      return JSON.parse(data);
    } catch {
      return null;
    }
  });

export default localStorageObjectSchema;
