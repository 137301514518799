import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    step: 'Step',
    steps: {
      accountCreation: 'Creation of your access',
      identityCheck: 'Identity check',
    },
  },
  fr: {
    step: 'Étape',
    steps: {
      accountCreation: 'Création de votre accès',
      identityCheck: 'Vérification d’identité',
    },
  },
} as const);

export default locales;
