import { type FC } from 'react';
import { Flex, type FlexProps } from '@chakra-ui/react';

export type ContainerProps = FlexProps;

export const Container: FC<ContainerProps> = (props) => (
  <Flex
    border="1px solid"
    borderColor="grey.300"
    borderRadius="radius-8"
    direction="column"
    {...props}
  />
);
