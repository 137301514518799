import { useCallback } from 'react';

import { CompanyInvitationRole } from '__generated__/GQL';
import asBentoContainer from 'common/bento/hoc/asBentoContainer';
import LoaderPage from 'common/bento/pages/LoaderPage';

import Admin from './flows/admin';
import EmployeeOrAccountant from './flows/employeeOrAccountant';
import DesktopLayout from './layout/Desktop';
import MobileLayout from './layout/Mobile';

type TeamOnboardingProps = {
  companyProfileId?: string;
  role?: CompanyInvitationRole | null;
};

const TeamOnboarding = asBentoContainer<TeamOnboardingProps>(
  ({ companyProfileId, role }) => {
    const exitFlow = useCallback(() => {
      window.location.href = '/';
    }, []);

    if (!role) {
      return <LoaderPage onPrev={exitFlow} />;
    }

    switch (role) {
      case CompanyInvitationRole.Accountant:
      case CompanyInvitationRole.Employee:
        return <EmployeeOrAccountant onDone={exitFlow} />;

      case CompanyInvitationRole.Admin:
        if (!companyProfileId) {
          return <LoaderPage onPrev={exitFlow} />;
        }
        return <Admin companyProfileId={companyProfileId} onDone={exitFlow} />;

      default:
        throw new Error('NOPE');
    }
  },
  {
    layouts: {
      base: MobileLayout,
      md: DesktopLayout,
    },
  },
);

export default TeamOnboarding;
