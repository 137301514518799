import * as Types from '../../../../../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TrustedDevicesQueryVariables = Types.Exact<{
  status: Types.AuthenticationDeviceStatus;
}>;


export type TrustedDevicesQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', uid: string, authenticationDevices: Array<{ __typename: 'AuthenticationDevice', authenticationDeviceId: string, phone: string, lastConnectedAt: string | null, lastConnectionAttemptedAt: string | null, model: string | null, name: string | null, platform: Types.AuthenticationDevicePlatform | null, status: Types.AuthenticationDeviceStatus, token: string }> } };


export const TrustedDevicesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"trustedDevices"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"status"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AuthenticationDeviceStatus"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"authenticationDevices"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"Variable","name":{"kind":"Name","value":"status"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authenticationDeviceId"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"lastConnectedAt"}},{"kind":"Field","name":{"kind":"Name","value":"lastConnectionAttemptedAt"}},{"kind":"Field","name":{"kind":"Name","value":"model"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"platform"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"token"}}]}}]}}]}}]} as unknown as DocumentNode<TrustedDevicesQuery, TrustedDevicesQueryVariables>;