import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description:
      'Spend with a free mind: select a virtual or physical card according to your expenses type. An admin then sets the limits for each of your cards.',
    tab: 'Credit cards',
    title: 'Request your own card today from the tab\xa0',
  },
  fr: {
    description:
      'Dépensez l’esprit libre : choisissez une carte virtuelle ou physique selon la nature de vos dépenses. Un admin du compte définit ensuite un plafond pour chacune de vos cartes.',
    tab: 'Mes cartes bancaires',
    title: 'Demandez votre propre carte dès aujourd’hui depuis l’onglet\xa0',
  },
} as const);

export default locales;
