import styled from 'styled-components';

import BaseLogo from 'components/Logo';

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  max-width: 384px;
  height: 100%;
`;

export const Logo = styled(BaseLogo)`
  margin: 50px auto;
`;

export const Image = styled.img`
  margin-top: auto;
  justify-self: flex-end;
`;

export const FullscreenContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: ${({ theme }) => theme.spacings.abs32}px;
`;
