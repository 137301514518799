import * as Types from '../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AuthenticationDeviceRequestsQueryVariables = Types.Exact<{
  status: Types.InputMaybe<Types.AuthenticationDeviceRequestStatus>;
}>;


export type AuthenticationDeviceRequestsQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', uid: string, authenticationDeviceRequests: { __typename: 'AuthenticationDeviceRequestConnection', edges: Array<{ __typename: 'AuthenticationDeviceRequestEdge', node: { __typename: 'AuthenticationDeviceRequest', phone: string, authenticationDeviceId: string, authenticationDeviceRequestId: string, status: Types.AuthenticationDeviceRequestStatus, updatedAt: string } }> }, profile: { __typename: 'UserProfile', uid: string, phone: string | null } } };


export const AuthenticationDeviceRequestsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"authenticationDeviceRequests"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"status"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"AuthenticationDeviceRequestStatus"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"authenticationDeviceRequests"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"Variable","name":{"kind":"Name","value":"status"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"edges"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"authenticationDeviceId"}},{"kind":"Field","name":{"kind":"Name","value":"authenticationDeviceRequestId"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}}]}}]}}]}}]} as unknown as DocumentNode<AuthenticationDeviceRequestsQuery, AuthenticationDeviceRequestsQueryVariables>;