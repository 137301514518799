import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    button: 'Access my account',
    description:
      'Your phone number is already associated with an account in this company. As a reminder, you can only connect to an existing account via a unique phone number.',
    title: 'Your phone number is already in use',
  },
  fr: {
    button: 'Accéder à mon compte',
    description:
      "Votre numéro de téléphone est déjà associé à un compte dans cette société. Pour rappel, vous ne pouvez vous connecter à un compte existant qu'avec un numéro de téléphone unique.",
    title: 'Votre numéro est déjà utilisé',
  },
} as const);

export default locales;
