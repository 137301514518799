import { identify as segmentIdentify } from 'features/Analytics/analytics';
import { getUser } from 'helpers/auth';
import logger from 'helpers/logger';

/**
 * This function takes user traits as input, and returns a XState action that, when executed,
 * calls segment.identify on the user with these traits.
 * If the user is not logged in, it will make an anonymous identify call
 * (Segment will automatically tie an Anonymous ID if a User ID is not provided,
 *  see https://segment.com/docs/connections/spec/identify/#anonymous-id).
 */
const identify = (
  traits: Record<string, string | number | boolean | null | undefined>,
) => ({
  exec: () => {
    const user = getUser();

    segmentIdentify(user?.uid, traits);

    logger.info('ℹ️ SEGMENT IDENTIFY: ', traits);
  },
  type: 'identify',
});

export default identify;
