import LocalizedStrings from 'localized-strings';

import { CompanyCreationLegalForm } from 'features/Bento/flows/signup/machine/model';

const locales = new LocalizedStrings({
  en: {
    legalForm: {
      [CompanyCreationLegalForm.SAS]: 'SAS',
      [CompanyCreationLegalForm.SASU]: 'SASU',
      [CompanyCreationLegalForm.SARL]: 'SARL',
      [CompanyCreationLegalForm.EURL]: 'EURL',
      [CompanyCreationLegalForm.SCI]: 'SCI',
      [CompanyCreationLegalForm.UNKNOWN]: "I don't know",
    },
    submit: 'Continue',
    title: 'Choose your company legal form',
  },

  fr: {
    legalForm: {
      [CompanyCreationLegalForm.SAS]: 'SAS',
      [CompanyCreationLegalForm.SASU]: 'SASU',
      [CompanyCreationLegalForm.SARL]: 'SARL',
      [CompanyCreationLegalForm.EURL]: 'EURL',
      [CompanyCreationLegalForm.SCI]: 'SCI',
      [CompanyCreationLegalForm.UNKNOWN]: 'Je ne sais pas',
    },
    submit: 'Valider',
    title: 'Choisissez votre type de société',
  },
} as const);

export default locales;
