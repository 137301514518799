// While all environments can return the same default value,
// they can all import this value.
// TODO(matt): Remove this shared file once the actual defaults change
// from production to staging.
const sharedRemoteConfigDefaults = {
  shouldEnableMultiKycFlow: false,
  shouldEnableNewOnboardingFlow: false,
};

export default sharedRemoteConfigDefaults;
