import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    button: 'Got it',
    description:
      '**Scammers will use Shine’s number to call you.** They will ask you to enter confidential information or validate transactions remotely. If you suspect fraud on your account, temporarily block your cards using the application.',
    helpButton: 'Contact your Shine advisor',
    title: 'Fake phone advisor scam campaign',
  },
  fr: {
    button: 'J’ai compris',
    description:
      '**Des arnaqueurs affichent le numéro de Shine pour vous appeler.** Ils vous demanderont de saisir des informations confidentielles ou de valider des opérations à distance. En cas de fraude suspectée sur votre compte, bloquez temporairement vos cartes depuis l’application.',
    helpButton: 'Contacter votre conseiller Shine',
    title: 'Campagne d’arnaque au faux conseiller téléphonique',
  },
} as const);

export default locales;
