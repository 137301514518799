export const getAddressFromPlace = (place: google.maps.places.PlaceResult) => {
  let city = '';
  let zip = '';
  let streetElements: string[] = [];

  place.address_components?.forEach((component) => {
    if (component.types.includes('street_number')) {
      streetElements = [component.long_name, ...streetElements];
    } else if (component.types.includes('route')) {
      streetElements = [...streetElements, component.long_name];
    } else if (component.types.includes('locality')) {
      city = component.long_name;
    } else if (component.types.includes('postal_code')) {
      zip = component.long_name;
    }
  });

  return {
    city,
    street: streetElements.join(' '),
    zip,
  };
};
