import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    accessMyAccount: 'Access my account',
    description: 'You can now access your account from this device:',
    title: 'Login accepted',
    unknownDevice: 'Unknown device',
    unknownDeviceType: 'Unknown device type',
  },
  fr: {
    accessMyAccount: 'Accéder à mon compte',
    description:
      'Vous pouvez à présent accéder à votre compte depuis cet appareil\xa0:',
    title: 'Connexion acceptée',
    unknownDevice: 'Appareil inconnu',
    unknownDeviceType: 'Type d’appareil inconnu',
  },
} as const);

export default locales;
