const transitionProperty = {
  background: 'background-color, background-image, background-position',
  colors: 'background-color, border-color, color, fill, stroke',
  common:
    'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
  dimensions: 'width, height',
  position: 'left, right, top, bottom',
};

const transitionTimingFunction = {
  'ease-in': 'cubic-bezier(0.4, 0, 1, 1)',
  'ease-in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
  'ease-out': 'cubic-bezier(0, 0, 0.2, 1)',
};

/* eslint-disable sort-keys-fix/sort-keys-fix */

const transitionDuration = {
  'duration-50': '50ms',
  'duration-100': '100ms',
  'duration-150': '150ms',
  'duration-200': '200ms',
  'duration-300': '300ms',
  'duration-400': '400ms',
  'duration-500': '500ms',
};

/* eslint-enable sort-keys-fix/sort-keys-fix */

const transition = {
  duration: transitionDuration,
  easing: transitionTimingFunction,
  property: transitionProperty,
};

export default transition;
