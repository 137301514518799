import { Image } from '@chakra-ui/react';

import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';

import Mail from './assets/Mail@2x.png';
import locales from './locales';

const InviteeFollowEmailLinkCard = () => (
  <>
    <Image boxSize="180px" marginBottom="space-24" marginX="auto" src={Mail} />

    <Heading marginBottom="space-24" size="lg">
      {locales.title}
    </Heading>

    <Text marginBottom="space-16">{locales.firstPart}</Text>

    <Text>{locales.secondPart}</Text>
  </>
);

export default InviteeFollowEmailLinkCard;
