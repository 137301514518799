import styled from 'styled-components';

import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';
import ButtonBadge from 'components/ButtonBadge';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Heading)`
  text-align: center;
  margin: ${({ theme }) => theme.spacings.abs64}px 0
    ${({ theme }) => theme.spacings.abs16}px 0;
`;

export const Description = styled(Text)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacings.abs64}px;
`;

export const BackButton = styled(ButtonBadge).attrs({
  icon: 'arrow-left',
})`
  flex: 0;
  min-height: 34px;
  align-self: flex-start;
`;

export const PasscodeInputContainer = styled.div`
  align-self: center;
`;
