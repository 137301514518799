import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings<{
  physicalCard: string;
  virtualBudgetCard: string;
  virtualCard: string;
}>({
  en: {
    physicalCard: 'Physical card',
    virtualBudgetCard: 'Virtual budget card',
    virtualCard: 'Virtual card',
  },
  fr: {
    physicalCard: 'Carte physique',
    virtualBudgetCard: 'Carte virtuelle à budget',
    virtualCard: 'Carte virtuelle',
  },
} as const);

export default locales;
