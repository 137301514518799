import ReactDOM from 'react-dom';
import { initializeApp } from 'firebase/app';
import { loadWithinAppLayoutRoutesChunk } from 'Main/SecuredRoutes/WithinAppLayoutRoutes';

import config from 'config';
import { initializeFetchAndActivate } from 'common/remoteConfig';
import { initSentry } from 'common/sentry';
import { webAppOpeningTransaction } from 'common/sentry/webAppOpeningTransaction';
import webView from 'common/webView';
import { loadOnboardingChunk } from 'features/Onboarding';
import logger from 'helpers/logger';

import Main from './Main';

initializeApp(config.firebase);

try {
  initializeFetchAndActivate();
} catch (e) {
  // Only log the error at the moment - don't let this block the continued
  // loading of the app.
  // If this has not triggered the initialize part of the remote config set up
  // it may lead to harder to avoid errors later on.
  // Failures seen seem related to this issue:
  // https://github.com/firebase/firebase-js-sdk/issues/2373
  // TODO(matt): Switch this back to failing fully when there are no issues
  // when running in Firefox Private Mode
  logger.error(e);
}

initSentry();

webAppOpeningTransaction.start();

/**
 * We preload the two main chunks of the web-app here.
 *
 * We start preloading:
 * - AFTER the main chunks (entry and vendors) are fully loaded (not competing for bandwidth)
 * - as soon as possible afterwards (to load them in the background, while we start mounting the React tree)
 *
 * Technically, only one will be necessary, but in most cases both will have time to load anyways.
 */
if (!webView.isInWebView) {
  const preloadCustomerChunkSpan = webAppOpeningTransaction.addSpan({
    name: 'Preload the Customer chunk',
    op: 'bundle',
  });

  loadWithinAppLayoutRoutesChunk().finally(() => {
    preloadCustomerChunkSpan?.finish();
  });
}

const preloadOnboardingChunkSpan = webAppOpeningTransaction.addSpan({
  name: 'Preload the Onboarding chunk',
  op: 'bundle',
});

loadOnboardingChunk().finally(() => {
  preloadOnboardingChunkSpan?.finish();
});

const root = document.getElementById('root') as HTMLElement;

const STORAGE_KEY_RETRIED = 'bundle-load-retried';

// refresh page once if vite fails to load dynamic imports
window.addEventListener('vite:preloadError', (event: Event) => {
  event.preventDefault();

  if (window.sessionStorage.getItem(STORAGE_KEY_RETRIED)) {
    return;
  }

  window.sessionStorage.setItem(STORAGE_KEY_RETRIED, 'true');
  window.location.reload();
});

/**
 * !!! WARNING !!!
 * We still use the deprecated `render` method to create the React tree,
 * because our code isn't ready for the Concurrent Renderer at the time of writing
 */

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<Main />, root);
