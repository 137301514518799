import * as Types from '../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RootQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RootQuery = { __typename: 'Query', viewer: { __typename: 'Viewer', uid: string, companies: Array<{ __typename: 'Company', companyProfileId: string, profile: { __typename: 'CompanyProfile', companyProfileId: string, legalName: string, legalForm: string }, metadata: { __typename: 'CompanyMetadata', companyProfileId: string, accountStatus: Types.CompanyMetadataAccountStatus | null } }>, companyUsers: Array<{ __typename: 'CompanyUser', roles: Array<Types.Role>, companyUserId: string, companyProfileId: string, isPrimaryApplicant: boolean, status: Types.CompanyUserStatus, uid: string }> } };

export type RootCompanyListFragment = { __typename: 'Query', viewer: { __typename: 'Viewer', uid: string, companies: Array<{ __typename: 'Company', companyProfileId: string, profile: { __typename: 'CompanyProfile', companyProfileId: string, legalName: string, legalForm: string }, metadata: { __typename: 'CompanyMetadata', companyProfileId: string, accountStatus: Types.CompanyMetadataAccountStatus | null } }>, companyUsers: Array<{ __typename: 'CompanyUser', roles: Array<Types.Role>, companyUserId: string, companyProfileId: string, isPrimaryApplicant: boolean, status: Types.CompanyUserStatus, uid: string }> } };

export const RootCompanyListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"rootCompanyList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Query"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"companies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}},{"kind":"Field","name":{"kind":"Name","value":"profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}},{"kind":"Field","name":{"kind":"Name","value":"legalName"}},{"kind":"Field","name":{"kind":"Name","value":"legalForm"}}]}},{"kind":"Field","name":{"kind":"Name","value":"metadata"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}},{"kind":"Field","name":{"kind":"Name","value":"accountStatus"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"companyUsers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"roles"}},{"kind":"Field","name":{"kind":"Name","value":"companyUserId"}},{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}},{"kind":"Field","name":{"kind":"Name","value":"isPrimaryApplicant"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"uid"}}]}}]}}]}}]} as unknown as DocumentNode<RootCompanyListFragment, unknown>;
export const RootDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"root"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"rootCompanyList"}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"rootCompanyList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Query"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"companies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}},{"kind":"Field","name":{"kind":"Name","value":"profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}},{"kind":"Field","name":{"kind":"Name","value":"legalName"}},{"kind":"Field","name":{"kind":"Name","value":"legalForm"}}]}},{"kind":"Field","name":{"kind":"Name","value":"metadata"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}},{"kind":"Field","name":{"kind":"Name","value":"accountStatus"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"companyUsers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"roles"}},{"kind":"Field","name":{"kind":"Name","value":"companyUserId"}},{"kind":"Field","name":{"kind":"Name","value":"companyProfileId"}},{"kind":"Field","name":{"kind":"Name","value":"isPrimaryApplicant"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"uid"}}]}}]}}]}}]} as unknown as DocumentNode<RootQuery, RootQueryVariables>;