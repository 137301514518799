/**
 * Returns the ReactNativeWebView object if it exists, or returns null if not.
 * Can be used as a way to check if we're in the context of Shine's React Native app or not.
 */
const getWebViewAPI = () => {
  const webViewAPI = window.ReactNativeWebView;

  return webViewAPI ?? null;
};

export default getWebViewAPI;
