import { Text } from '@chakra-ui/react';

import Card from 'components/_core/Card';
import Heading from 'components/_core/Heading';
import { ErrorPage } from 'components/ErrorPage';
import { ErrorPageType } from 'components/ErrorPage/types';
import { OBCard } from 'features/Bento/components';

import LockedIllustration from '../assets/locked-account.svg';
import { useUserLockedPage } from './hooks/useUserLockedPage';
import locales from './locales';
import { LocalePathKey, UserLockedReasons } from './types';

export interface Company {
  legalName: string;
}

interface UserLockedProps {
  companies?: Company[];
  reason: UserLockedReasons;
}

/**
 * The "companies" prop is passed when a parent component already has the list
 * of the companies that the user is locked from.
 **/
const UserLocked = ({ companies, reason }: UserLockedProps) => {
  const { companyName, isLockedFromMultipleCompanies, loading } =
    useUserLockedPage(companies);

  if (loading) {
    return null;
  }

  const localePathKey = isLockedFromMultipleCompanies
    ? LocalePathKey.LockedFromMultipleCompanies
    : LocalePathKey.LockedFromSingleCompany;
  const title = locales.formatString(locales.title[localePathKey], {
    ...(companyName && { companyName }),
  });
  const description =
    reason === UserLockedReasons.LockedByAdmin
      ? locales.formatString(locales.description[reason][localePathKey], {
          ...(companyName && { companyName }),
        })
      : locales.formatString(locales.description[reason], {
          ...(companyName && { companyName }),
        });

  return (
    <ErrorPage
      type={
        reason === UserLockedReasons.KycRefused
          ? ErrorPageType.UserKycRefused
          : ErrorPageType.UserRevoked
      }
      width="100%"
    >
      <Card width="429px">
        <OBCard textAlign="center">
          <OBCard.Illustration src={LockedIllustration} />
          <OBCard.Body>
            <Heading paddingBottom="space-16" size="lg">
              {title}
            </Heading>

            <Text>{description}</Text>
          </OBCard.Body>
        </OBCard>
      </Card>
    </ErrorPage>
  );
};

export default UserLocked;
