// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounceToNextFrame = <F extends (...args: any[]) => void>(
  fn: F,
): ((...funcArgs: Parameters<F>) => void) & {
  cancel: () => void;
} => {
  let frameReference: number;

  const cancel = (): void => {
    cancelAnimationFrame(frameReference);
  };

  return Object.assign(
    (...args: Parameters<F>): void => {
      cancel();
      frameReference = requestAnimationFrame((): void => fn(...args));
    },
    { cancel },
  );
};

export default debounceToNextFrame;
