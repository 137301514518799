/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeDeepWithKey } from 'ramda';

import mergeBy from '../mergeBy';

const mergeConnectionResults = (predicate: (a: any, b: any) => boolean): any =>
  mergeDeepWithKey((key, left, right): any =>
    key === 'edges' ? mergeBy(predicate, left, right) : right,
  );

export default mergeConnectionResults;
