import { useEffect } from 'react';
import { useHistory } from 'react-router';

const useOnHistoryPop = (callback: () => void) => {
  const history = useHistory();

  /**
   * When the user clicks the browser back button,
   * send an event to the current child machine.
   */
  useEffect(
    () =>
      history &&
      history.listen((_, action) => {
        if (action === 'POP') {
          callback();
        }
      }),
    [history, callback],
  );
};

export default useOnHistoryPop;
