import { type FC } from 'react';
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { Typography } from '@shinetools/sunshine-universal';

import useCompanyContext from 'common/hooks/useCompanyContext';
import Button from 'components/_core/Button';
import * as Layout from 'layouts/ContentLayout';

import { InvoicesTable } from '../../components/InvoicesTable';
import { ModalFees } from '../../components/ModalFees';
import * as routes from '../../routes';
import { OverviewHeader } from './components/OverviewHeader';
import { OverviewQuotas } from './components/OverviewQuotas';
import * as GQL from './overview.gql';

export const Overview: FC = () => {
  const { companyProfileId } = useCompanyContext();
  const intl = useIntl();

  const modalFees = useDisclosure();

  const { data, loading } = useQuery(
    GQL.SubscriptionManagementOverviewDocument,
    {
      skip: !companyProfileId,
      variables: {
        companyProfileId: companyProfileId as string,
      },
    },
  );

  const invoices = data?.viewer.company.subscriptionPlan?.invoices?.edges;

  return (
    <IntlProvider
      {...intl}
      defaultRichTextElements={{
        ...intl.defaultRichTextElements,
        discreet: (chunks) => (
          <Typography.Text tag="span" variant="discreet">
            {chunks}
          </Typography.Text>
        ),
        modal_fees: (chunks) => (
          <Typography.Link onPress={modalFees.onOpen}>{chunks}</Typography.Link>
        ),
      }}
    >
      <Layout.Root>
        <Layout.Body size="md">
          <Flex direction="column" gap="space-64">
            <OverviewHeader data={data} />

            <Flex direction="column" gap="space-32">
              <Flex direction="column" gap="space-2">
                <Typography.Text bold size="large">
                  <FormattedMessage id="subscription.overview.usage.title" />
                </Typography.Text>
                <Typography.Text variant="secondary">
                  <FormattedMessage id="subscription.overview.usage.description" />
                </Typography.Text>
              </Flex>

              <Flex direction="column" gap="space-24">
                <OverviewQuotas data={data} loading={loading} />

                <Typography.Text variant="secondary">
                  <FormattedMessage id="subscription.overview.fees" />
                </Typography.Text>
              </Flex>
            </Flex>

            <Flex direction="column" gap="space-24">
              <Flex alignItems="center" justifyContent="space-between">
                <Typography.Text bold size="large">
                  <FormattedMessage id="subscription.overview.invoices.label" />
                </Typography.Text>

                <Button as={Link} to={routes.invoices} variant="inline-primary">
                  <FormattedMessage id="subscription.overview.invoices.link" />
                </Button>
              </Flex>

              <InvoicesTable invoices={invoices} loading={loading} />
            </Flex>
          </Flex>
        </Layout.Body>
      </Layout.Root>
      {companyProfileId ? (
        <ModalFees {...modalFees} companyProfileId={companyProfileId} />
      ) : null}
    </IntlProvider>
  );
};
