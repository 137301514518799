import { apolloClient } from 'common/graphql/clients';
import { TrackSegmentEventDocument } from 'features/Analytics/graphql/mutations/trackSegmentEvent.gql';

interface TrackSkipVerificationHomeDecidedProps {
  companyProfileId: string;
  skippedVerificationHome: boolean;
}

export const trackSkipVerificationHomeDecided = ({
  companyProfileId,
  skippedVerificationHome,
}: TrackSkipVerificationHomeDecidedProps) => {
  return apolloClient.mutate({
    mutation: TrackSegmentEventDocument,
    variables: {
      input: {
        companyProfileId,
        event: 'Onboarding Skip Verification Home Decided',
        properties: { skippedVerificationHome },
      },
    },
  });
};
