import withSignupWrapper from '../../libs/withSignupModuleWrapper';
import Component from './AccountCreation';
import status from './libs/status';
import machine from './machine';

const accountCreation = {
  component: withSignupWrapper(Component),
  id: machine.id,
  machine,
  status,
};

export default accountCreation;
