// The focus-visible library only exposes a built version of its code
// which needs importing at least once to be included in our bundle.
import 'focus-visible/dist/focus-visible';

import styled from 'styled-components';

import zIndices from 'common/zIndices';

export const AppContainer = styled.div`
  height: 100%;
  z-index: ${zIndices.appContainer};
  position: relative;
`;

export const ModalsContainer = styled.div`
  z-index: ${zIndices.modalsContainer};
  position: fixed;
  top: 0;
  left: 0;
`;

export const AlertsContainer = styled.div`
  z-index: ${zIndices.alertsContainer};
  position: fixed;
  top: 0;
  left: 0;
`;

export const FullscreenContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: ${({ theme }) => theme.spacings.abs32}px;
`;
