import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    cancel: 'Cancel',
    confirmResetPasscode: 'Yes, reset my passcode',
    descriptionCreatePasscode:
      'We will ask for your passcode to login to Shine and secure important actions.',
    descriptionPasscode:
      'This is the secret code you chose when you created your account. **This code is not sent to you** by text.',
    descriptionResetPasscode:
      'We will ask for your passcode to login to Shine and secure important actions. **This will replace your previous passcode.**',
    descriptionVerificationCode:
      'We send a confirmation code each time you connect to a new device, to ensure that no one else but you can access your account',
    didNotMatchError: 'Your codes did not match. Please try again.',
    next: 'Next',
    passcodeForgotten: 'Forgot passcode?',
    titleCreatePasscode: 'Create your 4 digits passcode',
    titleCreateRetypePasscode: 'Confirm your passcode',
    titlePasscode: 'Enter your 4 digits passcode',
    titleResetPasscode: 'Create your new secret code',
    titleVerificationCode: 'Type the code you received by SMS',
  },
  fr: {
    cancel: 'Annuler',
    confirmResetPasscode: 'Réinitialiser mon code secret',
    descriptionCreatePasscode:
      'Ce mot de passe vous servira à vous connecter à Shine, et à valider certaines transactions',
    descriptionPasscode:
      "Il s'agit du code secret que vous avez choisi lors de la création de votre compte. **Ce code ne vous est pas envoyé** par SMS.",
    descriptionResetPasscode:
      'Ce mot de passe vous servira à vous connecter à Shine, et à valider certaines transactions. **Il remplacera votre précédent code secret.**',
    descriptionVerificationCode:
      'Nous envoyons un code de confirmation à chaque connexion sur un nouvel appareil, pour nous assurer que personne d’autre n’utilise votre compte',
    didNotMatchError: 'Vos codes ne correspondent pas. Veuillez réessayer.',
    next: 'Suivant',
    passcodeForgotten: 'Code secret oublié ?',
    titleCreatePasscode: 'Créez votre code secret',
    titleCreateRetypePasscode: 'Confirmez votre code secret',
    titlePasscode: 'Saisissez votre code secret à 4 chiffres',
    titleResetPasscode: 'Créez votre nouveau code secret',
    titleVerificationCode:
      'Saisissez le code de vérification à 6 chiffres envoyé par SMS',
  },
} as const);

export default locales;
