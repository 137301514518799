/**
 * Checks if a given script’s src is already present on the page
 */
const isScriptAlreadyAdded = (src: string): boolean => {
  const scripts = document.querySelectorAll('script');

  return Boolean([...scripts].filter((script) => script.src === src).length);
};

/**
 * A simple helper function to add a script with the given attributes to the page
 */
const loadScript = (scriptAttributes: Record<string, string>) => {
  // @ts-expect-error scriptAttributes.src is possibly 'undefined'.
  if (isScriptAlreadyAdded(scriptAttributes.src)) {
    return;
  }

  const script = document.createElement('script');

  Object.keys(scriptAttributes).forEach((key) => {
    // @ts-expect-error scriptAttributes[key] is possibly 'undefined'.
    script.setAttribute(key, scriptAttributes[key]);
  });

  document.body.appendChild(script);
};

export default loadScript;
