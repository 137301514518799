/**
 * `analytics.identify([userId], [traits], [options], [callback]);`
 * `analytics.track(event, [properties], [options], [callback]);`
 * `analytics.trackLink(element, event, [properties])`
 * `analytics.trackForm(form, event, [properties])`
 * `analytics.page([name|category], [name], [properties], [options], [callback])`
 * `analytics.group(groupId, [traits], [options], [callback])`
 * `analytics.alias(userId, [previousId], [options], [callback])`
 *
 * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 */

/**
 * Create a function which once called will return the result of the method from segment if
 * defined or null
 * @param methods the name of a segment's method
 */
import webView from 'common/webView';
import { getUser } from 'helpers/auth';

import getCookies from './helpers/getCookies';
import { getInsensitiveContextPageData } from './helpers/url';

const wrap =
  (methods: keyof Window['analytics']) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...args: any[]): any => {
    // analytics being unavailable does not mean we should not execute the callback
    const argsCallbackIndex = methods === 'page' ? 4 : 3;
    const hasTrackCallback =
      methods === 'track' &&
      args &&
      Array.isArray(args) &&
      args.length > argsCallbackIndex &&
      typeof args[argsCallbackIndex] === 'function';

    const callback = hasTrackCallback ? args[argsCallbackIndex] : null;

    try {
      if (window.analytics && methods in window.analytics) {
        /**
         * Segment uses a queue to store the calls to its methods before the script is initialized.
         * @see https://stackoverflow.com/questions/14859058/why-does-the-segment-io-loader-script-push-method-names-args-onto-a-queue-which
         */
        const hasInitialized = window.analytics.initialized;
        const shouldPerformCallbackImmediately =
          !hasInitialized && hasTrackCallback;
        const argsAdjusted = shouldPerformCallbackImmediately
          ? args.slice(0, argsCallbackIndex)
          : args;
        // eslint-disable-next-line @typescript-eslint/ban-types
        const exec = window.analytics[methods] as Function;

        if (methods === 'identify') {
          const contextPage = getInsensitiveContextPageData();
          const [userId, traits = {}, options, callbackIdentify] = args;
          const optionsObj = {
            ...options,
            context: { ...options?.context, page: contextPage },
          };

          const newArgs = [userId, traits, optionsObj];
          if (args.length > 3) {
            newArgs.push(callbackIdentify);
          }

          return exec(...newArgs);
        }

        const result = exec(...argsAdjusted);
        if (shouldPerformCallbackImmediately) {
          callback();
        }

        return result;
      } else if (hasTrackCallback) {
        callback();
      }

      return null;
    } catch (error) {
      if (hasTrackCallback) {
        callback();
      }

      // ignore trackers' errors
      return null;
    }
  };

export const load: Window['analytics']['load'] = wrap('load');
export const identify: Window['analytics']['identify'] = wrap('identify');
export const track: Window['analytics']['track'] = wrap('track');
export const page: Window['analytics']['page'] = wrap('page');
export const group: Window['analytics']['group'] = wrap('group');
export const alias: Window['analytics']['alias'] = wrap('alias');
export const trackLink: Window['analytics']['trackLink'] = wrap('trackLink');
export const trackForm: Window['analytics']['trackForm'] = wrap('trackForm');
export const ready: Window['analytics']['ready'] = wrap('ready');
export const reset: Window['analytics']['reset'] = wrap('reset');
export const user: Window['analytics']['user'] = wrap('user');
export const debug: Window['analytics']['debug'] = wrap('debug');
export const on: Window['analytics']['on'] = wrap('on');
export const timeout: Window['analytics']['timeout'] = wrap('timeout');

interface SegmentContext {
  campaign: Partial<{
    name: string;
    source: string;
    medium: string;
  }>;
}

export interface TrackedEvent {
  name: string;
  properties?: JSONObject;
  usePrefix?: boolean;
  context?: Partial<SegmentContext>;
}

const buildProperties = (
  props: JSONObject = {},
): Record<string, string | number | JSONObject> => {
  const cookies = getCookies();
  return {
    ...props,
    // @ts-expect-error cookies._fbc is possibly 'undefined'.
    fbc: cookies._fbc,
    // @ts-expect-error cookies._fbp is possibly 'undefined'.
    fbp: cookies._fbp,
    isInWebView: webView.isInWebView.toString(),
    // We’re sending viewport instead of screen width because we want to make
    // analysis based on responsive-display of some pages, and responsive is
    // based on viewport width not screen width (which is fixed no matter the window size)
    viewportWidth: window.innerWidth,
  };
};

/**
 * Because Segment.js automatically collects data from the page,
 * we override some fields within `context.page` to avoid sending sensitive data
 * which was part of query params
 *
 * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/troubleshooting/#can-i-overwrite-the-context-fields
 */
const buildContext = (context: Partial<SegmentContext>) => {
  const { email, phone, zip } = getUser() || {};

  return {
    context: {
      traits: {
        address: (zip && { postalCode: zip }) || undefined,
        email,
        phone,
      },
      ...context,
      page: getInsensitiveContextPageData(),
    },
  };
};

/**
 * @param {boolean} usePrefix - Add 'Web ' prefix to the event name.
 * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
 * timeout for optional callback is 300 ms by default
 */
export const logEvent = (
  { context = {}, name, properties = {}, usePrefix = true }: TrackedEvent,
  callback?: () => void,
): void => {
  const eventName = `${usePrefix ? 'Web ' : ''}${name}`;
  const eventProperties = buildProperties(properties);
  const eventContext = buildContext(context);

  if (callback) {
    track(eventName, eventProperties, eventContext, callback);
  } else {
    track(eventName, eventProperties, eventContext);
  }
};

/**
 * @param {boolean} usePrefix - Add 'Web View ' prefix to the event name.
 */
export const logScreen = ({
  context = {},
  name,
  properties = {},
  usePrefix = true,
}: TrackedEvent): void =>
  track(
    `${usePrefix ? 'Web View ' : ''}${name}`,
    buildProperties(properties),
    buildContext(context),
  );
