import { useMemo } from 'react';
import {
  CircularProgress,
  CircularProgressLabel,
  type CircularProgressProps,
  forwardRef,
} from '@chakra-ui/react';
import { type Color } from '@shinetools/sunshine';
import { type IconName } from '@shinetools/sunshine-icons';
import { Icon } from '@shinetools/sunshine-universal';

import { useQuota } from '../context';

export type ProgressProps = CircularProgressProps & {
  icon: IconName;
};

export const Progress = forwardRef<ProgressProps, 'div'>((props, ref) => {
  const { __css: css, icon, max, value, ...rest } = props;

  const { theme } = useQuota();

  const progressColor = useMemo<Color>(() => {
    if (typeof value === 'undefined' || typeof max === 'undefined') {
      return 'grey.300';
    }

    if (value > max) {
      return 'orange.600';
    }

    return 'green.600';
  }, [max, value]);

  return (
    <CircularProgress
      __css={{
        ...theme.progress,
        ...css,
      }}
      capIsRound
      className="quota__progress"
      color={progressColor}
      max={max}
      min={0}
      thickness={8}
      trackColor="grey.300"
      value={value}
      {...rest}
      ref={ref}
    >
      {icon ? (
        <CircularProgressLabel __css={{ ...theme.progress_icon }}>
          <Icon icon={icon} margin="auto" />
        </CircularProgressLabel>
      ) : null}
    </CircularProgress>
  );
});
