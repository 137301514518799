import styled from 'styled-components';

import Text from 'components/_core/Text';
import CompanyListItem from 'components/CompanyListItem';
import Row from 'components/Row';

interface LayoutProps {
  readonly withDropdown: boolean;
}

export const Layout = styled.div<LayoutProps>`
  height: ${(props) => (props.withDropdown ? '133px' : '81px')};
  position: relative;
  margin-bottom: 32px;
  width: 100%;
`;

export const Container = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.07),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  left: 0;
  right: 0;
`;

export const Account = styled(CompanyListItem)`
  border-bottom: 1px solid #f7f8f9;
`;

export const Dropdown = styled.div``;

interface DropdownHeaderProps {
  readonly withBorder: boolean;
}

export const DropdownHeader = styled(Row)<DropdownHeaderProps>`
  padding: 16px 24px;
  &:hover {
    cursor: pointer;
  }
  border-bottom: ${(props) =>
    props.withBorder ? '1px solid #F7F8F9' : 'none'};
`;

export const DropdownTitle = styled(Text).attrs({
  fontWeight: 'weight-500',
  variant: 'primary',
})``;
