import { Box, type BoxProps, forwardRef } from '@chakra-ui/react';

import { useTable } from '../context';

export type CellProps = BoxProps;

export const Cell = forwardRef<CellProps, 'div'>((props, ref) => {
  const { __css: css, ...rest } = props;
  const { theme } = useTable();

  return (
    <Box
      __css={{ ...theme.cell, ...css }}
      className="table-cell"
      {...rest}
      ref={ref}
    />
  );
});
