import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    next: 'Next',
    phoneNumber: 'Your phone number',
  },
  fr: {
    next: 'Suivant',
    phoneNumber: 'Votre numéro de téléphone',
  },
} as const);

export default locales;
