export const getMaxWitdthFromSize = (size: string) => {
  switch (size) {
    case 'sm':
      return { '3xl': '752px', '4xl': '960px', sm: '600px' };
    case 'md':
      return { '3xl': '1200px', '4xl': '1440px', sm: '960px' };
    default:
      throw new Error(`Layout size ${size} not supported`);
  }
};
