import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    genericLabel: 'My account',
  },
  fr: {
    genericLabel: 'Mon compte',
  },
} as const);
