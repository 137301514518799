import styled from 'styled-components';

import ButtonBadge from 'components/ButtonBadge';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacings.abs16}px 0;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 ${({ theme }) => theme.spacings.abs40}px;
`;

export const Expiry = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacings.abs16}px;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: ${({ theme }) => theme.spacings.abs32}px;
  height: ${({ theme }) => theme.spacings.abs64}px;
  width: ${({ theme }) => theme.spacings.abs64}px;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacings.abs40}px;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.08),
    0px 5px 20px rgba(0, 0, 0, 0.08);
`;

export const RightImageContainer = styled(ImageContainer)`
  margin-left: -${({ theme }) => theme.spacings.abs16}px;
`;

export const ImageGroup = styled.div`
  display: flex;
`;

export const RoundImage = styled.img`
  height: ${({ theme }) => theme.spacings.abs32}px;
  width: ${({ theme }) => theme.spacings.abs32}px;
  border-radius: ${({ theme }) => theme.spacings.abs16}px;
  object-fit: contain;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CloseButton = styled(ButtonBadge)`
  align-self: flex-start;
`;
