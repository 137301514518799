import { Image, VStack } from '@chakra-ui/react';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import Content from 'features/TeamOnboarding/Onboarding/components/Content';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import timerImg from './assets/timer.png';
import locales from './locales';

const ManualReviewApplication = asBentoPage(({ Layout }) => (
  <Layout nav={<Navigation />}>
    <VStack boxSize="30rem" marginX="auto" textAlign="center">
      <Image alt="" role="presentation" src={timerImg} />
      <Content content={locales.content} title={locales.title} />
    </VStack>
  </Layout>
));

export default ManualReviewApplication;
