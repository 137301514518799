/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import { Center } from '@chakra-ui/react';

import Loader from 'components/Loader';
import OBLayoutContext from 'features/Bento/libs/OBLayoutContext';

/**
 * A loader page meant for the Signup flow.
 */
const SignupLoaderPage = () => {
  const Layout = useContext(OBLayoutContext);

  return (
    <Layout>
      <Center paddingTop="space-128">
        <Loader />
      </Center>
    </Layout>
  );
};

export default SignupLoaderPage;
