import { type FC, type PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from '@chakra-ui/react';
import { Icon } from '@shinetools/sunshine-universal';

export const SubscriptionManagementLayout: FC<
  PropsWithChildren<{
    prevRoute: string;
  }>
> = ({ children, prevRoute }) => {
  return (
    <Box
      paddingX={{ base: 40, lg: 60, xl: 92 }}
      paddingY={{ base: 'space-40', xl: 'space-64' }}
    >
      <Button
        as={Link}
        leftIcon={<Icon icon="arrow-left" />}
        marginBottom={{ base: 'space-40', xl: 'space-48' }}
        to={prevRoute}
        variant="secondary"
      >
        Retour
      </Button>

      {children}
    </Box>
  );
};
