import styled from 'styled-components';

const containerStyle = `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 504px) {
    align-items: center;
  }
`;

export const Container = styled.form`
  ${containerStyle}
`;

export const Form = styled.div`
  ${containerStyle}
  margin-bottom: ${({ theme }) => theme.spacings.abs32}px;
  width: 100%;
`;

export const Errors = styled.div`
  color: #db4759;
  font-size: 18px;
  margin-top: ${({ theme }) => theme.spacings.abs8}px;
  @media (max-width: 504px) {
    margin: ${({ theme }) =>
      `${theme.spacings.abs8}px ${theme.spacings.abs32}px 0`};
    text-align: center;
  }
`;
