export const root = '/bank';

export const bankOverview = `${root}/overview`;

export const bankAccounts = `${root}/accounts`;
export const bankAccount = `${bankAccounts}/:bankAccountId`;
export const bankAccountDetails = `${bankAccounts}/iban`;

export const dispute = `${root}/dispute`;
export const disputeSuccess = `${root}/dispute/success`;
export const disputeTransactions = `${root}/dispute/transactions`;

export const newBankAccount = `${bankAccounts}/new`;
export const closeBankAccount = `${bankAccounts}/:bankAccountId/close`;

export const editBankAccount = `${bankAccounts}/:bankAccountId/edit`;

export const getTransactionsByCompanyUserIdPath = (companyUserId: string) =>
  `${bankOverview}?companyUserId=${companyUserId}`;

export const getBankAccountPath = (bankAccountId: string) =>
  `${bankAccounts}/${bankAccountId}`;

export const getBankAccountDetailsPath = (bankAccountId?: string) =>
  bankAccountId
    ? `${bankAccountDetails}?bankAccountId=${bankAccountId}`
    : bankAccountDetails;

export const getOverviewPath = (bankAccountId?: string) =>
  bankAccountId
    ? `${bankOverview}?bankAccountId=${bankAccountId}`
    : bankOverview;

export const getEditBankAccountPath = (bankAccountId: string) =>
  `${bankAccounts}/${bankAccountId}/edit`;

export const getCloseBankAccountPath = (bankAccountId: string) =>
  `${bankAccounts}/${bankAccountId}/close`;

export const getDisputePath = (transactionIds: string[]) =>
  `${dispute}?transactionId=${transactionIds.join('&transactionId=')}`;

export const getDisputeTransactionsPath = (transactionIds: string[]) =>
  transactionIds?.length > 0
    ? `${disputeTransactions}?transactionId=${transactionIds.join(
        '&transactionId=',
      )}`
    : disputeTransactions;

export const getDisputeSuccessPath = (conversationId: string) =>
  `${disputeSuccess}?conversationId=${conversationId}`;
