const onfidoErrors = {
  'Onfido.OnfidoFlowError.cameraPermission': 'cameraPermission',
  'Onfido.OnfidoFlowError.microphonePermission': 'microphonePermission',
} as const;

export type OnfidoError = (typeof onfidoErrors)[keyof typeof onfidoErrors];

/**
 * Turns an error code from Onfido into a known error we will handle.
 * If the error isn't known, we'll set `null` and treat is as unknown.
 */
const getOnfidoError = (
  onfidoErrorCode: string | undefined,
): OnfidoError | null => {
  return onfidoErrorCode && onfidoErrorCode in onfidoErrors
    ? onfidoErrors[onfidoErrorCode as keyof typeof onfidoErrors]
    : null;
};

export default getOnfidoError;
