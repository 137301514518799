import {
  alias as segmentAlias,
  user as segmentUser,
} from 'features/Analytics/analytics';
import { getUser } from 'helpers/auth';
import logger from 'helpers/logger';

/**
 * This function returns a XState action that, when executed,
 * calls segment.alias on the user.
 */
const alias = () => ({
  exec: () => {
    const user = getUser();

    const uid = user?.uid;
    const anonymousId = segmentUser()?.anonymousId();

    if (uid && anonymousId) {
      segmentAlias(uid, anonymousId);

      logger.info('ℹ️ SEGMENT ALIAS');
    }
  },
  type: 'alias',
});

export default alias;
