import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    agreement: 'I agree to',
    cta: 'Continue',
    error:
      'This email address is already linked to another phone number. Please change your phone number and try again.',
    subtitle:
      "In order to join {companyName}'s Shine account, you must read and agree to our terms and conditions.",
    termsAndConditions: 'the terms & conditions',
    title: "Check Shine's app terms and conditions",
  },
  fr: {
    agreement: "J'accepte",
    cta: 'Continuer',
    error:
      'Cette adresse mail est déjà liée à un autre numéro de téléphone. Veuillez changer votre numéro de téléphone puis réessayer.',
    subtitle:
      'Pour pouvoir rejoindre le compte Shine de {companyName}, vous devez lire et accepter les conditions générales d’utilisation de notre service.',
    termsAndConditions: "les conditions générales d'utilisation",
    title:
      'Consultez les conditions générales d’utilisation de l’application Shine',
  },
} as const);

export default locales;
