import { type FC } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { Flex, type FlexProps, Image } from '@chakra-ui/react';
import { Icon, Squircle, Typography } from '@shinetools/sunshine-universal';

import Button from 'components/_core/Button';
import Skeleton from 'components/Skeleton';
import SkeletonText from 'components/SkeletonText';
import { formatDuration } from 'helpers/date';
import { capitalize } from 'helpers/string';

import { PLANS_CARD_IMAGES } from '../../../../lib/cardImages';
import * as routes from '../../../../routes';
import { getTrialPeriod } from '../../helpers/getTrialPeriod';
import type * as GQL from '../../overview.gql';
import { Body } from './components/Body';
import { Container } from './components/Container';
import { Footer } from './components/Footer';
import { FooterItem } from './components/FooterItem';

export type OverviewHeaderProps = FlexProps & {
  data?: GQL.SubscriptionManagementOverviewQuery;
};

export const OverviewHeader: FC<OverviewHeaderProps> = (props) => {
  const { data, ...rest } = props;

  const isLoaded = Boolean(data);
  const subscriptionPlan = data?.viewer.company.subscriptionPlan;
  const currentPlan = data?.viewer.company.currentPlan;
  const trialPeriod = getTrialPeriod(data);

  return (
    <Container {...rest}>
      <Body>
        <Flex direction="column" gap="space-4">
          <Flex align="center" justifyContent="space-between">
            <Flex align="center" gap="space-12">
              {currentPlan?.providerPlanId ? (
                <Typography.Header size="large">
                  <FormattedMessage
                    id="subscription.overview.header.plan"
                    values={{
                      plan: capitalize(currentPlan.providerPlanId),
                    }}
                  />
                </Typography.Header>
              ) : (
                <SkeletonText minWidth="space-120" skeletonHeight="36" />
              )}

              {currentPlan?.providerPlanId ? (
                <Image
                  src={PLANS_CARD_IMAGES[currentPlan.providerPlanId]}
                  width="space-40"
                />
              ) : (
                <SkeletonText minWidth="space-40" skeletonHeight="36" />
              )}
            </Flex>
          </Flex>

          {trialPeriod.isLoading ? (
            <SkeletonText skeletonHeight="22" width="200px" />
          ) : null}

          {trialPeriod.isOngoing ? (
            <Flex align="center" gap="space-6">
              <Icon color="$grey.800" icon="sparkles" size="small" />

              <Typography.Text bold>
                <FormattedMessage
                  id="subscription.overview.header.end_of_trial_period"
                  values={{
                    duration: formatDuration(trialPeriod.duration, {
                      format: ['years', 'months', 'days'],
                    }),
                  }}
                />
              </Typography.Text>
            </Flex>
          ) : null}

          {subscriptionPlan?.nextBillingAt ? (
            <Typography.Text variant="secondary">
              <FormattedMessage
                id="subscription.overview.header.next_billing_date"
                values={{
                  amount: (
                    <FormattedNumber
                      currency="EUR"
                      style="currency"
                      value={(subscriptionPlan.planUnitPrice ?? 0) / 100}
                    />
                  ),
                  date: (
                    <FormattedDate
                      day="numeric"
                      month="long"
                      value={new Date(subscriptionPlan.nextBillingAt)}
                      year="numeric"
                    />
                  ),
                }}
              />
            </Typography.Text>
          ) : (
            <SkeletonText skeletonHeight="22" width="400px" />
          )}
        </Flex>

        {isLoaded ? (
          <Button as={Link} marginLeft="auto" to={routes.plans}>
            <FormattedMessage id="subscription.overview.header.compare_offers" />
          </Button>
        ) : (
          <Skeleton height="space-40" marginLeft="auto" width="space-128" />
        )}
      </Body>
      <Footer>
        <FooterItem
          borderBottomLeftRadius="radius-8"
          borderRight="1px solid"
          borderRightColor="grey.300"
        >
          <Squircle backgroundColor="$grey.200" icon="calendar" />

          <Flex direction="column">
            <Typography.Text bold>
              <FormattedMessage id="subscription.overview.header.billing.label" />
            </Typography.Text>

            {currentPlan ? (
              <Typography.Text size="small" variant="secondary">
                <FormattedMessage id="subscription.overview.header.billing.value" />
              </Typography.Text>
            ) : (
              <SkeletonText skeletonHeight="20" />
            )}
          </Flex>
        </FooterItem>

        <FooterItem borderBottomRightRadius="radius-8">
          <Squircle backgroundColor="$grey.200" icon="wallet" />

          <Flex direction="column">
            <Typography.Text bold>
              <FormattedMessage id="subscription.overview.header.payment_method.label" />
            </Typography.Text>

            {currentPlan ? (
              <Typography.Text size="small" variant="secondary">
                <FormattedMessage id="subscription.overview.header.payment_method.value" />
              </Typography.Text>
            ) : (
              <SkeletonText skeletonHeight="20" />
            )}
          </Flex>
        </FooterItem>
      </Footer>
    </Container>
  );
};
