import * as Types from '../../../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TeamOnboardingVerificationAddressUpdatePersonalAddressMutationVariables = Types.Exact<{
  profile: Types.UserProfileInput;
}>;


export type TeamOnboardingVerificationAddressUpdatePersonalAddressMutation = { __typename: 'Mutation', updateUserProfile: { __typename: 'UserProfilePayload', profile: { __typename: 'UserProfile', city: string | null, country: string | null, updatedAt: number, uid: string, street: string | null, streetAddition: string | null, zip: string | null } | null } };


export const TeamOnboardingVerificationAddressUpdatePersonalAddressDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"teamOnboardingVerificationAddressUpdatePersonalAddress"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"profile"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserProfileInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserProfile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"profile"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"country"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"street"}},{"kind":"Field","name":{"kind":"Name","value":"streetAddition"}},{"kind":"Field","name":{"kind":"Name","value":"zip"}}]}}]}}]}}]} as unknown as DocumentNode<TeamOnboardingVerificationAddressUpdatePersonalAddressMutation, TeamOnboardingVerificationAddressUpdatePersonalAddressMutationVariables>;