import styled from 'styled-components';

import Text from 'components/_core/Text';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  @media (max-width: 504px) {
    align-items: center;
  }
`;

export const Title = styled(Text).attrs({
  fontWeight: 'weight-500',
  size: 'lg',
  variant: 'primary',
})`
  margin-bottom: ${({ theme }) => theme.spacings.abs8}px;
  @media (max-width: 504px) {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
  }
`;

export const Description = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacings.abs16}px;
`;
