import { useContext } from 'react';
import { Suntheme } from '@shinetools/suntheme';
import { mapObjIndexed } from 'ramda';
import { ThemeContext } from 'styled-components';

export type LegacyTheme = ReturnType<typeof Suntheme>;

export type LegacySpacing = keyof LegacyTheme['spacings'];

const legacyTheme = Suntheme({ platform: 'web' });

export const useLegacyTheme = () => useContext(ThemeContext);

/**
 * This is a dirty override of the Suntheme color palette to force-retheme the last legacy components.
 */
const overrides = {
  ctaBlue: [
    '#25241D', // '#0D5ABF', // primaryActionColorActive
    '#25241D', // '#0C6CF3', // primaryActionColor
    '#7F7B71', // '#5CA3FF', // logoCtaBlueColor
    '#B6B4AA', // '#A8D4FF', // primaryActionColorInactive
    '#DEDCCE', // '#D1E8FF', // primaryActionColorDisabled
    '#F0EFE4', // '#E2F0FD', // backgroundActionableActive
    '#F6F5EF', // '#F1F7FD', // backgroundActionable
    '#FCFBF8', // '#FAFDFF', // backgroundActionableDisabled
  ],
  grey: [
    '#25241D', // '#17294D', // textPrimaryColor
    '#555249', // '#455980', // textSecondaryColor
    '#7F7B71', // '#8090B2', // textLightColor
    '#B6B4AA', // '#B1BACC', // textLighterColor
    '#DEDCCE', // '#EDEEF1', // separatorLightColor
    '#F0EFE4', // '#F3F5F6', // backgroundGreyColor
    '#F6F5EF', // '#F9FAFB', // backgroundLightGreyColor
    '#FCFBF8', // '#FCFDFE',
  ],
};

type ColorVariant = -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5;

const mapColorVariationsToColorScale =
  (array: string[]) => (index: ColorVariant) =>
    array[index + 2];

const colors = mapObjIndexed(mapColorVariationsToColorScale, overrides);

// @ts-expect-error Type '(index: ColorVariant) => string | undefined' is not assignable to type '(index: ColorVariant) => string'
legacyTheme.colors.ctaBlue = colors.ctaBlue;
// @ts-expect-error Type '(index: ColorVariant) => string | undefined' is not assignable to type '(index: ColorVariant) => string'
legacyTheme.colors.grey = colors.grey;

export default legacyTheme;
