import styled from 'styled-components';

export const OnboardingPhaseWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: minmax(min-content, 380px) minmax(min-content, 120px) minmax(
      min-content,
      1fr
    );
`;

export const NarrowCentredContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 332px;
  padding-top: ${({ theme }) => theme.spacings.abs24}px;
  align-items: flex-start;
`;
