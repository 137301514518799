import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    genericLabel: 'My account',
    help: 'Help',
    logout: 'Log out',
    switchCompany: 'Switch company',
  },

  fr: {
    genericLabel: 'Mon compte',
    help: 'Aide',
    logout: 'Se déconnecter',
    switchCompany: 'Changer d’entreprise',
  },
} as const);
