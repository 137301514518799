import { Children, type FC, useState } from 'react';
import {
  Box,
  type BoxProps,
  Center,
  Circle,
  Flex,
  HStack,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

const ANIMATING_DISTANCE = '40px';

const motionVariants = {
  after: (direction: number) => ({
    opacity: 0,
    x: direction < 0 ? ANIMATING_DISTANCE : `-${ANIMATING_DISTANCE}`,
  }),

  before: (direction: number) => ({
    opacity: 0,
    x: direction > 0 ? ANIMATING_DISTANCE : `-${ANIMATING_DISTANCE}`,
  }),

  in: {
    opacity: 1,
    x: 0,
  },
};

export interface CarouselProps extends Omit<BoxProps, 'onChange'> {
  value: number;
  onChange: (index: number) => void;
  onDone?: () => void;
}

const Carousel: FC<CarouselProps> = ({
  children,
  onChange,
  onDone,
  value,
  ...props
}) => {
  const [previousIndex, setPreviousIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  const slides = Children.toArray(children);

  const circleColorDefault = 'grey.300';
  const circleColorActive = 'grey.800';
  const circleColorHover = 'grey.400';

  if (previousIndex !== value) {
    setDirection(value > previousIndex ? 1 : -1);
    setPreviousIndex(value);
  }

  return (
    <Flex direction="column" {...props}>
      <AnimatePresence custom={direction} initial={false} mode="wait">
        <motion.div
          animate="in"
          custom={direction}
          exit="after"
          initial="before"
          key={value}
          onPanEnd={(event, { offset }) => {
            const isSwipingForward = offset.x < 0;

            if (isSwipingForward) {
              const target = value + 1;
              return target >= slides.length ? onDone?.() : onChange(target);
            }

            const target = value - 1;
            return target < 0 ? null : onChange(target);
          }}
          style={{ touchAction: 'none', userSelect: 'none' }}
          transition={{ duration: 0.2, ease: 'easeOut' }}
          variants={motionVariants}
        >
          {slides[value]}
        </motion.div>
      </AnimatePresence>

      <Center flex={1} flexDirection="column">
        {/*
          when a carousel has a fixed height
          stack the navigation to its bottom
        */}
        <Box flex={1} />
        <HStack
          marginBottom={props.marginBottom || 'space-40'}
          spacing="space-12"
        >
          {slides.map((child, i) => (
            <Circle
              _hover={{
                background: i === value ? undefined : circleColorHover,
              }}
              background={i === value ? circleColorActive : circleColorDefault}
              cursor="pointer"
              key={i}
              onClick={() => onChange(i)}
              size="space-8"
            />
          ))}
        </HStack>
      </Center>
    </Flex>
  );
};

export default Carousel;
