import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    cta: 'Try again',

    errors: {
      cameraPermission: {
        guidance:
          'Please enable camera permission for the Shine app in your device settings.',
        message:
          'Camera permission is required to complete the identity check.',
      },

      microphonePermission: {
        guidance:
          'Please enable microphone permission for the Shine app in your device settings.',
        message:
          'Microphone permission is required to complete the identity check.',
      },

      unknown: {
        guidance:
          'Please try again. If the problem persists, please contact our support team.',
        message:
          'There was an unknown error while completing the identity check.',
      },
    },

    title: 'Identity check failed',
  },

  fr: {
    cta: 'Réessayer',

    errors: {
      cameraPermission: {
        guidance:
          "Veuillez autoriser l'accès à la caméra pour l'application Shine dans les paramètres de votre appareil.",
        message:
          "L'accès à la caméra est requis pour compléter la vérification d'identité.",
      },

      microphonePermission: {
        guidance:
          "Veuillez autoriser l'accès au microphone pour l'application Shine dans les paramètres de votre appareil.",
        message:
          "L'accès au microphone est requis pour compléter la vérification d'identité.",
      },

      unknown: {
        guidance:
          "Veuillez réessayer. Si le problème persiste, veuillez contacter notre équipe d'assistance.",
        message:
          "Une erreur inconnue s'est produite lors de la vérification d'identité.",
      },
    },

    title: 'Échec de la vérification d’identité',
  },
} as const);

export default locales;
