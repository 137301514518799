import { useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { GenerateOnfidoTokenDocument } from './generateOnfidoToken.gql';

const useGetOnfidoToken = () => {
  const [generateOnfidoToken, { data, error, loading }] = useMutation(
    GenerateOnfidoTokenDocument,
  );

  useEffect(() => {
    generateOnfidoToken();
  }, [generateOnfidoToken]);

  return { error, loading, onfidoToken: data?.generateOnfidoToken.token };
};

export default useGetOnfidoToken;
