import LocalizedStrings from 'localized-strings';

const verificationLocales = new LocalizedStrings({
  en: {
    address: {
      add: 'Add your address',
      review: 'Your address',
    },

    document: {
      refused: 'Refused',
    },

    id: {
      add: 'Add your identity',
      review: 'Your identity',
    },
  },

  fr: {
    address: {
      add: 'Compléter votre adresse',
      review: 'Votre adresse',
    },

    document: {
      refused: 'Refusé',
    },

    id: {
      add: 'Compléter vos informations',
      review: 'Vos informations',
    },
  },
} as const);

export default verificationLocales;
