/**
 * For the current browser, this function returns whether an inline PDF viewer is available.
 */
const hasNativePdfViewer =
  navigator.pdfViewerEnabled || 'application/pdf' in navigator.mimeTypes;

/**
 * The inline PDF viewer of iOS browsers is buggy, so we don't want to use it.
 */
const isIosBrowser =
  navigator.userAgent.includes('iPhone') ||
  navigator.userAgent.includes('iPad');

const shouldUseNativePdfViewer = hasNativePdfViewer && !isIosBrowser;

export default shouldUseNativePdfViewer;
