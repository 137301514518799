import { Button, type ButtonProps, forwardRef } from '@chakra-ui/react';

import { useTable } from '../context';

export type FooterProps = ButtonProps;

export const Footer = forwardRef<FooterProps, 'button'>((props, ref) => {
  const { __css: css, ...rest } = props;
  const { theme } = useTable();

  return (
    <Button
      __css={{
        ...theme.footer,
        ...css,
      }}
      className="table-footer"
      {...rest}
      ref={ref}
    />
  );
});
