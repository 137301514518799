/* eslint-disable sort-keys-fix/sort-keys-fix */

const RADIUS_FULL = '9999px';

const radii = {
  'radius-0': '0',
  'radius-2': '0.125rem',
  'radius-4': '0.25rem',
  'radius-6': '0.375rem',
  'radius-8': '0.5rem',
  'radius-10': '0.625rem',
  'radius-12': '0.75rem',
  'radius-16': '1rem',
  'radius-24': '1.5rem',
  'radius-full': RADIUS_FULL,

  /**
   * Kept for compatibility with a few chakra-ui hardcoded values.
   * Cf `chakra-ui/packages/button/src/avatar.tsx`
   */
  full: RADIUS_FULL,
};

export default radii;
