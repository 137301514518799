import { useEffect } from 'react';

import './style.css';

/**
 * Ain't nothing like the old school:
 * We need to target the IC bubble from the outside,
 * so we add an attribute to specifically apply style
 * to the element depending on the breakpoints
 */
const useIntercomStyle = (bp: string | undefined) => {
  useEffect(() => {
    if (bp) {
      document.body.setAttribute('signup-layout', bp);
    } else {
      document.body.removeAttribute('signup-layout');
    }

    return () => {
      document.body.removeAttribute('signup-layout');
    };
  }, [bp]);
};

export default useIntercomStyle;
