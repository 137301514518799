import { defineMessage, type MessageDescriptor } from 'react-intl';
import { type IconProps } from '@shinetools/sunshine-universal';

import {
  type SubscriptionInvoice,
  SubscriptionInvoiceCategory,
} from '__generated__/GQL';

const SUBSCRIPTION_INVOICE_CATEGORY_METADATA = {
  [SubscriptionInvoiceCategory.Fee]: {
    icon: 'cash',
    label: defineMessage({
      id: 'subscription.invoices.category.fee',
    }),
  },
  [SubscriptionInvoiceCategory.Subscription]: {
    icon: 'shine',
    label: defineMessage({
      id: 'subscription.invoices.category.subscription',
    }),
  },
} satisfies Record<
  SubscriptionInvoiceCategory,
  {
    icon: IconProps['icon'];
    label: MessageDescriptor;
  }
>;

export const getInvoiceCategoryMetadata = (
  invoice: Pick<SubscriptionInvoice, 'category'>,
) => {
  return SUBSCRIPTION_INVOICE_CATEGORY_METADATA[invoice.category];
};
