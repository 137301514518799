import { logEvent } from '../../../../../Analytics/analytics';

interface APIResult {
  nom: string;
  code: string;
  _score: number;
  departement: {
    code: string;
    nom: string;
  };
}

/**
 * Fetch a list of matching communes given the provided search string.
 * @see https://geo.api.gouv.fr/decoupage-administratif/communes#name
 */
const fetchFrenchCommunes = async (search: string): Promise<APIResult[]> => {
  const searchParams = new URLSearchParams({
    boost: 'population',
    fields: 'departement',
    // We fetch 13 results because that’s the maximum amount of homonyms a commune can have
    limit: '13',
    nom: search,
    // The API also returns COM by default (like Nouvelle-Calédonie), and we don’t want
    zone: 'metro,drom',
  });

  const url = `https://geo.api.gouv.fr/communes?${searchParams.toString()}`;

  try {
    const response = await fetch(url);
    return response.json();
  } catch (e) {
    logEvent({
      name: 'Communes Fetch Failed',
      properties: {
        url,
      },
      usePrefix: false,
    });

    return [];
  }
};

export interface BirthPlace {
  birthCity: string; // Santeuil
  birthDepartment: string; // 95
  label: `${string} — ${string}`; // Santeuil — Val d’Oise (95)
}

const parseResults = (results: APIResult[]): BirthPlace[] => {
  return results.map((result) => ({
    birthCity: result.nom,
    birthDepartment: result.departement.code,
    label: `${result.nom} — ${result.departement.nom} (${result.departement.code})`,
  }));
};

export const getResults = async (search: string) => {
  const data = await fetchFrenchCommunes(search);

  return parseResults(
    /**
     * Return the first 5 results but also more if the commune has the same name
     * as the first one because some communes have homonyms.
     */
    data.filter((result, index) => index < 5 || result.nom === data[0]?.nom),
  );
};
