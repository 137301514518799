import { BentoLayoutSize } from './types';

import { useLayoutContext } from '.';

const scale = [
  BentoLayoutSize.base,
  BentoLayoutSize.sm,
  BentoLayoutSize.md,
  BentoLayoutSize.lg,
  BentoLayoutSize.xl,
] as const;

/**
 * Just like the behaviour of Chakra UI breakpoint matching,
 * this hook will match a query (ex. `BentoLayoutSize.md`) against the current size of the Layout we are in.
 *
 * Use it like `const isLarge = useLayoutSizeQuery(BentoLayoutSize.lg)`
 */
const useLayoutSizeQuery = (query: BentoLayoutSize) => {
  const { layoutSize } = useLayoutContext();

  return scale.indexOf(query) <= scale.indexOf(layoutSize);
};

export default useLayoutSizeQuery;
