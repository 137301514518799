export enum State {
  INIT = 'init',
  INIT_MANDATEE = 'init-mandatee',
  INIT_NON_MANDATEE = 'init-non-mandatee',

  PROFILE_HELPER = 'profile-helper',
  REDIRECT_BENEFICIARY_INVITEE = 'redirect-beneficiary-invitee',

  COMPANY_CREATION_LEGAL_FORM = 'company-creation-legal-form',
  COMPANY_CREATION_LANDBOT = 'company-creation-landbot',
  SASU_CREATION_ELIGIBILITY_CHECK = 'sasu-creation-eligibility-check',

  BENEFICIARY = 'beneficiary',
  INVITED_ACCOUNT_HOLDER_CAPITAL_DEPOSIT = 'invited-account-holder-capital-deposit',
  START_APPLICATION = 'start-application',
  STANDARD = 'standard',
  CAPITAL_DEPOSIT = 'capital-deposit',
  SHINE_START = 'shine-start',
  SASU_CREATION = 'sasu-creation',

  /**
   * Mandatee states
   */
  PROFILE_HELPER_MANDATEE = 'profile-helper-mandatee',
  WELCOME_MANDATEE = 'welcome-mandatee',
  APPLICATION_SELECTION_MANDATEE = 'application-selection-mandatee',
  CAPITAL_DEPOSIT_MANDATEE = 'capital-deposit-mandatee',
  SEND_NEW_MANDATEE_APPLICATION = 'send-new-mandatee-application',

  DONE = 'done',
}
