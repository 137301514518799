import { formatRelative as dateFnsFormatRelative } from 'date-fns';

import { dateFnsLocales, locales } from './constants';

/**
 * Localized **date-fns** `formatRelative` function
 *
 * *https://date-fns.org/v2.23.0/docs/formatRelative*
 *
 * @param {number | Date} date
 * @param {number | Date} baseDate
 * @param {{ locale?: Locale, weekStartsOn?: number }} options
 * @returns {string} relatively formatted date
 */
const formatRelative = (
  date: number | Date,
  baseDate: number | Date,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  },
): string => {
  return dateFnsFormatRelative(date, baseDate, {
    locale: dateFnsLocales[locales.getLanguage()],
    ...options,
  });
};

export default formatRelative;
