import { Box, type BoxProps, forwardRef } from '@chakra-ui/react';

import { useTable } from '../context';

export type RowProps = BoxProps;

export const Row = forwardRef<RowProps, 'div'>((props, ref) => {
  const { __css: css, ...rest } = props;
  const { columnStyles, theme } = useTable();

  return (
    <Box
      __css={{ ...theme.row, ...columnStyles, ...css }}
      className="table-row"
      {...rest}
      ref={ref}
    />
  );
});
