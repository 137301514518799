import { Box, type BoxProps, forwardRef } from '@chakra-ui/react';
import { Typography } from '@shinetools/sunshine-universal';

import { useTable } from '../context';

export type ColumnProps = BoxProps;

export const Column = forwardRef<ColumnProps, 'div'>((props, ref) => {
  const { __css: css, ...rest } = props;
  const { theme } = useTable();

  return (
    <Box
      __css={{ ...theme.column, ...css }}
      className="table-column"
      {...rest}
      ref={ref}
    >
      <Typography.Text variant="discreet">{rest.children}</Typography.Text>
    </Box>
  );
});
