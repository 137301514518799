import { type FC } from 'react';
import { Flex, type FlexProps } from '@chakra-ui/react';

import { ProviderPlanId } from '__generated__/GQL';
import Skeleton from 'components/Skeleton';
import { capitalize } from 'helpers/string';

import type * as GQL from '../../overview.gql';
import { Quota as QuotaComponent } from './components/Quota';
import { Quota, type QuotaContext } from './context';
import { CheckDepositQuota } from './quotas/CheckDepositQuota';
import { MastercardBusinessWorldDebitQuota } from './quotas/MastercardBusinessWorldDebitPaymentCardQuota';
import { SepaTransferQuota } from './quotas/SepaTransferQuota';
import { TeamMemberSeatQuota } from './quotas/TeamMemberSeatQuota';
import { VirtualPaymentCardQuota } from './quotas/VirtualPaymentCardQuota';
import { WithdrawalQuota } from './quotas/WithdrawalQuota';

const PLAN_TO_QUOTAS: Record<ProviderPlanId, React.ReactNode[]> = {
  [ProviderPlanId.Basic]: [
    <SepaTransferQuota key="sepa-transfer" />,
    <WithdrawalQuota key="withdrawal" />,
    <CheckDepositQuota key="check-deposit" />,
    <VirtualPaymentCardQuota key="virtual-payment-card" />,
  ],
  [ProviderPlanId.Plus]: [
    <SepaTransferQuota key="sepa-transfer" />,
    <WithdrawalQuota key="withdrawal" />,
    <CheckDepositQuota key="check-deposit" />,
    <MastercardBusinessWorldDebitQuota key="mastercard-business" />,
    <VirtualPaymentCardQuota key="virtual-payment-card" />,
  ],
  [ProviderPlanId.Pro]: [
    <SepaTransferQuota key="sepa-transfer" />,
    <WithdrawalQuota key="withdrawal" />,
    <CheckDepositQuota key="check-deposit" />,
    <MastercardBusinessWorldDebitQuota key="mastercard-business" />,
    <TeamMemberSeatQuota key="team-member-seat" />,
  ],
  [ProviderPlanId.Business]: [
    <SepaTransferQuota key="sepa-transfer" />,
    <WithdrawalQuota key="withdrawal" />,
    <CheckDepositQuota key="check-deposit" />,
    <MastercardBusinessWorldDebitQuota key="mastercard-business" />,
    <TeamMemberSeatQuota key="team-member-seat" />,
  ],

  /**
   * Discontinued plans - for pleasing TS
   */
  [ProviderPlanId.MoralPersonBasic]: [],
  [ProviderPlanId.MoralPersonBusiness]: [],
  [ProviderPlanId.MoralPersonPremium]: [],
  [ProviderPlanId.PhysicalPersonBasic]: [],
  [ProviderPlanId.PhysicalPersonPremium]: [],
};

export type OverviewQuotasProps = FlexProps & {
  loading?: boolean;
  data?: GQL.SubscriptionManagementOverviewQuery;
};

export const OverviewQuotas: FC<OverviewQuotasProps> = (props) => {
  const { data, loading, ...rest } = props;

  if (loading || !data || !data.viewer.company.subscriptionPlan) {
    return (
      <Flex direction="column" gap="space-16">
        {[...Array(4)].map((_, index) => (
          <QuotaComponent key={index}>
            <Skeleton height="space-96" width="full" />
          </QuotaComponent>
        ))}
      </Flex>
    );
  }

  const quotaContext: QuotaContext = {
    fees: data.viewer.company.currentPlan.fees,
    planName: capitalize(data.viewer.company.currentPlan.providerPlanId),
    quotas: data.viewer.company.subscriptionPlan.quotas,
  };

  return (
    <Quota.Provider value={quotaContext}>
      <Flex {...rest} direction="column" gap="space-16">
        {PLAN_TO_QUOTAS[data.viewer.company.currentPlan.providerPlanId]}
      </Flex>
    </Quota.Provider>
  );
};
