const DISALLOW_LIST = ['bankAccountId'];

const isValidHttpUrl = (str: string) => {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return ['http:', 'https:'].includes(url.protocol);
};

const hideSensitiveQueryParams = (search: string) => {
  if (!search) {
    return '';
  }

  const searchParams = new URLSearchParams(search);

  DISALLOW_LIST.forEach((param) => {
    if (searchParams.has(param)) {
      searchParams.delete(param);
    }
  });

  const hasSearchParams = [...new Set(searchParams.keys())].length > 0;

  if (!hasSearchParams) {
    return '';
  }

  return `?${searchParams.toString()}`;
};

const hideSensitiveQueryParamsFromUrl = (url: string) => {
  if (!url || !isValidHttpUrl(url)) {
    return '';
  }

  const urlObj = new URL(url);

  DISALLOW_LIST.forEach((param) => {
    if (urlObj.searchParams.has(param)) {
      urlObj.searchParams.delete(param);
    }
  });

  return urlObj.toString();
};

/**
 * Because Segment.js automatically collects data from the page,
 * we override some fields within `context.page` to avoid sending sensitive data
 * which was part of query params
 *
 * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/troubleshooting/#can-i-overwrite-the-context-fields
 */
const getInsensitiveContextPageData = () => {
  const { document, location } = window;
  const { href, search } = location;
  const { referrer } = document;

  return {
    referrer: hideSensitiveQueryParamsFromUrl(referrer),
    search: hideSensitiveQueryParams(search),
    url: hideSensitiveQueryParamsFromUrl(href),
  };
};

export {
  getInsensitiveContextPageData,
  hideSensitiveQueryParams,
  hideSensitiveQueryParamsFromUrl,
  isValidHttpUrl,
};
