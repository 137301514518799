import { createContext, useContext } from 'react';
import { type UseDisclosureProps } from '@chakra-ui/react';

import { quotaTheme } from './theme';

export type QuotaState = UseDisclosureProps & {
  isExpandable?: boolean;
  theme: typeof quotaTheme.baseStyle;
  onToggle: () => void;
};

export const QuotaContext = createContext<QuotaState>({
  onClose: () => {},
  onOpen: () => {},
  onToggle: () => {},
  theme: quotaTheme.baseStyle,
});
export const useQuota = () => useContext(QuotaContext);
