import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    step: 'Step {stepNumber}',
  },
  fr: {
    step: 'Étape {stepNumber}',
  },
} as const);

export default locales;
