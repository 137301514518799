import styled from 'styled-components';

export const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding-left: ${({ theme }) => theme.spacings.abs40}px;
  padding-right: ${({ theme }) => theme.spacings.abs40}px;
  border: 0;
  border-radius: 60px;
  font-size: ts(1);
  font-weight: 400;
  font-family: ${({ theme }): string => theme.texts.fontFamily};
  cursor: pointer;
  text-align: center;
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: #e5e5e5;
    pointer-events: none;
  }
  font-size: 16;
  font-weight: 500;
  background-color: transparent;
  color: #505fe3;
  padding-left: 0;
  height: initial;
  &:hover {
    color: #161f6a;
  }
`;

export const WrongInvitTitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.abs16}px;
`;

export const SentPhoneNumberWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings.abs32}px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
