import { type ProviderPlanId } from '__generated__/GQL';

export const INSURANCE_SUMMARY_PRO_BUSINESS_URL =
  'https://storage.googleapis.com/shine-public/product/insurances/insurance-summary-pro-business.pdf';

export const INSURANCE_SUMMARY_PLUS_URL =
  'https://storage.googleapis.com/shine-public/product/insurances/insurance-summary-plus.pdf';

export const INSURANCE_2024_NOTICE_URL =
  'https://storage.googleapis.com/shine-public/product/insurances/insurance-notice-2024.pdf';

export const getInsuranceSummaryUrl = (
  planId: ProviderPlanId,
  { enableNewInsurance }: { enableNewInsurance: boolean },
) => {
  if (enableNewInsurance) {
    return INSURANCE_2024_NOTICE_URL;
  }
  if (planId.includes('plus')) {
    return INSURANCE_SUMMARY_PLUS_URL;
  }

  return INSURANCE_SUMMARY_PRO_BUSINESS_URL;
};
