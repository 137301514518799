import { Flex, Image, VStack } from '@chakra-ui/react';

import illustration from 'assets/brand/ok-hand.png';
import asBentoPage from 'common/bento/hoc/asBentoPage';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import Text from 'components/_core/Text';
import { OBCard } from 'features/Bento/components';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import locales from './locales';

interface IdentitySuccessProps {
  onContinue: () => void;
}

const IdentitySuccess = asBentoPage<IdentitySuccessProps>(
  ({ Layout, onContinue }) => (
    <Layout nav={<Navigation />}>
      <Flex direction="column" height={{ base: 'full', md: 'auto' }}>
        <OBCard flexGrow={1}>
          <OBCard.Body>
            <VStack spacing="space-16">
              <Image height="180px" src={illustration} width="180px" />

              <Heading size="lg" textAlign="center">
                {locales.title}
              </Heading>

              <Text textAlign="center">{locales.subtitle}</Text>
            </VStack>
          </OBCard.Body>

          <OBCard.Footer bottom="space-24" justifyContent="flex-end">
            <Button icon="arrow-right" onClick={onContinue}>
              {locales.next}
            </Button>
          </OBCard.Footer>
        </OBCard>
      </Flex>
    </Layout>
  ),
);

export default IdentitySuccess;
