import { createContext, useContext } from 'react';
import platform from 'platform';
import { v4 as uuidv4 } from 'uuid';

const DEVICE_STORAGE_KEY = 'device';

export interface Device {
  token: string;
  model: string;
  name: string;
}

const saveDevice = (device: Device) => {
  window.localStorage.setItem(DEVICE_STORAGE_KEY, JSON.stringify(device));
};

const getDevice = (): Device | null => {
  const device = window.localStorage.getItem(DEVICE_STORAGE_KEY);

  if (!device) {
    return null;
  }

  return JSON.parse(device);
};

export const DeviceContext = createContext<Device>({
  model: '',
  name: '',
  token: '',
});

export const useDevice = (): Device => useContext(DeviceContext);

export const getDeviceInformation = (): Device => {
  const device = getDevice();

  const deviceInformation = {
    model: `${platform.name} - ${platform.os}`,
    name: platform.name || '',
    token: device?.token || uuidv4(),
  };

  saveDevice(deviceInformation);

  return deviceInformation;
};
