import { createToast } from 'features/Bento/atoms';

/**
 * This function returns a XState action that, when executed,
 * display a toast to the user.
 */
const toast = (...[props]: Parameters<typeof createToast>) => ({
  exec: () => {
    createToast(props);
  },
  type: 'toast',
});

export default toast;
