import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  flex: 1;
  min-height: 0px;

  @media (max-width: 504px) {
    margin: 32px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0px;

  @media (max-width: 504px) {
    margin-bottom: 80px;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: -115.5px;
  background-color: white;
  box-shadow: 0 10px 20px 0 rgba(223, 223, 227, 0.5);
  width: 125px;
  height: 125px;
  border-radius: 62.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

export const ClientLogoContainer = styled(LogoContainer)`
  @media (max-width: 504px) {
    display: none;
  }
`;

export const ClientLogoContainerResponsive = styled(LogoContainer)`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  top: -2opx;
  right: 0px;
  @media (min-width: 504px) {
    display: none;
  }
`;

export const ClientLogo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: contain;

  @media (max-width: 504px) {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    top: 0px;
  }
`;

export const Logo = styled.img`
  width: 81px;
  margin-top: 30px;
  margin-bottom: 24px;

  @media (max-width: 504px) {
    margin-top: 16px;
  }
`;

export const Title = styled.div`
  font-size: 23px;
  font-weight: 500;
  color: #161f6a;
  margin-bottom: 24px;
  position: relative;

  @media (max-width: 504px) {
    margin-bottom: 64px;
  }
`;

export const Item = styled.div`
  border-style: solid;
  border-width: 0px;
  border-top-width: 1px;
  border-color: #eaeaee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 8px;
  &:first-child {
    border-top-width: 0;
  }
`;

export const CustomText = styled.div`
  color: #161f6a;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 504px) {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 80px;
    background-color: #fbfbfb;
    border: 1px solid #ededf0;
  }
`;

export const List = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const Spacer = styled.div`
  width: ${({ theme }) => theme.spacings.abs32}px;
`;
