import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description:
      'The login attempt from this device was blocked by the holder of the Shine account.',
    title: 'Login attempt blocked',
  },
  fr: {
    description:
      'La connexion depuis cet appareil a été bloquée par le titulaire du compte Shine.',
    title: 'Connexion bloquée',
  },
} as const);

export default locales;
