import { useQuery } from '@apollo/client';

import { OnboardingHotlineType } from '__generated__/GQL';
import useApplicationTypeStore from 'features/Onboarding/hooks/useApplicationTypeStore';
import { ApplicationType } from 'features/Onboarding/types';

import { ObNavigationDocument } from '../graphql/queries/obNavigation.gql';

const map: Record<ApplicationType, OnboardingHotlineType> = {
  [ApplicationType.Standard]: OnboardingHotlineType.Standard,
  [ApplicationType.CapitalDeposit]: OnboardingHotlineType.CapitalDeposit,
  [ApplicationType.ShineStart]: OnboardingHotlineType.ShineStart,
  [ApplicationType.SasuCreation]: OnboardingHotlineType.Default,
};

const useObNavigation = () => {
  const { value } = useApplicationTypeStore();

  const onboardingHotlineType = value ? map[value] : null;

  const { data } = useQuery(ObNavigationDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      onboardingHotlineType,
    },
  });

  return {
    data,
  };
};

export default useObNavigation;
