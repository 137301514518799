export const EXCLUDED_DOMAINS_OR_IP_FROM_SENTRY_BREADCRUMBS = [
  'api.segment.io',
  'tr.snapchat.com',
  'shine.admo.tv',
  'clarity.ms',
  'api-eu.mixpanel.com',
  'appsflyer.com',
];

export const TRACE_ID_HEADER_NAME = 'x-cloud-trace-context';
