import LocalizedStrings from 'localized-strings';

const errorMessages = new LocalizedStrings({
  en: {
    label: 'Close',
  },
  fr: {
    label: 'Fermer',
  },
} as const);

export default errorMessages;
