import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    body: [
      'For the referral to be taken into account, your registration must be done from the referral link you received.',
      `If you haven't received anything, ask the person who referred you to send it to you again.`,
    ],
    title: 'Have you received a referral link?',
  },

  fr: {
    body: [
      'Pour que votre parrainage soit validé, votre inscription doit se faire depuis le lien de parrainage que vous avez reçu.',
      `Si vous n'avez rien reçu, demandez à la personne qui a créé un compte pour votre entreprise chez Shine de vérifier votre adresse e-mail ou de vous renvoyer une invitation.`,
    ],
    title: 'Avez-vous reçu un lien de parrainage ?',
  },
} as const);

export default locales;
