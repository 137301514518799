import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    consent: `I authorize the processing of my photo and video to verify my identity.`,
    consentError: `Your consent is required to verify your identity.`,
    moreInfo: `Read our data protection policy`,
    next: `Next`,
    subtitle: `In order to verify your identity and continue your registration, we need your consent.`,
    title: `Protecting your data matters`,
  },
  fr: {
    consent:
      'J’autorise le traitement de ma photo et vidéo pour vérifier mon identité.',
    consentError:
      'Votre consentement est nécessaire pour vérifier votre identité.',
    moreInfo: 'Lire notre politique de protection des données',
    next: 'Suivant',
    subtitle:
      'Afin de vérifier votre identité et continuer votre inscription, nous avons besoin de votre consentement.',
    title: 'La protection de vos données nous importe',
  },
} as const);

export default locales;
