import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    no: 'No, not yet',
    title: 'Do you already have your draft statutes?',
    validate: 'Continue',
    yes: 'Yes, I already have my draft statutes',
  },

  fr: {
    no: 'Non, pas encore',
    title: 'Avez-vous déjà votre projet de statuts\u00a0?',
    validate: 'Valider',
    yes: 'Oui, j’ai déjà mon projet de statuts',
  },
} as const);

export default locales;
