import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    cta: 'This information is up to date',
    description:
      'Check that your details are still up-to-date and change them if necessary.',
    labels: {
      birthCity: 'Birth city',
      birthCountry: 'Country of birth',
      birthdate: 'Date of birth',
      birthDepartment: 'Birth department',
      legalTitle: 'Legal title',
      nationality: 'Nationality',
    },
    sections: {
      additionalInformation: 'Additional information',
      personnalInformation: 'Personal information',
    },
    title: 'Verify your details',
  },
  fr: {
    cta: 'Ces informations sont à jour',
    description:
      'Veuillez vérifier si les informations suivantes sont toujours à jour et contactez-nous si besoin.',
    labels: {
      birthCity: 'Ville de naissance',
      birthCountry: 'Pays de naissance',
      birthdate: 'Date de naissance',
      birthDepartment: 'Département de naissance',
      legalTitle: 'Civilité',
      nationality: 'Nationalité',
    },
    sections: {
      additionalInformation: 'Informations supplémentaires',
      personnalInformation: 'Informations personnelles',
    },
    title: 'Vérifiez vos informations',
  },
} as const);
