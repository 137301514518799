/* eslint-disable sort-keys-fix/sort-keys-fix */

import SHINE_COLORS from './shineColors';

const CHAKRA_COLORS = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  /**
   * Alias : chakra uses some `blue.digit` keys to style things like input outlines.
   */
  blue: {
    50: SHINE_COLORS.blue[5],
    100: SHINE_COLORS.blue[4],
    200: SHINE_COLORS.blue[3],
    300: SHINE_COLORS.blue[2],
    400: SHINE_COLORS.blue[1],
    500: SHINE_COLORS.blue[0],
    600: SHINE_COLORS.blue['-1'],
    700: SHINE_COLORS.blue['-2'],
    800: SHINE_COLORS.blue['-2'],
    900: SHINE_COLORS.blue['-2'],
  },

  /**
   * @info
   * We'll continue to evaluate where those are used in the chakra-ui defaults
   * (things like input placeholders, etc).
   *
   * Replace them by relevant Shine colors afterwards.
   */
  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: SHINE_COLORS.grey[1],
    400: '#F3F5F6',
    500: '#EDEEF1',
    600: '#B1BACC',
    700: '#8090B2',
    800: '#455980',
    900: '#17294D',
  },
};

export default CHAKRA_COLORS;
