import styled from 'styled-components';

import zIndices from 'common/zIndices';
import { MAIN_CONTENT_WIDTH } from 'features/Onboarding/styleConsts';

// This is the element that sits in the surrounding grid layout
export const OnboardingPageSection = styled.div`
  height: 100%;
  position: relative;
`;

export const OnboardingPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: ${zIndices.pageComponent};
`;

export const OnboardingPageScroll = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-left: 0px;
`;

export const OnboardingPageWidthConstraint = styled.div`
  max-width: ${MAIN_CONTENT_WIDTH}px;
  padding: 110px ${({ theme }) => theme.spacings.abs8}px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

interface BackButtonProps {
  buttonDisabled: boolean;
}

export const OnboardingBackButtonWrapper = styled.div<BackButtonProps>`
  display: flex;
  visibility: ${({ buttonDisabled }) =>
    buttonDisabled ? 'hidden' : 'visible'};
  padding-top: ${({ theme }) => theme.spacings.abs32}px;
  justify-content: flex-end;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: ${MAIN_CONTENT_WIDTH}px;
  width: 100%;
`;
