// eslint-disable-next-line no-restricted-imports
import { CompanyCreationLegalForm } from 'features/Bento/flows/signup/machine/model';

/**
 * Returns if a user should go through the in house SASU creation flow given the legal form of the company
 */
const shouldUserGoThroughInHouseSasuCreation = (
  companyCreationLegalForm: CompanyCreationLegalForm,
): boolean => {
  if (companyCreationLegalForm !== CompanyCreationLegalForm.SASU) {
    return false;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const paramOption = urlParams.get('creation');
  if (paramOption !== null) {
    return paramOption === 'shine';
  }

  const storedOption = localStorage.getItem('inHouseSasuCreation');
  if (storedOption !== null) {
    return storedOption === 'true';
  }

  localStorage.setItem('inHouseSasuCreation', true.toString());

  return true;
};

export default shouldUserGoThroughInHouseSasuCreation;
