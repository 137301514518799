import { useQuery } from '@apollo/client';
import {
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import Button from 'components/_core/Button';
import Card from 'components/_core/Card';
import SuspenseQuery from 'components/SuspenseQuery';
import CreateNewAccountCard from 'features/CreateNewAccount/components/CreateNewAccountCard';
import OnboardingStyleMessageWrapper from 'features/Onboarding/legacy/components/OnboardingStyleMessageWrapper';

import { BeneficiaryNoAccessDocument } from './graphql/queries/beneficiaryNoAccess.gql';
import locales from './locales';
import { SubTitle, Title } from './styles';

const BeneficiaryNoAccountAccess = () => {
  const query = useQuery(BeneficiaryNoAccessDocument);

  const newAccountModal = useDisclosure();

  return (
    <SuspenseQuery query={query}>
      {({ viewer: { isNewOnboardingAllowed, profile } }) => (
        <OnboardingStyleMessageWrapper user={profile}>
          <Card>
            <Flex align="center" direction="column" textAlign="center">
              <Title>{locales.title}</Title>
              <SubTitle>{locales.description}</SubTitle>

              {isNewOnboardingAllowed ? (
                <Button isExpanded onClick={newAccountModal.onOpen}>
                  {locales.openNewAccount}
                </Button>
              ) : null}

              <Modal {...newAccountModal}>
                <ModalOverlay />
                <ModalContent width="400px">
                  <CreateNewAccountCard />
                </ModalContent>
              </Modal>
            </Flex>
          </Card>
        </OnboardingStyleMessageWrapper>
      )}
    </SuspenseQuery>
  );
};

export default BeneficiaryNoAccountAccess;
