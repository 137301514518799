import { type FC, useMemo } from 'react';
import { Flex, type FlexProps } from '@chakra-ui/react';

import { LAYOUT_VERTICAL_PADDING } from '../../constants';
import { getMaxWitdthFromSize } from './utils';

export interface BodyProps extends FlexProps {
  isScrollable?: boolean;
  size?: 'sm' | 'md';
}

const Body: FC<BodyProps> = ({
  children,
  isScrollable = true,
  size = 'sm',
  ...props
}) => {
  const maxWidth = useMemo(() => getMaxWitdthFromSize(size), [size]);

  return (
    <Flex
      flex={1}
      flexDirection="column"
      paddingX={{ '3xl': 'space-240', sm: 'space-40', xl: 'space-120' }}
      paddingY={LAYOUT_VERTICAL_PADDING}
      {...(isScrollable && {
        maxHeight: '100vh',
        overflowY: 'auto',
      })}
      {...props}
    >
      <Flex
        flex={1}
        flexDirection="column"
        maxWidth={maxWidth}
        {...(!isScrollable && { height: '100%' })}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default Body;
