import { eqBy, path } from 'ramda';

import mergeConnectionResults from './mergeConnectionResults';

// Merge two connection result paylods (assuming the edges are of the same type)
// This makes sure we don't end up with duplicates edges in query results
// The 'key' parameter should be the name of the edge node's primary key
const mergeConnectionResultsBy = (key: string) =>
  mergeConnectionResults((a, b) => eqBy(path(['node', key]), a, b));

export default mergeConnectionResultsBy;
