import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description:
      'Take ownership of your expenses: access your transactions in real-time and add your receipts directly to your transactions to make it simpler for accounting.',
    title: 'Submit your receipts easily',
  },
  fr: {
    description:
      'Vos dépenses vous appartiennent\xa0: accédez à vos transactions en temps réel et ajoutez directement vos reçus à vos transactions pour simplifier le travail du service comptable.',
    title: 'Transmettez facilement vos justificatifs et reçus',
  },
} as const);

export default locales;
