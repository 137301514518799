import { type FC, type PropsWithChildren } from 'react';
import { Typography } from '@shinetools/sunshine-universal';

export const Label: FC<PropsWithChildren> = (props) => {
  return (
    <Typography.Text bold className="quota__label">
      {props.children}
    </Typography.Text>
  );
};
