import { State } from '../machine';

export enum Section {
  SupportingDocuments = 'supportingDocuments',
  Review = 'review',
}

export const STATE_TO_SECTION: Record<State, Section | null> = {
  [State.INIT]: null,
  [State.WELCOME]: Section.SupportingDocuments,
  [State.VERIFICATION]: Section.SupportingDocuments,
  [State.AGREEMENT]: Section.SupportingDocuments,
  [State.SEND_APPLICATION]: null,
  [State.UNDER_REVIEW]: Section.Review,
};

export const SECTIONS = [Section.SupportingDocuments, Section.Review] as const;
