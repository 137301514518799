/* eslint-disable @typescript-eslint/no-use-before-define */

import { useCallback } from 'react';
import { useQuery } from '@apollo/client';

import { IdDocumentCase } from '__generated__/GQL';
import asBentoModule from 'common/bento/hoc/asBentoModule';
import withStateMachine from 'common/bento/hoc/withStateMachine/withStateMachine';
import assertNever from 'common/bento/lib/assertNever';
import stringifyState from 'common/bento/lib/stringifyState';
import { useConsumeQueryParam } from 'common/bento/lib/useConsumeQueryParam';
import SuspenseQuery from 'components/SuspenseQuery';
import LoaderPage from 'features/TeamOnboarding/Onboarding/components/LoaderPage';

import { AdminIdentityModuleDataDocument } from './graphql/queries/adminIdentityModuleData.gql';
import getIdentityErrors from './libs/getIdentityErrors';
import getIdentityStatus from './libs/getIdentityStatus';
import getInitialFormValues from './libs/getInitialFormValues';
import machineConfig, { model, State } from './machine';
import BiometricConsent from './pages/BiometricConsent';
import Guide from './pages/Guide';
import IdentityReviewError from './pages/IdentityReviewError';
import IdentitySuccess from './pages/IdentitySuccess';
import NativeIdCheckError from './pages/NativeIdCheckError/NativeIdCheckError';
import OnfidoIdCheck from './pages/OnfidoIdCheck';
import PersonalInformation from './pages/PersonalInformation';
import Recap from './pages/Recap/Recap';

interface IdentityProps {
  companyProfileId: string;
  companyUserId: string;
}

/**
 * This module is responsible to verify the identity of the user.
 *
 * @input A user whose identity data is not registered, and whose identity is not certified.
 * @output A user whose identity data is registered and with an Onfido-certified ID check.
 */
const Identity = asBentoModule<IdentityProps>(
  ({ companyProfileId, onDone }) => {
    const query = useQuery(AdminIdentityModuleDataDocument, {
      variables: { companyProfileId },
    });

    const bootstrap = useConsumeQueryParam(`bootstrap:${machineConfig.id}`);

    return (
      <SuspenseQuery loader={<LoaderPage onPrev={() => null} />} query={query}>
        {(queryResult) => (
          <IdentityComponent
            moduleData={{ ...queryResult, bootstrap }}
            onDone={onDone}
          />
        )}
      </SuspenseQuery>
    );
  },
);

const IdentityComponent = withStateMachine<typeof model>(
  ({ machine, moduleData }) => {
    const [state, send] = machine;

    const stringifiedState = stringifyState<State>(state);

    const onPrev = useCallback(() => send(model.events.PREV()), [send]);

    switch (stringifiedState) {
      case State.IDENTITY_REVIEW_ERROR: {
        const status = getIdentityStatus({
          companyUser: moduleData.viewer.companyUser,
        });
        const errors = getIdentityErrors({
          kycDocuments: moduleData.viewer.companyUser.kycReviewDocuments,
          kycReviewError:
            moduleData.viewer.companyUser.lastKycReview?.error ?? null,
          providerReviewError: moduleData.viewer.companyUser.kycIdProviderError,
          stepStatus: status,
        });

        return (
          <IdentityReviewError
            errors={errors}
            onContinue={() => send(model.events.NEXT())}
            onPrev={onPrev}
          />
        );
      }

      case State.IDENTITY_FORM:
      case State.IDENTITY_SEND:
      case State.ONFIDO_NATIVE_IDENTITY_CHECK:
        return (
          <PersonalInformation
            initialValues={getInitialFormValues({
              invitation: moduleData.viewer.companyUser.invitation,
              profile: moduleData.viewer.profile,
            })}
            isSubmitting={
              state.matches(State.IDENTITY_SEND) ||
              state.matches(State.ONFIDO_NATIVE_IDENTITY_CHECK)
            }
            onPrev={onPrev}
            onSubmit={(values) => send(model.events.SUBMIT(values))}
          />
        );

      case State.GUIDE:
        return (
          <Guide
            idDocumentCase={
              state.context.idDocumentCase ??
              IdDocumentCase.AcceptedPassportOrIdCard
            }
            isAllowedToSkipOnfidoSelfie={
              moduleData.viewer.companyUser.isAllowedToSkipOnfidoSelfie
            }
            onContinue={() => send(model.events.NEXT())}
            onPrev={onPrev}
          />
        );

      case State.BIOMETRIC_CONSENT:
        return (
          <BiometricConsent
            onContinue={() => send(model.events.NEXT())}
            onPrev={onPrev}
          />
        );

      case State.ONFIDO_NATIVE_IDENTITY_ERROR: {
        const error =
          state.event.type === 'done.invoke.completeNativeIdCheck' &&
          state.event.data.status === 'error'
            ? state.event.data.error
            : null;

        return <NativeIdCheckError onfidoErrorCode={error} onPrev={onPrev} />;
      }

      case State.IDENTITY_RECAP:
        return (
          <Recap
            identityValues={getInitialFormValues({
              invitation: moduleData.viewer.companyUser.invitation,
              profile: moduleData.viewer.profile,
            })}
            onNext={() => send(model.events.NEXT())}
            onPrev={onPrev}
          />
        );

      case State.ONFIDO_IDENTITY_CHECK: {
        if (!state.context?.idCheckContext) {
          throw new Error(
            `idCheckContext is required in state ${stringifiedState}`,
          );
        }

        return (
          <OnfidoIdCheck
            isAllowedToSkipOnfidoSelfie={
              moduleData.viewer.companyUser.isAllowedToSkipOnfidoSelfie
            }
            onfidoToken={state.context.idCheckContext.token}
            onPrev={onPrev}
            onSuccess={(documents) =>
              send(model.events.ONFIDO_SUCCESS(documents))
            }
          />
        );
      }

      case State.SUCCESS: {
        return (
          <IdentitySuccess
            onContinue={() => send(model.events.NEXT())}
            onPrev={onPrev}
          />
        );
      }

      case State.INIT:
      case State.DONE:
      case State.START_IDENTITY_VERIFICATION:
      case State.FINISH_IDENTITY_VERIFICATION:
      case State.ABORT:
        return <LoaderPage onPrev={() => null} />;

      default:
        return assertNever(stringifiedState);
    }
  },
  {
    devTools: true,
    machineConfig,
  },
);

export default Identity;
