import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    firstPart: `Your company’s main account holder has sent you an invitation.`,
    secondPart:
      'Can’t find your invitation email? Ask the account holder to check their Shine account to make sure your email address is correct and resend you an invitation.',
    title: 'You have received an invitation by email',
  },

  fr: {
    firstPart:
      'Le titulaire du compte de votre entreprise vous a envoyé une invitation.',
    secondPart:
      'Vous ne retrouvez pas votre e-mail d’invitation\u00a0? Demandez au titulaire du compte de vérifier sur son compte Shine que votre adresse e-mail est la bonne et de vous renvoyer une invitation.',
    title: 'Vous avez reçu une invitation par e-mail',
  },
} as const);

export default locales;
