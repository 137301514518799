import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    codeExpired: 'The code has expired',
    codeTimerPlural: 'The code will expire in {timer} seconds',
    codeTimerSingular: 'The code will expire in {timer} second',
    cta: 'Continue',
    didNotMatchError: 'The code you entered is incorrect. Can you check?',
    sendANewCode: 'Send a new code',
    sentSmsTo: 'The SMS was sent to **{phoneNumber}**',
    title: 'Enter the 6-digit code you received by SMS',
  },

  fr: {
    codeExpired: 'Le code a expiré',
    codeTimerPlural: 'Le code expire dans {timer} secondes',
    codeTimerSingular: 'Le code expire dans {timer} seconde',
    cta: 'Continuer',
    didNotMatchError:
      'Le code que vous avez saisi est incorrect. Pouvez-vous vérifier\u00a0?',
    sendANewCode: 'Envoyer un nouveau code',
    sentSmsTo: 'Le SMS a été envoyé au **{phoneNumber}**',
    title: 'Saisissez le code à 6 chiffres reçu par SMS',
  },
} as const);

export default locales;
