import LocalizedStrings from 'localized-strings';

export default new LocalizedStrings({
  en: {
    cta: {
      reamainingSteps: '{numberOfRemainingSteps} more steps',
      reamainingStepsSingular: '1 more step',
      send: 'Submit my application',
    },

    page: {
      errorSubtitle:
        'Please go back on the steps highlighted in red to finalize your application.',
      subtitle: 'Upload your documents and send your application.',
      title: 'Your application',
      titleWithLegalName: 'Your application for {legalName}',
    },
  },

  fr: {
    cta: {
      reamainingSteps: 'Encore {numberOfRemainingSteps} étapes',
      reamainingStepsSingular: 'Encore une étape',
      send: 'Envoyer mon dossier',
    },

    page: {
      errorSubtitle:
        'Veuillez revenir sur les étapes indiquées en rouge afin de finaliser votre dossier.',
      subtitle: 'Ajoutez vos documents et envoyer votre dossier.',
      title: 'Votre dossier',
      titleWithLegalName: 'Votre dossier pour {legalName}',
    },
  },
} as const);
