export enum Scope {
  BANK_DIRECT_DEBIT_WRITE = 'bank:direct_debits:write',
  BANK_PAYMENT_CARDS_READ = 'bank:payment_cards:read',
  BANK_PAYMENT_CARDS_WRITE = 'bank:payment_cards:write',
  BANK_TRANSFERS_WRITE = 'bank:transfers:write',
  BANK_TRANSFERS_RECIPIENTS_WRITE = 'bank:transfers:recipients:write',
  HOLDER = 'holder',
  CARD_PAYMENT_STRONG_AUTHENTICATION_REQUESTS_ACCEPT = 'cardPaymentStrongAuthenticationRequests:accept',
  TEAM_MEMBERS_REQUESTS_REVIEW = 'team:members_requests:review',
}

export interface Params {
  onPasscodeSuccess: (token: string) => Promise<void>;
  scope: Scope;
}
