import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    description:
      'As an accountant, you have access to all account transactions as well as receipts and supporting documents.',
    title: 'Find all transactions of {companyName}',
  },
  fr: {
    description:
      'En tant que comptable, vous avez accès à l’ensemble des transactions du compte ainsi qu’aux reçus et justificatifs.',
    title: 'Retrouvez l’ensemble des transactions de {companyName}',
  },
} as const);

export default locales;
