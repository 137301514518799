const NAF_REGEX = /\d{4}[A-Z]/i;
export const isValidNAF = (value: string): boolean => {
  return NAF_REGEX.test(value);
};

const ZIP_FR_REGEX = /^\d{5}$/;
export const isValidZip = (value: string): boolean => {
  return ZIP_FR_REGEX.test(value);
};

export const EMAIL_REGEXP =
  /^((([!#$%&'*+\-=?^_`{|}~\w])|([!#$%&'*+\-=?^_`{|}~\w][!#$%&'*+\-=?^_`{|}~.\w]{0,}[!#$%&'*+\-=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/;
export const isValidEmail = (value: string): boolean => {
  return EMAIL_REGEXP.test(value);
};
