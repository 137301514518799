import webView from '..';

const splitVersion = (version: string) => version.split('.').map(Number);

const APP_VERSION = webView.config?.appVersion ?? null;

export const versionIsGte = (currentStr: string, targetStr: string) => {
  const currentVersion = splitVersion(currentStr);
  const targetVersion = splitVersion(targetStr);

  for (const [index, value] of targetVersion.entries()) {
    if (value !== currentVersion[index]) {
      // @ts-expect-error Object is possibly 'undefined'.
      return value <= currentVersion[index];
    }
  }

  return true;
};

export const currentAppVersionIsGte = (targetStr: string): boolean | null => {
  if (!APP_VERSION) {
    return null;
  }

  return versionIsGte(APP_VERSION, targetStr);
};
