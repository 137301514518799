import { Box, Flex } from '@chakra-ui/react';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';
import SunshineCard from 'components/_core/SunshineCard';
import Text from 'components/_core/Text';
import Navigation from 'features/TeamOnboarding/Onboarding/components/Navigation/Navigation';

import locales from './locales';

interface WelcomeAdminProps {
  adminName: string;
  companyName: string;
  onContinue: () => void;
}

const WelcomeAdmin = asBentoPage<WelcomeAdminProps>(
  ({ adminName, companyName, Layout, onContinue }) => {
    return (
      <Layout nav={<Navigation />}>
        <Heading marginBottom="space-16" size="lg">
          {locales.formatString(locales.heading, {
            adminName,
          })}
        </Heading>
        <Text variant="primary">
          {locales.formatString(locales.standardDescription, {
            companyName,
          })}
        </Text>
        <SunshineCard marginY="space-32" padding="space-24">
          <SunshineCard.Content>
            {locales.list.map((listItem, index) => (
              <Flex
                alignItems="center"
                key={`listItem-${index}`}
                marginBottom="space-16"
              >
                <Box
                  border="1px solid"
                  borderColor="grey.300"
                  borderRadius="radius-12"
                  marginRight="space-16"
                  paddingBottom="space-6"
                  paddingTop="space-8"
                  paddingX="space-14"
                >
                  <Text fontWeight="weight-500" size="lg" variant="primary">
                    {index + 1}
                  </Text>
                </Box>
                <Text>{listItem}</Text>
              </Flex>
            ))}
          </SunshineCard.Content>
        </SunshineCard>
        <Button isExpanded onClick={onContinue}>
          {locales.cta}
        </Button>
      </Layout>
    );
  },
);

export default WelcomeAdmin;
