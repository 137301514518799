import { useCallback, useState } from 'react';

import useToggleState from 'common/hooks/useToggleState';

const useEmailSubmit = (defaultValue = '') => {
  const [email, setEmailValue] = useState(defaultValue);

  const setEmail = useCallback(
    (value: string) => {
      setEmailValue(value.trim());
    },
    [setEmailValue],
  );

  const [hasAgreedToTermsOfService, toggleHasAgreedToTermsOfService] =
    useToggleState(false);
  return {
    email,
    hasAgreedToTermsOfService,
    setEmail,
    toggleHasAgreedToTermsOfService,
  };
};

export default useEmailSubmit;
