import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    buttonLabelNo: 'No',
    buttonLabelYes: 'Yes',
    country: 'In which country?',
    countryPlaceholder: 'Select a country…',
    done: 'Your tax residency has been correctly updated',
    doYouHaveTaxResidenceAbroad:
      'After checking, do you have a tax residence outside of France?',
    fiscalId: 'What is your fiscal identifier in this country?',
    hasActivityAbroad:
      'Do you have a significant professional activity outside France?',
    hasActivityAbroadSubtitle:
      'For example: another company or a job which represents more that half your income',
    hasError: 'An error occurred, please try again.',
    isLivingAbroad: 'Last year, have you lived less than 183 days in France?',
    linkTaxResidence: 'How to know I have a tax residence abroad?',
    mayHaveTaxResidenceAbroad:
      'You may have a tax residence outside of France.',
    submit: 'Submit',
  },
  fr: {
    buttonLabelNo: 'Non',
    buttonLabelYes: 'Oui',
    country: 'Dans quel pays ?',
    countryPlaceholder: 'Sélectionnez un pays…',
    done: 'Vos informations fiscales ont bien été mises à jour',
    doYouHaveTaxResidenceAbroad:
      'Après vérification, avez-vous une résidence fiscale à l’étranger ?',
    fiscalId:
      'Quel est votre numéro d’identification fiscal (NIF) dans ce pays ?',
    hasActivityAbroad:
      'Avez-vous une activité professionnelle importante en dehors de France ?',
    hasActivityAbroadSubtitle:
      'Par exemple : une autre société ou un emploi qui représente plus de la moitié de vos revenus',
    hasError: 'Une erreur s’est produite, veuillez ré-essayer.',
    isLivingAbroad:
      'Avez-vous résidé en France moins de 183 jours l’année passée ?',
    linkTaxResidence:
      'Comment savoir si j’ai une résidence fiscale à l’étranger ?',
    mayHaveTaxResidenceAbroad:
      'Il se peut que vous ayez une résidence fiscale en dehors de France.',
    submit: 'Envoyer',
  },
} as const);

export default locales;
