/**
 * Copied from shine.fr
 */

/**
 * @param {Number} value  - Number between 0 and max
 * @param {Number} max    - Number of stars
 */
export const getStarDecomposition = (value: number, max: number) => {
  const fullStarsCount = Math.floor(value);
  const lastStarPercentage = (value - fullStarsCount) * 100;

  return {
    fullStarsCount,
    lastStarPolygon: `polygon(0 0, ${lastStarPercentage}% 0, ${lastStarPercentage}% 100%, 0 100%)`,
    max,
  };
};
