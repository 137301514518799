import LocalizedStrings from 'localized-strings';

import { OutstandingAgreementType } from '__generated__/GQL';

const locales = new LocalizedStrings({
  en: {
    check: {
      [OutstandingAgreementType.Cgu]:
        'You have read and accepted our terms and conditions',
    },
    cta: 'Back to my application',
    title: {
      [OutstandingAgreementType.Cgu]: 'Terms and conditions',
    },
  },
  fr: {
    check: {
      [OutstandingAgreementType.Cgu]:
        'Vous avez lu et accepté nos conditions générales d’utilisation',
    },
    cta: 'Revenir à mon dossier',
    title: {
      [OutstandingAgreementType.Cgu]: 'Conditions générales d’utilisation',
    },
  },
} as const);

export default locales;
