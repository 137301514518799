import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(142, 146, 214, 0.05);
  @media (max-width: 504px) {
    padding: 0;
    flex: 1;
    align-self: stretch;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 654px;
  width: 519px;
  background-color: white;
  border-radius: 25px;
  padding: 55px 48px;
  box-shadow: 0 10px 20px 0 rgba(223, 223, 227, 0.37);
  @media (max-width: 504px) {
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    border-radius: 0;
  }
`;
