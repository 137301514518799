import useCompanyContext from './useCompanyContext';
import { useUserCompanyList } from './useUserCompanyList';

/**
 * Hook which retrieves the current company from the user's company list based on the companyProfileId from the context.
 * @returns the current company
 */
export function useCurrentCompany() {
  const { companyProfileId } = useCompanyContext();
  const { companies } = useUserCompanyList();

  return (companies || []).find(
    (company) => company?.companyProfileId === companyProfileId,
  );
}
